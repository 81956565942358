import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
} from '@angular/core';
import { destroySubscriptions } from '../../../core/reactive/until-destroyed';
import { PermissionStates } from '../../../core/principal/permission.states';
import { ControllingSingleUserTypes } from '../../../core/ctrl-single-user.types';
import {
  CtrlSingleUserDetailsCurriculumTypes
} from '../../../route/ctrl/single-user/ctrl-single-user-details/ctrl-single-user-details-curriculum/ctrl-single-user-details-curriculum.types';
import { combineLatest, Observable } from 'rxjs';
import {
  ControllingSingleUserService
} from '../../../route/ctrl/single-user/ctrl-single-user-util/ctrl-single-user.service';
import {
  CtrlSingleUserCurriculumEventsService
} from '../../../route/ctrl/single-user/ctrl-single-user-curriculum/ctrl-single-user-curriculum-events/ctrl-single-user-curriculum-events.service';
import {
  CtrlSingleUserCurriculumNotificationsService
} from '../../../route/ctrl/single-user/ctrl-single-user-curriculum/ctrl-single-user-curriculum-notifications/ctrl-single-user-curriculum-notifications.service';
import {
  CtrlSingleUserCurriculumService
} from '../../../route/ctrl/single-user/ctrl-single-user-curriculum/ctrl-single-user-curriculum.service';
import {
  CtrlSingleUserDetailsCurriculumEditService
} from '../../../route/ctrl/single-user/ctrl-single-user-details/ctrl-single-user-details-curriculum-edit/ctrl-single-user-details-curriculum-edit.service';
import { CurriculumPathSwitchService } from '../../../core/curriculum/curriculum-path-switch.service';
import { PrincipalService } from '../../../core/principal/principal.service';
import { InfoService } from '../../../core/info/info.service';
import { TableColumnMenuService } from '../../table/table-column-menu/table-column-menu.service';
import { AdminCoursesService } from '../../../route/admin/admin-courses/admin-courses-util/admin-courses.service';
import { map, switchMap, take, takeWhile, tap } from 'rxjs/operators';
import {
  CTRL_SINGLE_USER_MSG
} from '../../../route/ctrl/single-user/ctrl-single-user-details/ctrl-single-user-details.types';
import { InfoType, MessageConstants } from '../../../core/info/info.types';
import { TableGroupingHelper } from '../../table/table-grouping/table-grouping.helper';
import { DisplayStatusHelper } from '../../../core/display-status-helper';
import { LearnerAccountTypes } from '../../../core/learner-account/learner-account.types';
import { Core, Dialogs, NumberedAnyObject } from '../../../core/core.types';
import {
  CurrIterationComponent
} from '../../../route/ctrl/single-user/ctrl-single-user-details/ctrl-single-user-certificates/components/curr-iteration/curr-iteration.component';
import {
  RecentFailureIterationsDialogComponent
} from '../../../route/ctrl/single-user/ctrl-single-user-details/ctrl-single-user-certificates/components/recent-failure-iterations-dialog/recent-failure-iterations-dialog.component';
import { TableGroupingTypes } from '../../table/table-grouping/table-grouping.types';
import { ColumnFilterV2 } from '../../../core/column-settings/column-filter.types';
import { TableControllerTypes } from '../../table/table-controller/table-controller.types';
import { LanguageHelper } from '../../../core/language.helper';
import {
  CtrlSingleUserDetailsCourseTypes
} from '../../../route/ctrl/single-user/ctrl-single-user-details/ctrl-single-user-details-course/ctrl-single-user-details-course.types';
import { TableGroupingControllerComponent } from '../../table/table-grouping/table-grouping-controller.component';
import {
  CtrlSingleUserCertificates
} from '../../../route/ctrl/single-user/ctrl-single-user-details/ctrl-single-user-certificates/ctrl-single-user-certificates.types';
import {
  CtrlSingleUserDetailsLearningDataDialogComponent
} from '../../../route/ctrl/single-user/ctrl-single-user-details/ctrl-single-user-details-learning-data-dialog/ctrl-single-user-details-learning-data-dialog.component';
import {
  CurrValidityComponent
} from '../../../route/ctrl/single-user/ctrl-single-user-details/ctrl-single-user-certificates/components/curr-validity/curr-validity.component';
import { CertificatesService } from '../../../route/user/certificates/certificates.service';
import { UserNameHelper } from '../../../core/user-name.helper';
import { DisplayStatus } from '../../../core/display-status.enum';


@Component({
  selector: 'rag-controlling-curriculum',
  templateUrl: './controlling-curriculum.component.html',
  styleUrls: [ './controlling-curriculum.component.scss' ],
})
export class ControllingCurriculumComponent
  extends TableGroupingControllerComponent<CtrlSingleUserDetailsCurriculumTypes.RowData,
    CtrlSingleUserDetailsCurriculumTypes.RowDataChild,
    CtrlSingleUserDetailsCurriculumTypes.RowDataParent,
    CtrlSingleUserDetailsCurriculumTypes.ColumnMenuData> implements AfterViewInit, OnChanges, OnDestroy
{
  @Input() curriculumId: number;
  @Input() contextItemUrlPattern: string;
  @Input() userDetailsView: ControllingSingleUserTypes.UserDetailsResponse;
  @Output() readonly needsReload$: Observable<void>;

  canChangeData = false;
  permissions: PermissionStates;
  user: ControllingSingleUserTypes.ControllingUser;
  userFullName: string;
  moduleDetailsEnabled$: Observable<boolean>;

  private _needsReload$ = new EventEmitter<void>(true);
  private tableRows: CtrlSingleUserDetailsCurriculumTypes.RowData[];

  constructor(
    private adminCourseService: AdminCoursesService,
    private controllingSingleUserService: ControllingSingleUserService,
    private ctrlSingleUserCurriculumEventsService: CtrlSingleUserCurriculumEventsService,
    private ctrlSingleUserCurriculumNotificationsService: CtrlSingleUserCurriculumNotificationsService,
    private ctrlSingleUserCurriculumService: CtrlSingleUserCurriculumService,
    private ctrlSingleUserDetailsCurriculumEditService: CtrlSingleUserDetailsCurriculumEditService,
    private curriculumPathSwitchService: CurriculumPathSwitchService,
    private certificatesService: CertificatesService,
    private infoService: InfoService,
    private principalService: PrincipalService,
    tableColumnMenuService: TableColumnMenuService,
  ) {
    super(tableColumnMenuService);

    this.needsReload$ = this._needsReload$.asObservable();
    this.dataSource.filterPredicate = this.filterPredicate;

    this.moduleDetailsEnabled$ = this.principalService.permissionStates$
      .pipe(tap(permissions => this.permissions = permissions))
      .pipe(map(permissions => !permissions.navCtrlHideModuleDetailsMenu));
  }

  getContextItem(row: CtrlSingleUserDetailsCurriculumTypes.RowData): ControllingSingleUserTypes.CurriculumItemAccount {
    const data = this.asChild(row)?.$data;
    if ( data?.targetType !== Core.DistributableType.lms_context ) {
      return null;
    }
    return data;
  }

  getContextItemUrl(row: CtrlSingleUserDetailsCurriculumTypes.RowData | null): string | null {
    const contextItem = this.getContextItem(row);
    if ( (contextItem == null) || (this.contextItemUrlPattern == null) ) {
      return null;
    }

    return this.contextItemUrlPattern
      .replace(/{userId}/gi, String(this.user.userId))
      .replace(/{curriculumId}/gi, String(contextItem.curriculumId))
      .replace(/{itemType}/gi, String(contextItem.itemType))
      .replace(/{curriculumItemId}/gi, String(contextItem.curriculumItemId));
  }

  getCourse(row: CtrlSingleUserDetailsCurriculumTypes.RowData): ControllingSingleUserTypes.ScoCourseAccount {
    return this.asChild(row)?.$data?.course;
  }

  getCurriculumDetailsUrl(row: CtrlSingleUserDetailsCurriculumTypes.RowData): string {
    return `/ctrl/user/${this.user.userId}/curriculum/${row.$data.curriculumId}`;
  }

  getInteractionsUrl(
    course: ControllingSingleUserTypes.ScoCourseAccount,
    row: CtrlSingleUserDetailsCurriculumTypes.RowData
  ): string {
    const curriculumItemAccount = row.$data as ControllingSingleUserTypes.CurriculumItemAccount;
    const curriculumItemId = curriculumItemAccount?.curriculumItemId;
    const curriculumId = curriculumItemAccount?.curriculumId;
    const courseId = course?.courseId;

    if (curriculumItemId == null || curriculumId == null || courseId == null ) {
      return;
    }
    return `/ctrl/user/${this.user.userId}/curriculum/${curriculumId}/training/${curriculumItemId}/interactions/${courseId}`;
  }

  getOrderIndex(row: CtrlSingleUserDetailsCurriculumTypes.RowData): number {
    if ( (row?.$parent != null) && (row.orderIndex > -1) ) {
      return row.orderIndex + 1;
    } else {
      return null;
    }
  }

  getTargetType(
    row: CtrlSingleUserDetailsCurriculumTypes.RowData,
  ): Core.DistributableType {
    const childRow = this.asChild(row);
    if ( childRow == null ) {
      return Core.DistributableType.lms_curriculum;
    }

    return childRow.targetType;
  }

  hasValidity(row: CtrlSingleUserDetailsCurriculumTypes.RowData): boolean {
    return row.validSince != null;
  }

  hasChangeableChildren(parentRow: CtrlSingleUserDetailsCurriculumTypes.RowDataParent): boolean {
    const $children = parentRow.$children ?? [];
    return $children.findIndex(row => this.maySave(row)) > -1;
  }

  hasCourseActions(row: CtrlSingleUserDetailsCurriculumTypes.RowData): boolean {
    const course = this.getCourse(row);
    return this.maySave({ $data: course }) || this.hasRelevantScormDetailData(course);
  }

  hasMultiActions(row: CtrlSingleUserDetailsCurriculumTypes.RowData): boolean {
    // can change path of curriculum or status of item
    return this.maySave(row) ||
      // or can change course status
      this.maySave({ $data: this.getCourse(row) });
  }

  hasRelevantScormDetailData(course: ControllingSingleUserTypes.ScoCourseAccount): boolean {
    return LearnerAccountTypes.Util.hasRelevantScormDetailData(course);
  }

  public hasSelectionChangeableChild(): boolean {
    // force row to child -> null if parent
    return this.selection.selected.filter(row => (row.$rowType === 'child') && this.maySave(row)).length > 0;
  }

  public hasSelectionChangeableCourse(): boolean {
    return this.selection.selected.filter(row => this.hasMultiActions(row) &&
      this.hasRelevantScormDetailData(this.getCourse(row))).length > 0;
  }

  public hasSelectionParent(): boolean {
    return TableGroupingHelper.getParents(this.selection.selected)
      .find(row => this.maySave(row)) != null;
  }

  includesType(rows: CtrlSingleUserDetailsCurriculumTypes.RowData[], contentType: string): boolean {
    return rows.filter(row => this.asChild(row)?.targetType === contentType).length > 0;
  }

  isCurriculumAndStatusFixable(row: CtrlSingleUserDetailsCurriculumTypes.RowData): boolean {
    const parentRow = this.asParent(row);
    if ( parentRow == null ) {
      return false;
    }

    const curriculumAccount = parentRow.$data;

    return curriculumAccount.accountStatus === Core.CurriculumAccountStatus.ended &&
      curriculumAccount.displayStatus === DisplayStatus.RECERTIFICATION_TIME;
  }

  isCurriculumStuck(row: CtrlSingleUserDetailsCurriculumTypes.RowData): boolean {
    const parentRow = this.asParent(row);
    if ( parentRow == null ) {
      return false;
    }

    const curriculumAccount = parentRow.$data;
    return (curriculumAccount?.licenceEnded === true) &&
      DisplayStatusHelper.isStatusRecert(curriculumAccount?.displayStatus);
  }

  isTodo(course: ControllingSingleUserTypes.ScoCourseAccount): boolean {
    return course.courseType === Core.CourseType.ToDo;
  }

  mayOpenCourseInfos(course: ControllingSingleUserTypes.ScoCourseAccount): boolean {
    return course.rbacActions.mayRead && course.rbacActions.maySave;
  }

  mayRead<T extends Core.WithRbacActions<Core.RbacActionsElearning>>(row: { $data: T }): boolean {
    if ( this.inputDisabled ) {
      return false;
    }

    const data = row?.$data;
    if ( data == null ) {
      return false;
    }
    return data.rbacActions?.mayRead ?? true;
  }

  mayReadScormLog(course: ControllingSingleUserTypes.ScoCourseAccount): boolean {
    return this.hasRelevantScormDetailData(course) && this.mayRead({ $data: course });
  }

  maySave<T extends Core.WithRbacActions<Core.RbacActionsElearning>>(row: { $data: T }): boolean {
    if ( this.inputDisabled || !this.canChangeData ) {
      return false;
    }

    const data = row?.$data;
    if ( data == null ) {
      return false;
    }
    return data.rbacActions?.maySave ?? true;
  }

  ngAfterViewInit(): void {
    this.warnOwnAccount();
  }

  ngOnChanges(
    changes: SimpleChanges,
  ): void {

    if ( changes.hasOwnProperty('userDetailsView') ) {
      this.updateUserDetailsView(this.userDetailsView);
    }

    if ( changes.hasOwnProperty('curriculumId') && (this.curriculumId > 0) ) {
      TableGroupingHelper.expandParents(this.findCurriculumRow(this.tableRows), true);
      this.updateFilterState();
    }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onCurrentIteration(parentRow?: CtrlSingleUserDetailsCurriculumTypes.RowData) {

    const userId = this.user?.userId;
    const curriculumId = parentRow?.$data?.curriculumId;
    const curriculumTitle = parentRow?.$data.title;

    this.controllingSingleUserService
      .getCurriculumSteering(userId, curriculumId)
      .pipe(take(1))
      .pipe(switchMap(data => this.infoService.showDialog<CurrIterationComponent,
        CtrlSingleUserCertificates.EditIterationDialogParams, boolean>(CurrIterationComponent, {
        userId: this.user.userId,
        curriculumId,
        curriculumTitle: LanguageHelper.objectToText(curriculumTitle),
        steering: data,
      }, Dialogs.BigDialogProps)))
      .pipe(takeWhile(shouldReload => shouldReload === true))
      .pipe(tap(this.reloadData))
      .subscribe();
  }

  onShowLatestValidity(parentRow: CtrlSingleUserDetailsCurriculumTypes.RowData) {

    const userId = this.user.userId;
    const curriculumId = parentRow.$data.curriculumId;
    const curriculumTitle = parentRow?.$data?.title;

    this.controllingSingleUserService
      .getCurriculumHistory(userId, curriculumId)
      .pipe(take(1))
      .pipe(switchMap(data => {
        const latestCertifiedIteration = data.history[0];
        const certificateAccount = {
          curriculumId,
          curid: curriculumId,        // mess with types
          iteration: latestCertifiedIteration.it,
          validSince: latestCertifiedIteration.validSince,
          validUntil: latestCertifiedIteration.validUntil
        };
        return this.infoService.showDialog<CurrValidityComponent, CtrlSingleUserCertificates.EditValidityDialogParams, boolean>(
          CurrValidityComponent, {
            userId,
          userName: UserNameHelper.getFullName(this.user),
            curriculumId,
          curriculumTitle: LanguageHelper.objectToText(curriculumTitle),
            certificateAccount,
          });
      }))
      .pipe(takeWhile(shouldReload => shouldReload === true))
      .pipe(tap(this.reloadData))
      .subscribe();
  }

  onCurriculumSteering(parentRow?: CtrlSingleUserDetailsCurriculumTypes.RowData) {

    const curriculumId = parentRow?.$data?.curriculumId;
    const userId = this.user.userId;

    combineLatest([
      this.controllingSingleUserService.getCurriculumSteering(userId, curriculumId),
      this.controllingSingleUserService.getCurriculumHistory(userId, curriculumId),
    ]).subscribe(([ steering, historyResponse ]) => {

      // remove current iteration from history, if present
      historyResponse.latest = historyResponse.latest?.filter(
        iteration => iteration.it !== steering.current.it) ?? [];

      if ( historyResponse.latest.length === 0 ) {
        this.infoService.showAlert($localize`:@@ctrl_history_no_entries:There are no recent unsuccessful attempts available.`);
        return;
      }

      this.infoService.showDialog(
        RecentFailureIterationsDialogComponent, {
          userId,
          curriculumId,
          history: historyResponse,
        }, Dialogs.BigDialogProps).subscribe(iteration => {
        // the user has selected the current iteration for edition
        if ( iteration != null ) {
          this.onCurrentIteration(parentRow);
        }
      });
    });
  }

  public onEditAccount(row?: CtrlSingleUserDetailsCurriculumTypes.RowDataParent): void {
    let curriculumItems: CtrlSingleUserDetailsCurriculumTypes.RowDataChild[];
    if ( row?.$rowType === 'parent' ) {
      curriculumItems = TableGroupingHelper.getChildren(row)
        .map(entry => this.asChild(entry));
    } else if ( row?.$rowType === 'child' ) {
      curriculumItems = [ this.asChild(row) ];
    } else {
      curriculumItems = this.selection.selected
        .map(entry => this.asChild(entry));
    }
    this.onEditAccountImpl(curriculumItems.filter(entry => entry != null));
  }

  onEditAccountImpl(curriculumItems: CtrlSingleUserDetailsCurriculumTypes.RowDataChild[]): void {
    if ( !(curriculumItems?.length > 0) ) {
      // nothing to change
      return;
    }

    const curricula = Object.values(curriculumItems
      .reduce((pV, row) => {
        const childRow = row;
        const curriculumItem = childRow.$data;
        const curriculumId = curriculumItem.curriculumId;

        let curriculum: ControllingSingleUserTypes.CurriculumAccount;
        if ( pV[curriculumId] == null ) {
          // create shallow copy without items
          curriculum = pV[curriculumId] = { ...childRow.$parent.$data };
          curriculum.curriculumItems = [];
        } else {
          curriculum = pV[curriculumId];
        }

        if ( !curriculum.curriculumItems.includes(curriculumItem) ) {
          curriculum.curriculumItems.push(curriculumItem);
        }
        return pV;
      }, {} as NumberedAnyObject<ControllingSingleUserTypes.CurriculumAccount>))
      .filter((curriculum: ControllingSingleUserTypes.CurriculumAccount) => curriculum?.curriculumItems?.length > 0);

    if ( !(curricula.length > 0) ) {
      // ignore "empty" selection
      return;
    }

    this.ctrlSingleUserDetailsCurriculumEditService
      .editAccount(this.user.userId, curricula)
      .pipe(take(1))
      .pipe(tap(this.reloadData))
      .subscribe();
  }

  onFilterChange(f: ColumnFilterV2, column: TableControllerTypes.ColumnMenuItem): void {
    super.onFilterChange(f, column);
    this.updateFilterState();
  }

  onFilterStopped(): void {
    const menuItems = this.columnMenuData.menuItems;
    Object.values(menuItems)
      .filter(o => o.options?.filter != null)
      .forEach(o => this.resetMenuItem(o, o.options.filter.defaultValue || null));

    const accountStatus = menuItems.accountStatus.options.filter;
    accountStatus.value = Core.CurriculumAccountStatus.ended;
    super.onFilterChange(accountStatus, menuItems.accountStatus);

    const displayStatus = menuItems.displayStatus.options.filter;
    super.onFilterChange(accountStatus, menuItems.displayStatus);
    displayStatus.value = 'red';

    this.updateFilterState();
  }

  onRecalculateEvents(parentRow: CtrlSingleUserDetailsCurriculumTypes.RowDataParent): void {
    const userId = this.user?.userId;
    const curriculumId = parentRow?.$data?.curriculumId;
    this.ctrlSingleUserCurriculumEventsService
      .recalculateEvents(userId, curriculumId)
      .pipe(take(1))
      .subscribe();
  }

  onRecalculateNotifications(parentRow: CtrlSingleUserDetailsCurriculumTypes.RowDataParent): void {
    const userId = this.user?.userId;
    const curriculumId = parentRow?.$data?.curriculumId;
    this.ctrlSingleUserCurriculumNotificationsService
      .recalculateNotifications(userId, curriculumId)
      .pipe(take(1))
      .subscribe();
  }

  onResetCourse(row: CtrlSingleUserDetailsCurriculumTypes.RowDataChild): void {
    this.resetCourses([ row.$data.targetId ]);
  }

  public onResetCourses(): void {
    const courseIds = this.selection.selected
      .map(row => this.getCourse(row)?.courseId)
      .filter(courseId => (courseId > 0));
    this.resetCourses(courseIds);
  }

  onResetLock(row: TableGroupingTypes.TableRowParent): void {
    this.onResetLocksImpl([ row ]);
  }

  public onResetLocks(): void {
    if ( !this.selection.hasValue() ) {
      // nothing selected
      return;
    }

    const parents = TableGroupingHelper.getParents(this.selection.selected)
      .filter(row => this.maySave(row));
    this.onResetLocksImpl(parents);
  }

  onResetLocksImpl(curricula: TableGroupingTypes.TableRowParent[]): void {
    if ( !(curricula?.length > 0) ) {
      // nothing selected
      return;
    }

    const userId = this.user.userId;
    const entries = curricula.map(row => ({
      curriculumId: row.$data.curriculumId,
      userId,
    }));
    this.ctrlSingleUserCurriculumService
      .resetLocks(entries)
      .pipe(take(1))
      .pipe(tap(() => this.infoService
        .showMessage($localize`:@@general_save_success:The data has been saved successfully`,
          { infoType: InfoType.Success })))
      .pipe(tap(this.reloadData))
      .subscribe();
  }

  onScormLogDialog(course: ControllingSingleUserTypes.ScoCourseAccount): void {
    this.controllingSingleUserService
      .showScormLogUserAndSco(this.user, course)
      .pipe(take(1))
      .subscribe();
  }

  onDownloadInteractionsPDF(course: ControllingSingleUserTypes.ScoCourseAccount): void {
    this.controllingSingleUserService.downloadInteractionsPDF(this.user.userId, course.courseId);
  }

  onSessionDataDialog(course: ControllingSingleUserTypes.ScoCourseAccount): void {
    this.controllingSingleUserService
      .showSessionDataForUserAndContent(this.user, course)
      .pipe(take(1))
      .subscribe();
  }

  onShowEvents(parentRow: CtrlSingleUserDetailsCurriculumTypes.RowDataParent): void {
    const userId = this.user?.userId;
    const curriculumId = parentRow?.$data?.curriculumId;
    const maySave = this.maySave(parentRow);

    this.ctrlSingleUserCurriculumService
      .showEvents(userId, curriculumId, !maySave)
      .pipe(take(1))
      .subscribe();
  }

  onShowNotifications(parentRow: CtrlSingleUserDetailsCurriculumTypes.RowDataParent): void {
    const userId = this.user?.userId;
    const curriculumId = parentRow?.$data?.curriculumId;
    this.ctrlSingleUserCurriculumService
      .showNotifications(userId, curriculumId)
      .pipe(take(1))
      .subscribe();
  }

  onSwitchPath(row: TableGroupingTypes.TableRowParent): void {
    this.onSwitchPathsImpl([ row ]);
  }

  public onSwitchPaths(): void {
    if ( !this.selection.hasValue() ) {
      // nothing selected
      return;
    }

    const parents = TableGroupingHelper.getParents(this.selection.selected)
      .filter(row => this.maySave(row));
    this.onSwitchPathsImpl(parents);
  }

  onSwitchPathsImpl(curricula: TableGroupingTypes.TableRowParent[]): void {
    if ( !(curricula?.length > 0) ) {
      // nothing selected
      return;
    }

    const userId = this.user.userId;
    const entries = curricula.map(row => ({
      curriculumId: row.$data.curriculumId,
      users: [ { userId } ],
    }));
    this.curriculumPathSwitchService.switchPaths(entries)
      .pipe(take(1))
      .pipe(tap(this.reloadData))
      .subscribe();
  }

  openCourseAccount(rowCourse: ControllingSingleUserTypes.ScoCourseAccount, row: any): void {
    this.adminCourseService
      .getCourseAccountInCurriculumForUser(this.user.userId, rowCourse.courseId, row.$data.curriculumId)
      .pipe(take(1))
      .pipe(switchMap(response => {
        const { course, contribution, courseAccount } = response;
        course.title = LanguageHelper.objectToText(course.title);
        course.courseType = courseAccount.courseType;
        return this.infoService.showDialog<CtrlSingleUserDetailsLearningDataDialogComponent,
          CtrlSingleUserDetailsCourseTypes.CourseControllingDialogParams,
          boolean>(CtrlSingleUserDetailsLearningDataDialogComponent, {
          userId: this.user.userId,
          courses: [ { ...courseAccount, ...course, contribution } ],
        });
      }))
      .pipe(takeWhile(success => success === true))
      .pipe(tap(() => {
        this.infoService.showMessage(
          $localize`:@@general_save_success:The data has been saved successfully`,
          { infoType: InfoType.Success });
        this.reloadData();
      }))
      .subscribe();

  }

  toggleParent(row: CtrlSingleUserDetailsCurriculumTypes.RowDataParent): void {
    row = this.asParent(row);
    if ( row == null ) {
      return;
    }

    if ( row.$expanded ) {
      TableGroupingHelper.collapseChildren(row, true);
    } else {
      TableGroupingHelper.expandParents(row, true);
    }
    this.updateFilterState();
  }

  onFixStatus(row: CtrlSingleUserDetailsCurriculumTypes.RowDataParent): void {
    row = this.asParent(row);
    if ( row == null ) {
      return;
    }
    this.controllingSingleUserService.setLastToCurrentIteration(row.curriculumId, this.user.userId)
      .pipe(tap(() => {
      this.infoService.showMessage(MessageConstants.API.SUCCESS, {
        infoType: InfoType.Success,
      });
      this.reloadData();
    })).subscribe();
  }

  private filterForCurriculumDetails(
    rows: CtrlSingleUserDetailsCurriculumTypes.RowData[],
  ): CtrlSingleUserDetailsCurriculumTypes.RowData[] {
    const curriculumRow = this.findCurriculumRow(rows);
    if ( curriculumRow != null ) {
      // reduce rows to selected curriculum
      const curriculum = curriculumRow.$data;
      this.controllingSingleUserService.openCurriculum(this.user?.userId, curriculum);
      return rows.filter(row => row.$data.curriculumId === this.curriculumId);
    } else {
      return rows;
    }
  }

  private findCurriculumRow(rows?: CtrlSingleUserDetailsCurriculumTypes.RowData[]) {
    let curriculumRow: CtrlSingleUserDetailsCurriculumTypes.RowDataParent;
    if ( (rows != null) && (this.curriculumId > 0) ) {
      curriculumRow = rows
        .find(row => (row.$rowType === 'parent') && (row.$data.curriculumId === this.curriculumId)) as CtrlSingleUserDetailsCurriculumTypes.RowDataParent;
    }
    return curriculumRow;
  }

  private reloadData = () => {
    this._needsReload$.emit();
  };

  private resetCourses(courseIds: number[]): void {
    if ( !(courseIds.length > 0) ) {
      // nothing to do
      return;
    }

    this.controllingSingleUserService
      .resetCourses(this.user.userId, courseIds)
      .pipe(take(1))
      .pipe(tap(this.reloadData))
      .subscribe();
  }

  private updateAvailableColumns(): void {
    const menuData = TableColumnMenuService.createFromDefaults(CtrlSingleUserDetailsCurriculumTypes.DEFAULT_MENU_COLUMNS);
    this.setMenuData(menuData);
  }

  private updateFilterState(): void {
    if ( this.tableRows == null ) {
      // not yet initialized
      return;
    }

    this.checkFilter();
    let data = TableGroupingHelper.applyFilter(this.tableRows,
      this.isFilterActive ? this.filterPredicateDefault : null);
    data = this.filterForCurriculumDetails(data);
    this.setTableData(data);

    // filter should always be active to hide children in collapsed parents
    this.nextDataSourceFilter(String(this.dataSource.filter !== 'true'));
  }

  private updateUserDetailsView = (userDetailsView: ControllingSingleUserTypes.UserDetailsResponse): void => {
    this.isColumnContextLoaded = false;
    this.user = userDetailsView.controllingUser;
    this.canChangeData = this.user.userId !== this.principalService.userId;

    this.updateAvailableColumns();
    this.tableRows = CtrlSingleUserDetailsCurriculumTypes.Util.toTableRows(userDetailsView.curricula);

    // expand details curriculum on first rendering
    TableGroupingHelper.expandParents(this.findCurriculumRow(this.tableRows), true);

    this.inputDisabled = false;
    this.updateFilterState();
  };

  private warnOwnAccount(): void {

    if ( this.canChangeData ) {
      return;
    }

    this.infoService.showMessage(CTRL_SINGLE_USER_MSG.OWN_LEARNING_STATUS_NOT_EDITABLE, {
      infoType: InfoType.Warning,
      durationInSeconds: 5,
    });
  }

}
