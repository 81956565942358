export const CONTACT_CLH_DE = `
  <p>Anfragen zur Nutzung des Lernmanagementsystem (LMS) iLearn24 – Next Generation der Lufthansa Cityline (CLH, LHX und
    Lufthansa Aviation GmbH) sowie der hier bereitgestellten sowie der webbasierten Trainings (WBT) nimmt das
    <strong>IT-Service Desk</strong> via E-Mail oder telefonisch entgegen:</p>

  <p class="center">
    <b>E-Mail:</b>
    <a class="mail" href="mailto:cityline.servicedesk@dlh.de">cityline.servicedesk@dlh.de</a>
    <br/>
    <b>Telefon:</b>
    <span class="phone-number">+49 461 1608 378</span>
  </p>

  <p><strong>Beschäftigte der Technik</strong>, die ein <u>dringendes</u> Anliegen haben, welches zeitnah gelöst werden
    soll, enden sich bitte an:
    <a class="mail" href="mailto:c.iLearn.technik.support@dlh.de">c.iLearn.technik.support@dlh.de</a>.</p>

  <p>Um die Anfrage bestmöglich bearbeiten zu können, benötigen wir <strong>folgende Informationen</strong>:</p>

  <p><strong>Grundsätzlich:</strong></p>

  <ul>
    <li>Beschreibung des Problems</li>
    <li>Name, Vorname und U-Nummer</li>
  </ul>

  <p>Bei Herausforderungen im <strong>direkten Zusammenhang mit einer Online-Schulung</strong> zusätzlich</p>

  <ul>
    <li>Um welche Schulung handelt es sich?</li>
    <li>Mit welchem Endgerät (iPad, Laptop, PC, Handy) führte die Bearbeitung zu einem Fehler?</li>
    <li>Wurde die App genutzt oder ein Browser?</li>
    <li>Falls Browser: Welcher (Safari, Chrome, Firefox, Edge, anderer)?</li>
    <li>Welche Schritte wurden bereits unternommen, um die Herausforderung selbstständig zu lösen (z. B. anderen Browser
      ausprobiert, App neu gestartet, aus- und wieder eingeloggt, Suchverlauf gelöscht etc.)?
    </li>
  </ul>

  <p>Bei <strong>Kontaktaufnahme via E-Mail zusätzlich</strong> falls möglich und vorhanden einen:</p>

  <ul>
    <li>Screenshot des Bildschirms, der die Herausforderung verdeutlichen könnte.</li>
  </ul>

  <p>Vielen Dank!</p>
`;

export const CONTACT_CLH_EN = `
  <p>Inquiries regarding the use of the learning management system (LMS) iLearn24 - Next Generation of
    Lufthansa Cityline (CLH, LHX and Lufthansa Aviation GmbH) as well as the web-based training courses
    (WBT) provided here are accepted by the <strong>IT Service Desk</strong> via email or phone:</p>

  <p class="center">
    <b>Email:</b>
    <a class="mail" href="mailto:cityline.servicedesk@dlh.de">cityline.servicedesk@dlh.de</a>
    <br/>
    <b>Phone:</b>
    <span class="phone-number">+49 461 1608 378</span>
  </p>

  <p><strong>Employees of the Technology department</strong> who have an <u>urgent matter</u> that needs to be resolved
    promptly should contact:
    <a class="mail" href="mailto:c.iLearn.technik.support@dlh.de">c.iLearn.technik.support@dlh.de</a>.</p>

  <p>In order to process your request in the best possible way, we need the <strong>following information</strong>:</p>

  <p><strong>In general:</strong></p>

  <ul>
    <li>Description of the problem</li>
    <li>Surname, first name and U-number</li>
  </ul>

  <p>For challenges <strong>directly related to an online training course</strong>, additionally</p>

  <ul>
    <li>Which training course is involved?</li>
    <li>Which end device (iPad, laptop, PC, cell phone) was used when the error occurred?</li>
    <li>Was the app used or a browser?</li>
    <li>If a browser was used: Which one (Safari, Chrome, Firefox, Edge, other)?</li>
    <li>What steps have already been taken to resolve the challenge independently (e.g. tried another browser, restarted
      the app, logged out and back in, deleted search history, etc.)?</li>
  </ul>

  <p>When <strong>contacting us via e-mail</strong>, additionally, if possible and available, please add:</p>

  <ul>
    <li>A screenshot that could illustrate the challenge.</li>
  </ul>

  <p>Thank you!</p>
`;
