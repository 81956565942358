import { TableControllerTypes } from 'src/app/component/table/table-controller/table-controller.types';
import { ControllingSingleUserTypes } from 'src/app/core/ctrl-single-user.types';

export namespace CtrlSingleUserCertificates {

  export interface ColumnMenuItemMap extends TableControllerTypes.ColumnMenuItemMap {
    curriculumTitle: TableControllerTypes.ColumnMenuItem;
    iteration: TableControllerTypes.ColumnMenuItem;
    validSince: TableControllerTypes.ColumnMenuItem;
    validUntil: TableControllerTypes.ColumnMenuItem;
  }

  export interface ColumnMenuData
    extends TableControllerTypes.ColumnMenuData {
    menuItems: ColumnMenuItemMap;
  }

  export interface EditIterationDialogParams {
    userId: number;
    curriculumId: number;
    curriculumTitle: string;
    steering: ControllingSingleUserTypes.CurriculumSteering;
  }

  export interface EditValidityDialogParams {
    userId: number;
    userName: string;
    curriculumId: number;
    curriculumTitle: string;
    certificateAccount: ControllingSingleUserTypes.CertificateAccountMinimumData;
  }

  export const DEFAULT_MENU_COLUMNS: ColumnMenuData = {
    startWith: [ 'batchCheckbox' ],
    endWith: [ 'actions' ],
    menuItems: {

      state: TableControllerTypes.Util
        .dropdownColumnFactory('state', $localize`:@@certificates_state:Certificate`, {
            active: $localize`:@@certificate_state_unlocked:available`,
            not_active: $localize`:@@certificate_state_locked:locked`
        }, $localize`:@@certificate_state_is:Certificate is ...`, 1, true),

      curriculumTitle: TableControllerTypes.Util
        .textColumnFactory('curriculumTitle', $localize`:@@global_title:Title`, 2, true),

      iteration: TableControllerTypes.Util
        .numberColumnFactory('iteration', $localize`:@@global_iteration:Iteration`, 3, true),

      validSince: TableControllerTypes.Util
        .dateColumnFactory('validSince', $localize`:@@certificates_info_valid_since:Valid since`, 4, true),

      validUntil: TableControllerTypes.Util
        .dateColumnFactory('validUntil', $localize`:@@certificates_info_valid_until:Valid until`, 5, true),

      pathTitle: TableControllerTypes.Util
        .textColumnFactory('pathTitle', $localize`:@@certificates_info_path_title:Path title`, 6, false),

      variationCounter: TableControllerTypes.Util
        .numberColumnFactory('variationCounter', $localize`:@@certificates_info_path_id:Path ID`, 7, false),

      batchCheckbox: {
        hidden: true,
        id: 'batchCheckbox',
        selected: true,
      },

      actions: {
        hidden: true,
        id: 'actions',
        selected: true,
      },
    },
  };
}
