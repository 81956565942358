import { ApiUrls } from '../../core/api.urls';

export class FilesHelper {

  public static getFileInfoUrl(uuid: string): string | null {
    return ApiUrls.getKey('FileInfo').replace('{fileUUID}', uuid);
  }

  static getDownloadUrl(uuid: string, fileName: string) {
    return ApiUrls.getKey('Files') + '/' + uuid + '/download/' + fileName;
  }

  static getIframeUrl(uuid: string) {
    return ApiUrls.getKey('Files') + '/' + uuid;
  }
}
