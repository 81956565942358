<rag-dialog-header [closingEnabled]="false">
  {{data.curriculumTitle}}
</rag-dialog-header>

<div mat-dialog-content>
  <!-- there is no valid iteration -->
  <ng-container *ngIf="!isValid; else validIteration">
    <div class="no-valid-iteration">
      <span i18n="@@ctrl-single-user-no-iteration">There is no currently running iteration in progress.</span>
    </div>
  </ng-container>

  <ng-template #validIteration>
    <table mat-table [dataSource]="dataSource">
      <!-- Phase -->
      <ng-container matColumnDef="phase">
        <th mat-header-cell *matHeaderCellDef>
          <span i18n="@@global_phase">Phase</span>
        </th>
        <td mat-cell *matCellDef="let iteration">
          {{phaseForStatus}}
        </td>
      </ng-container>

      <!-- Processing period -->
      <ng-container matColumnDef="processing_period">
        <th mat-header-cell *matHeaderCellDef>
          <span i18n="@@processing_period">Processing period</span>
        </th>
        <td mat-cell *matCellDef="let iteration">
          <div class="calendars">

            <rag-date-time #startDateTimeComp
              (valueChanges)="onAddValidSince($event)"
              (validityChanges)="onValidityChanges($event)"
              [dateTime]="dateFor(iteration.start)"
              [lessThan]="endDateTimeComp"
              [dateOnly]="true"
              dateLabel="Start date"
              i18n-dateLabel="@@global_start_date"
              i18n-timeLabel="@@global_start_time_label"
              timeLabel="Start time (HH:MM)">
            </rag-date-time>

            <rag-date-time #endDateTimeComp
              (valueChanges)="onAddValidUntil($event)"
              (validityChanges)="onValidityChanges($event)"
              [dateTime]="dateFor(iteration.end)"
              [greatThan]="startDateTimeComp"
              [dateOnly]="true"
              [required]="false"
              dateLabel="End date"
              i18n-dateLabel="@@global_end_date"
              i18n-timeLabel="@@global_end_time_label"
              timeLabel="End time (HH:MM)">
            </rag-date-time>

          </div>
        </td>
      </ng-container>

      <tr *matHeaderRowDef="displayColumns" mat-header-row></tr>
      <tr *matRowDef="let row; columns: displayColumns;" mat-row></tr>
    </table>
  </ng-template>
</div>

<div mat-dialog-actions>
  <!-- Start new iteration -->
  <button
    *ngIf="!isValid; else editIterationButtons"
    (click)="onStartNewIteration()"
    mat-flat-button
    color="accent">
    <span i18n="@@ctrl-single-user-start-iteration">Start iteration</span>
  </button>

  <ng-template #editIterationButtons>
    <!-- Save button -->
    <button
      (click)="onSaveChanges()"
      [disabled]="saveButtonDisabled$ | async"
      mat-stroked-button
      color="accent">
      <span i18n="@@global_save">Save</span>
    </button>

    <!-- Reset button -->
    <button
      (click)="onResetIteration()"
      mat-stroked-button>
      <span i18n="@@ctrl-single-user-reset-iteration">Reset</span>
    </button>

    <!-- Certify button -->
    <button
      (click)="onCertify()"
      [disabled]="certifyButtonDisabled"
      mat-stroked-button>
      <span i18n="@@ctrl-single-user-certify-iteration">Certify</span>
    </button>

    <!-- End button -->
    <button
      (click)="onEndIteration()"
      [disabled]="finishButtonDisabled"
      mat-stroked-button>
      <span i18n="@@ctrl-single-user-end-iteration">Finish iteration</span>
    </button>

  </ng-template>

  <!-- Close button -->
  <button
    (click)="onClose()"
    cdkFocusInitial
    class="rag-stroked-button"
    mat-stroked-button>
    <span i18n="@@global_close">Close</span>
  </button>
</div>
