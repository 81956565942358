import {ColumnDefaults} from '../../route/ctrl/report/report-generator/pivot-table/pivot-table.types';
import {Translation} from '../translation/translation.types';
import * as moment from 'moment';
import {AnyObject} from '../core.types';
import {State} from 'src/app/app.state';


export type FilterType = 'date' | 'text' | 'number' | 'dropdown';

export enum ColumnName {
  accountId = 'accountId',
  accountCreationDate = 'accountCreationDate',
  accountDisplayStatus = 'accountDisplayStatus',
  accountEndDate = 'accountEndDate',
  accountItemsPercentage = 'accountItemsPercentage',
  accountIteration = 'accountIteration',
  accountProcessingTimeStatus = 'accountProcessingTimeStatus',
  accountStartDate = 'accountStartDate',
  accountValidSince = 'accountValidSince',
  accountValidUntil = 'accountValidUntil',
  assignmentMandatory = 'assignmentMandatory',
  courseId = 'courseId',
  courseItemExecCount = 'courseItemExecCount',
  courseItemId = 'courseItemId',
  courseItemLastModified = 'courseItemLastModified',
  courseItemLocked = 'courseItemLocked',
  courseItemResult = 'courseItemResult',
  courseItemStatus = 'courseItemStatus',
  courseItemTitle = 'courseItemTitle',
  courseSessionDiagnostic = 'courseSessionDiagnostic',
  courseSessionLastError = 'courseSessionLastError',
  courseSessionRunning = 'courseSessionRunning',
  courseStatus = 'courseStatus',
  courseTags = 'courseTags',
  courseTitle = 'courseTitle',
  courseContributionsCount = 'courseContributionsCount',
  courseLastExecutionDate = 'courseLastExecutionDate',
  curriculumId = 'curriculumId',
  curriculumTags = 'curriculumTags',
  curriculumTitle = 'curriculumTitle',
  curriculumVariationCounter = 'curriculumVariationCounter',
  curriculumVariationTitle = 'curriculumVariationTitle',
  userActivationDate = 'userActivationDate',
  userActive = 'userActive',
  userArea = 'userArea',
  userCompany = 'userCompany',
  userCostCenter = 'userCostCenter',
  userCostcenterDesc = 'userCostcenterDesc',
  userCreationDate = 'userCreationDate',
  userDateOfBirth = 'userDateOfBirth',
  userDeactivationDate = 'userDeactivationDate',
  userEmail = 'userEmail',
  userEmployeeID = 'userEmployeeID',
  userExtID = 'userExtID',
  userFirstname = 'userFirstname',
  userFreeTextAttribute1 = 'userFreeTextAttribute1',
  userFreeTextAttribute2 = 'userFreeTextAttribute2',
  userFreeTextAttribute3 = 'userFreeTextAttribute3',
  userFreeTextAttribute4 = 'userFreeTextAttribute4',
  userFreeTextAttribute5 = 'userFreeTextAttribute5',
  userFreeTextAttribute6 = 'userFreeTextAttribute6',
  userFreeTextAttribute7 = 'userFreeTextAttribute7',
  userGeID = 'userGeID',
  userHireDate = 'userHireDate',
  userHostID = 'userHostID',
  userId = 'userId',
  userUuid = 'userUuid',
  userJobCode = 'userJobCode',
  userLanguage = 'userLanguage',
  userLastname = 'userLastname',
  userLocation = 'userLocation',
  userLocationDesc = 'userLocationDesc',
  userLogin = 'userLogin',
  userSex = 'userSex',
  userSoeID = 'userSoeID',
  userState = 'userState',
  userSubstitute2ID = 'userSubstitute2ID',
  userSubstituteID = 'userSubstituteID',
  userSuperiorID = 'userSuperiorID',
  eventId = 'eventId',
  eventScheduleId = 'eventScheduleId',
  offlineContentTitle = 'offlineContentTitle',
  offlineContentDescription = 'offlineContentDescription',
  offlineContentType = 'offlineContentType',
  offlineContentStatus = 'offlineContentStatus',
  offlineEventType = 'offlineEventType',
  offlineEventStartDate = 'offlineEventStartDate',
  offlineEventEndDate = 'offlineEventEndDate',
  offlineEventLocation = 'offlineEventLocation',
  offlineEventMaxUserCount = 'offlineEventMaxUserCount',
  genericTextColumn = 'genericTextColumn',
  genericNumberColumn = 'genericNumberColumn'
}

export const USER_COLUMN_NAMES = [
  ColumnName.userActivationDate,
  ColumnName.userActive,
  ColumnName.userArea,
  ColumnName.userCompany,
  ColumnName.userCostCenter,
  ColumnName.userCostcenterDesc,
  ColumnName.userDateOfBirth,
  ColumnName.userDeactivationDate,
  ColumnName.userEmail,
  ColumnName.userEmployeeID,
  ColumnName.userExtID,
  ColumnName.userFirstname,
  ColumnName.userFreeTextAttribute1,
  ColumnName.userFreeTextAttribute2,
  ColumnName.userFreeTextAttribute3,
  ColumnName.userFreeTextAttribute4,
  ColumnName.userFreeTextAttribute5,
  ColumnName.userFreeTextAttribute6,
  ColumnName.userFreeTextAttribute7,
  ColumnName.userGeID,
  ColumnName.userHireDate,
  ColumnName.userHostID,
  ColumnName.userId,
  ColumnName.userJobCode,
  ColumnName.userLanguage,
  ColumnName.userLastname,
  ColumnName.userLocation,
  ColumnName.userLocationDesc,
  ColumnName.userLogin,
  ColumnName.userSex,
  ColumnName.userSoeID,
  ColumnName.userState,
  ColumnName.userSubstitute2ID,
  ColumnName.userSubstituteID,
  ColumnName.userSuperiorID,
];

export interface Column
  extends ColumnDefaults {
  /**
   * generic attribute for not persistant data
   */
  $view?: any;
  /**
   * used to identify item in array
   */
  columnName: ColumnName;
  /**
   * has to be defined if filterType is 'dropdown'
   */
  dropDownOptions?: AnyObject<Translation>;
  /**
   * the text to display in dropdown as placeholder
   */
  dropDownPlaceholder?: Translation;
  /**
   * if filterType is dropdown, dropDownOptions has to be defined!
   */
  filterType: FilterType;
  /**
   * always visible and cannot be removed
   */
  fixed: boolean;
  /**
   * determines at which position in the menu the column appears
   */
  orderIndex?: number;
  /**
   * Render for this column
   */
  render: (value: any, column?: Column) => string;
  /**
   * controller can toggle the field on and off, whether it is displayed in the table or not
   */
  selectable: boolean;
  /**
   * determines whether the column appears in the menu for the controller for editing
   */
  visible: boolean;
  /**
   * function to access data for this column
   */
  dataAccessor?: (entry: any) => any;

}

const dateRenderWithTime = (value: any) => {
  if ( value != null ) {
    return moment(value).format('lll');
  }
  return null;
};

const dateRender = (value: any) => {
  if ( value != null ) {
    return moment(value).format('ll');
  }
  return null;
};

const textRender = (value: any) => value;

const optionsRender = (value: any, options: AnyObject<Translation>) => {
  const translatedValue = options[value];
  if ( translatedValue ) {
    return translatedValue[State.language] || '';
  }
  return '';
};

export const ColumnDefaultConfig: AnyObject<Column> = {
  genericTextColumn: {
    columnName: ColumnName.genericTextColumn,
    type: 'text',
    title: {
      de: '',
      en: '',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  genericNumberColumn: {
    columnName: ColumnName.genericNumberColumn,
    type: 'number',
    title: {
      de: '',
      en: '',
    },
    filterType: 'number',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  accountCreationDate: {
    columnName: ColumnName.accountCreationDate,
    type: 'date',
    title: {
      de: 'Zuweisungsdatum',
      en: 'Assignment date',
    },
    filterType: 'date',
    selectable: true,
    fixed: false,
    visible: false,
    render: dateRender,
  },
  accountDisplayStatus: {
    columnName: ColumnName.accountDisplayStatus,
    type: 'status',
    title: {
      de: 'Zert.-Status',
      en: 'Cert. status',
    },
    filterType: 'dropdown',
    dropDownOptions: {
      certified: { de: 'Zertifiziert', en: 'Certified' },
      complete: { de: 'Abgeschlossen', en: 'Complete' },
      failed: { de: 'Fehlgeschlagen', en: 'Failed' },
      incomplete: { de: 'Unvollständig', en: 'Incomplete' },
      notAttempted: { de: 'Nicht angefangen', en: 'Not attempted' },
      notCertified: { de: 'Nicht zertifiziert', en: 'Not certified' },
      passed: { de: 'Bestanden', en: 'Passed' },
      recertificationTime: { de: 'Rezertifizierung', en: 'Recertification' },
    },
    dropDownPlaceholder: { de: 'Status ist...', en: 'Status is...' },
    selectable: true,
    fixed: false,
    visible: true,
    render: (value: any, context: Column) => optionsRender(value, context.dropDownOptions),
  },
  accountEndDate: {
    columnName: ColumnName.accountEndDate,
    type: 'date',
    subType: 'date-until',
    title: {
      de: 'Ende Iteration',
      en: 'Iteration end',
    },
    filterType: 'date',
    selectable: true,
    fixed: false,
    visible: false,
    render: dateRenderWithTime,
  },
  accountItemsPercentage: {
    columnName: ColumnName.accountItemsPercentage,
    type: 'number',
    title: {
      de: 'Prozentualer Fortschritt',
      en: 'Progress percentage',
    },
    filterType: 'number',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  accountIteration: {
    columnName: ColumnName.accountIteration,
    type: 'number',
    title: {
      de: 'Iteration',
      en: 'Iteration',
    },
    filterType: 'number',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  accountProcessingTimeStatus: {
    columnName: ColumnName.accountProcessingTimeStatus,
    type: 'accountProcessingStatus',
    title: {
      de: 'Bearbeitungsphase',
      en: 'Execution phase',
    },
    filterType: 'dropdown',
    dropDownOptions: {
      anulled: { de: 'Entzogen', en: 'Unassigned' },
      ended: { de: 'Beendet', en: 'Ended' },
      locked: { de: 'Gesperrt', en: 'Locked' },
      notAttempted: { de: 'Nicht gestartet', en: 'Not started' },
      valid: { de: 'Bearbeitbar', en: 'Executable' },
    },
    dropDownPlaceholder: { de: 'Status ist...', en: 'Status is...' },
    selectable: true,
    fixed: false,
    visible: false,
    render: (value: any, context: Column) => optionsRender(value, context.dropDownOptions),
  },
  accountStartDate: {
    columnName: ColumnName.accountStartDate,
    type: 'date',
    title: {
      de: 'Start Iteration',
      en: 'Iteration start',
    },
    filterType: 'date',
    selectable: true,
    fixed: false,
    visible: false,
    render: dateRenderWithTime,
  },
  accountValidSince: {
    columnName: ColumnName.accountValidSince,
    type: 'date',
    title: {
      de: 'Gültig seit',
      en: 'Valid since',
    },
    filterType: 'date',
    selectable: true,
    fixed: false,
    visible: true,
    render: dateRenderWithTime,
  },
  accountValidUntil: {
    columnName: ColumnName.accountValidUntil,
    type: 'date',
    subType: 'date-until',
    title: {
      de: 'Gültig bis',
      en: 'Valid until',
    },
    filterType: 'date',
    selectable: true,
    fixed: false,
    visible: true,
    render: dateRenderWithTime,
  },
  assignmentMandatory: {
    columnName: ColumnName.assignmentMandatory,
    type: 'boolean',
    title: {
      de: 'Zuweisungsart',
      en: 'Assignment type',
    },
    filterType: 'dropdown',
    dropDownOptions: {
      true: { de: 'Verpflichtend', en: 'Mandatory' },
      false: { de: 'Freiwillig', en: 'Voluntary' },
    },
    selectable: true,
    fixed: false,
    visible: true,
    render: (value: any, context: Column) => optionsRender(value, context.dropDownOptions),
  },
  courseId: {
    columnName: ColumnName.courseId,
    type: 'number',
    title: {
      de: 'Kurs ID',
      en: 'Course ID',
    },
    filterType: 'number',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  courseItemExecCount: {
    columnName: ColumnName.courseItemExecCount,
    type: 'number',
    title: {
      de: 'Versuche',
      en: 'Attempts',
    },
    filterType: 'number',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  courseItemId: {
    columnName: ColumnName.courseItemId,
    type: 'number',
    title: {
      de: 'SCO ID',
      en: 'SCO ID',
    },
    filterType: 'number',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  courseItemLastModified: {
    columnName: ColumnName.courseItemLastModified,
    type: 'date',
    title: {
      de: 'Änderungsdatum',
      en: 'Last change',
    },
    filterType: 'date',
    selectable: true,
    fixed: false,
    visible: false,
    render: dateRender,
  },
  courseItemLocked: {
    columnName: ColumnName.courseItemLocked,
    type: 'boolean',
    title: {
      de: 'Gesperrt',
      en: 'Locked',
    },
    filterType: 'dropdown',
    dropDownOptions: {
      true: { de: 'Gesperrt', en: 'Locked' },
      false: { de: 'Nicht gesperrt', en: 'Not locked' },
    },
    selectable: true,
    fixed: false,
    visible: false,
    render: (value: any, context: Column) => optionsRender(value, context.dropDownOptions),
  },
  courseItemResult: {
    columnName: ColumnName.courseItemResult,
    type: 'number',
    title: {
      de: 'Ergebnis',
      en: 'Result',
    },
    filterType: 'number',
    selectable: true,
    fixed: false,
    visible: true,
    render: textRender,
  },
  courseItemStatus: {
    columnName: ColumnName.courseItemStatus,
    type: 'status',
    title: {
      de: 'SCO Status',
      en: 'SCO status',
    },
    filterType: 'dropdown',
    dropDownOptions: {
      complete: { de: 'Abgeschlossen', en: 'Complete' },
      failed: { de: 'Fehlgeschlagen', en: 'Failed' },
      incomplete: { de: 'Unvollständig', en: 'Incomplete' },
      notAttempted: { de: 'Nicht angefangen', en: 'Not attempted' },
      passed: { de: 'Bestanden', en: 'Passed' },
    },
    dropDownPlaceholder: { de: 'Status ist...', en: 'Status is...' },
    selectable: true,
    fixed: false,
    visible: true,
    render: (value: any, context: Column) => optionsRender(value, context.dropDownOptions),
  },
  courseItemTitle: {
    columnName: ColumnName.courseItemTitle,
    type: 'text',
    title: {
      de: 'SCO Titel',
      en: 'SCO title',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  courseSessionDiagnostic: {
    columnName: ColumnName.courseSessionDiagnostic,
    type: 'text',
    title: {
      de: 'Diagnosetext',
      en: 'Diagnostic string',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  courseSessionLastError: {
    columnName: ColumnName.courseSessionLastError,
    type: 'number',
    title: {
      de: 'Letzter Fehlercode',
      en: 'Last error code',
    },
    filterType: 'number',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  courseSessionRunning: {
    columnName: ColumnName.courseSessionRunning,
    type: 'boolean',
    title: {
      de: 'Bearbeitung aktiv',
      en: 'SCO running',
    },
    filterType: 'dropdown',
    dropDownOptions: {
      true: { de: 'In Bearbeitung', en: 'Currently running' },
      false: { de: 'Nicht in Bearbeitung', en: 'Currently not running' },
    },
    dropDownPlaceholder: { de: 'Session ist...', en: 'Session is...' },
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  courseStatus: {
    columnName: ColumnName.courseStatus,
    type: 'status',
    title: {
      de: 'Kursstatus',
      en: 'Course status',
    },
    filterType: 'dropdown',
    dropDownOptions: {
      complete: { de: 'Abgeschlossen', en: 'Complete' },
      failed: { de: 'Fehlgeschlagen', en: 'Failed' },
      incomplete: { de: 'Unvollständig', en: 'Incomplete' },
      notAttempted: { de: 'Nicht angefangen', en: 'Not attempted' },
      passed: { de: 'Bestanden', en: 'Passed' },
    },
    dropDownPlaceholder: { de: 'Status ist...', en: 'Status is...' },
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  courseTags: {
    columnName: ColumnName.courseTags,
    type: 'tags',
    title: {
      de: 'Kurs Schlagworte',
      en: 'Course Tags',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  courseTitle: {
    columnName: ColumnName.courseTitle,
    type: 'text',
    title: {
      de: 'Kurs',
      en: 'Course',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: true,
    render: textRender,
  },
  courseContributionsCount: {
    columnName: ColumnName.courseContributionsCount,
    type: 'number',
    title: {
      de: 'Kommentare',
      en: 'Comments'
    },
    filterType: 'number',
    selectable: true,
    fixed: false,
    visible: true,
    render: textRender
  },
  courseLastExecutionDate: {
    columnName: ColumnName.courseLastExecutionDate,
    type: 'date',
    title: {
      de: 'Letzerzugriff',
      en: 'Execution date'
    },
    filterType: 'date',
    selectable: true,
    fixed: false,
    visible: true,
    render: dateRender
  },
  curriculumId: {
    columnName: ColumnName.curriculumId,
    type: 'number',
    title: {
      de: 'Curriculum ID',
      en: 'Curriculum ID',
    },
    filterType: 'number',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  curriculumTags: {
    columnName: ColumnName.curriculumTags,
    type: 'tags',
    title: {
      de: 'Curriculum Schlagworte',
      en: 'Curriculum tags',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  curriculumTitle: {
    columnName: ColumnName.curriculumTitle,
    type: 'text',
    title: {
      de: 'Curriculum',
      en: 'Curriculum',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: true,
    render: textRender,
  },
  curriculumVariationCounter: {
    columnName: ColumnName.curriculumVariationCounter,
    type: 'number',
    title: {
      de: 'Curriculum-Pfadnummer',
      en: 'Curriculum path number',
    },
    filterType: 'number',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  curriculumVariationTitle: {
    columnName: ColumnName.curriculumVariationTitle,
    type: 'text',
    title: {
      de: 'Curriculum-Pfad',
      en: 'Curriculum path',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userArea: {
    columnName: ColumnName.userArea,
    type: 'text',
    title: {
      de: 'Hauptabteilung',
      en: 'Division',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userCompany: {
    columnName: ColumnName.userCompany,
    type: 'text',
    title: {
      de: 'Firma',
      en: 'Company',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userCostCenter: {
    columnName: ColumnName.userCostCenter,
    type: 'text',
    title: {
      de: 'Kostenstelle',
      en: 'Cost center',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userCostcenterDesc: {
    columnName: ColumnName.userCostcenterDesc,
    type: 'text',
    title: {
      de: 'Abteilung',
      en: 'Department',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userDateOfBirth: {
    columnName: ColumnName.userDateOfBirth,
    type: 'date',
    title: {
      de: 'Geburtsdatum',
      en: 'Date of birth',
    },
    filterType: 'date',
    selectable: true,
    fixed: false,
    visible: false,
    render: dateRender,
  },
  userEmail: {
    columnName: ColumnName.userEmail,
    type: 'text',
    title: {
      de: 'E-Mail-Adresse',
      en: 'E-mail address',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userEmployeeID: {
    columnName: ColumnName.userEmployeeID,
    type: 'text',
    title: {
      de: 'Pers.-Nr.',
      en: 'Employee ID',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userExtID: {
    columnName: ColumnName.userExtID,
    type: 'text',
    title: {
      de: 'Ext.-ID',
      en: 'External ID',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userFirstname: {
    columnName: ColumnName.userFirstname,
    type: 'text',
    title: {
      de: 'Vorname',
      en: 'First name',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: true,
    render: textRender,
  },
  userFreeTextAttribute1: {
    columnName: ColumnName.userFreeTextAttribute1,
    type: 'text',
    title: {
      de: '2. Jobdescription',
      en: '2nd job description',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userFreeTextAttribute2: {
    columnName: ColumnName.userFreeTextAttribute2,
    type: 'text',
    title: {
      de: '3. Jobdescription',
      en: '3rd job description',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userFreeTextAttribute3: {
    columnName: ColumnName.userFreeTextAttribute3,
    type: 'text',
    title: {
      de: '4. Jobdescription',
      en: '4th job description',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userFreeTextAttribute4: {
    columnName: ColumnName.userFreeTextAttribute4,
    type: 'text',
    title: {
      de: '5. Jobdescription',
      en: '5th job description',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userFreeTextAttribute5: {
    columnName: ColumnName.userFreeTextAttribute5,
    type: 'text',
    title: {
      de: 'Alternativkontakt 1',
      en: 'Alternate contact 1',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userFreeTextAttribute6: {
    columnName: ColumnName.userFreeTextAttribute6,
    type: 'text',
    title: {
      de: 'Alternativkontakt 2',
      en: 'Alternate contact 2',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userFreeTextAttribute7: {
    columnName: ColumnName.userFreeTextAttribute7,
    type: 'text',
    title: {
      de: 'Alternativkontakt 3',
      en: 'Alternate contact 3',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userGeID: {
    columnName: ColumnName.userGeID,
    type: 'text',
    title: {
      de: 'Tel. dienstl.',
      en: 'Business phone',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userHireDate: {
    columnName: ColumnName.userHireDate,
    type: 'date',
    title: {
      de: 'Einstellungsdatum',
      en: 'Date of employment',
    },
    filterType: 'date',
    selectable: true,
    fixed: false,
    visible: false,
    render: dateRender,
  },
  userHostID: {
    columnName: ColumnName.userHostID,
    type: 'text',
    title: {
      de: 'Tel. mobil',
      en: 'Mobile phone',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userId: {
    columnName: ColumnName.userId,
    type: 'number',
    title: {
      de: 'Benutzer ID',
      en: 'User ID',
    },
    filterType: 'number',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userJobCode: {
    columnName: ColumnName.userJobCode,
    type: 'text',
    title: {
      de: 'Jobdescription',
      en: 'Job description',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userLastname: {
    columnName: ColumnName.userLastname,
    type: 'text',
    title: {
      de: 'Nachname',
      en: 'Last name',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: true,
    render: textRender,
  },
  userLocation: {
    columnName: ColumnName.userLocation,
    type: 'text',
    title: {
      de: 'Standortcode',
      en: 'Location code',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userLocationDesc: {
    columnName: ColumnName.userLocationDesc,
    type: 'text',
    title: {
      de: 'Standortbezeichnung',
      en: 'Location name',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userLogin: {
    columnName: ColumnName.userLogin,
    type: 'text',
    title: $localize`:@@global_username:Username`,
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userSex: {
    columnName: ColumnName.userSex,
    type: 'gender',
    title: {
      de: 'Geschlecht',
      en: 'Gender',
    },
    filterType: 'dropdown',
    dropDownOptions: {
      1: { de: 'Weiblich', en: 'Female' },
      2: { de: 'Männlich', en: 'Male' },
      3: { de: 'Divers', en: 'Diverse' },
      4: { de: 'Unbekannt', en: 'Unkown' },
    },
    dropDownPlaceholder: { de: 'Geschlecht ist...', en: 'Gender is...' },
    selectable: true,
    fixed: false,
    visible: false,
    dataAccessor: (value: any): number => {
      return value ?? 4;
    },
    render: (value: any, context: Column) => optionsRender(value, context.dropDownOptions),
  },
  userSoeID: {
    columnName: ColumnName.userSoeID,
    type: 'text',
    title: {
      de: 'Personalnummer',
      en: 'Employee number',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userState: {
    columnName: ColumnName.userState,
    type: 'number',
    title: {
      de: 'Führungskraft',
      en: 'Superior',
    },
    filterType: 'dropdown',
    dropDownOptions: {
      1: { de: 'Nein', en: 'No' },
      2: { de: 'Ja', en: 'Yes' },
    },
    dropDownPlaceholder: { de: 'Führungskraft?...', en: 'Superior?...' },
    selectable: true,
    fixed: false,
    visible: false,
    dataAccessor: (value: any): string => {
      /*
       #TF-10726 - userState is a system field to define if a user is flagged superior
       and should only be displayed as a boolean.
       The value is not always set correctly, so we need to check if it is set to 1
       and subsume every other value as 2.
      */
      return value === '1' ? '1' : '2';
    },
    render: (value: any, context: Column) => optionsRender(value, context.dropDownOptions),
  },
  userSubstitute2ID: {
    columnName: ColumnName.userSubstitute2ID,
    type: 'text',
    title: {
      de: '2. Vertretung (Pers. Nr.)',
      en: '2nd deputy (User ID)',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userSubstituteID: {
    columnName: ColumnName.userSubstituteID,
    type: 'text',
    title: {
      de: '1. Vertretung (Pers. Nr.)',
      en: '1st deputy (User ID)',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  userSuperiorID: {
    columnName: ColumnName.userSuperiorID,
    type: 'text',
    title: {
      de: 'Vorgesetzter (Pers. Nr.)',
      en: 'Superior (User ID)',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  eventId: {
    columnName: ColumnName.eventId,
    type: 'number',
    title: {
      de: 'Veranstaltung ID',
      en: 'Event ID',
    },
    filterType: 'number',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  eventScheduleId: {
    columnName: ColumnName.eventScheduleId,
    type: 'number',
    title: {
      de: 'Termin ID',
      en: 'Event schedule ID',
    },
    filterType: 'number',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  offlineContentTitle: {
    columnName: ColumnName.offlineContentTitle,
    type: 'text',
    title: {
      de: 'Veranstaltungstitel',
      en: 'Event title',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  offlineContentDescription: {
    columnName: ColumnName.offlineContentDescription,
    type: 'text',
    title: {
      de: 'Beschreibung',
      en: 'Description',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  offlineContentType: {
    columnName: ColumnName.offlineContentType,
    type: 'text',
    title: {
      de: 'Veranstaltungsart',
      en: 'Content type',
    },
    filterType: 'dropdown',
    dropDownOptions: {
      document: { de: 'Dokument', en: 'Document' },
      talk: { de: 'Gespräch', en: 'Talk' },
      seminar: { de: 'Seminar', en: 'Seminar' },
      sds: { de: 'SDS', en: 'Side by side' },
      virco: { de: 'Virco.', en: 'Virtual event' },
    },
    dropDownPlaceholder: { de: 'Veranstaltung ist...', en: 'Content is...' },
    selectable: true,
    fixed: false,
    visible: false,
    render: (value: any, context: Column) => optionsRender(value, context.dropDownOptions),
  },
  offlineEventType: {
    columnName: ColumnName.offlineEventType,
    type: 'text',
    title: {
      de: 'Terminart',
      en: 'Event type',
    },
    filterType: 'dropdown',
    dropDownOptions: {
      TrainingEvening: { de: 'TrainingEvening', en: 'TrainingEvening' },
    },
    dropDownPlaceholder: { de: 'Termin ist...', en: 'Event is...' },
    selectable: true,
    fixed: false,
    visible: false,
    render: (value: any, context: Column) => optionsRender(value, context.dropDownOptions),
  },
  offlineEventStartDate: {
    columnName: ColumnName.offlineEventStartDate,
    type: 'date',
    title: {
      de: 'Startdatum',
      en: 'Begin',
    },
    filterType: 'date',
    selectable: true,
    fixed: false,
    visible: false,
    render: dateRenderWithTime,
  },
  offlineEventEndDate: {
    columnName: ColumnName.offlineEventEndDate,
    type: 'date',
    title: {
      de: 'Enddatum',
      en: 'End',
    },
    filterType: 'date',
    selectable: true,
    fixed: false,
    visible: false,
    render: dateRenderWithTime,
  },
  offlineEventLocation: {
    columnName: ColumnName.offlineEventLocation,
    type: 'text',
    title: {
      de: 'Ort',
      en: 'Location',
    },
    filterType: 'text',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  offlineEventMaxUserCount: {
    columnName: ColumnName.offlineEventMaxUserCount,
    type: 'text',
    title: {
      de: 'Max. Teilnehmer',
      en: 'Max. Members',
    },
    filterType: 'number',
    selectable: true,
    fixed: false,
    visible: false,
    render: textRender,
  },
  offlineContentStatus: {
    columnName: ColumnName.genericTextColumn,
    type: 'text',
    title: {
      de: 'Status',
      en: 'Status',
    },
    filterType: 'dropdown',
    dropDownOptions: {
      true: { de: 'Veröffentlicht', en: 'Published' },
      false: { de: 'Nicht veröffentlicht', en: 'Not published' },
    },
    dropDownPlaceholder: { de: 'Veranstaltung ist ...', en: 'Event is...' },
    selectable: true,
    fixed: false,
    visible: false,
    render: (value: any, context: Column) => optionsRender(value, context.dropDownOptions),
  },
};

export const DefaultColumnsCourse = {
  // keep in this order!
  courseTitle: ColumnDefaultConfig.courseTitle,
  userLastname: ColumnDefaultConfig.userLastname,
  userFirstname: ColumnDefaultConfig.userFirstname,
  courseItemStatus: ColumnDefaultConfig.courseItemStatus,
  courseItemResult: ColumnDefaultConfig.courseItemResult,
  courseId: ColumnDefaultConfig.courseId,
  courseItemExecCount: ColumnDefaultConfig.courseItemExecCount,
  courseItemId: ColumnDefaultConfig.courseItemId,
  courseItemLocked: ColumnDefaultConfig.courseItemLocked,
  courseItemTitle: ColumnDefaultConfig.courseItemTitle,
  courseSessionDiagnostic: ColumnDefaultConfig.courseSessionDiagnostic,
  courseSessionLastError: ColumnDefaultConfig.courseSessionLastError,
  courseSessionRunning: ColumnDefaultConfig.courseSessionRunning,
  courseStatus: ColumnDefaultConfig.courseStatus,
  userArea: ColumnDefaultConfig.userArea,
  userCompany: ColumnDefaultConfig.userCompany,
  userCostCenter: ColumnDefaultConfig.userCostCenter,
  userCostcenterDesc: ColumnDefaultConfig.userCostcenterDesc,
  userDateOfBirth: ColumnDefaultConfig.userDateOfBirth,
  userEmail: ColumnDefaultConfig.userEmail,
  userEmployeeID: ColumnDefaultConfig.userEmployeeID,
  userExtID: ColumnDefaultConfig.userExtID,
  userFreeTextAttribute1: ColumnDefaultConfig.userFreeTextAttribute1,
  userFreeTextAttribute2: ColumnDefaultConfig.userFreeTextAttribute2,
  userFreeTextAttribute3: ColumnDefaultConfig.userFreeTextAttribute3,
  userFreeTextAttribute4: ColumnDefaultConfig.userFreeTextAttribute4,
  userFreeTextAttribute5: ColumnDefaultConfig.userFreeTextAttribute5,
  userFreeTextAttribute6: ColumnDefaultConfig.userFreeTextAttribute6,
  userFreeTextAttribute7: ColumnDefaultConfig.userFreeTextAttribute7,
  userGeID: ColumnDefaultConfig.userGeID,
  userHireDate: ColumnDefaultConfig.userHireDate,
  userHostID: ColumnDefaultConfig.userHostID,
  userId: ColumnDefaultConfig.userId,
  userJobCode: ColumnDefaultConfig.userJobCode,
  userLocation: ColumnDefaultConfig.userLocation,
  userLocationDesc: ColumnDefaultConfig.userLocationDesc,
  userLogin: ColumnDefaultConfig.userLogin,
  userSex: ColumnDefaultConfig.userSex,
  userSoeID: ColumnDefaultConfig.userSoeID,
  userState: ColumnDefaultConfig.userState,
  userSubstitute2ID: ColumnDefaultConfig.userSubstitute2ID,
  userSubstituteID: ColumnDefaultConfig.userSubstituteID,
  userSuperiorID: ColumnDefaultConfig.userSuperiorID,
};

export const DefaultColumnsCurriculum = {
  // keep in this order!
  curriculumTitle: ColumnDefaultConfig.curriculumTitle,
  userLastname: ColumnDefaultConfig.userLastname,
  userFirstname: ColumnDefaultConfig.userFirstname,
  accountDisplayStatus: ColumnDefaultConfig.accountDisplayStatus,
  accountValidSince: ColumnDefaultConfig.accountValidSince,
  accountValidUntil: ColumnDefaultConfig.accountValidUntil,
  accountStartDate: ColumnDefaultConfig.accountStartDate,
  accountEndDate: ColumnDefaultConfig.accountEndDate,
  accountIteration: ColumnDefaultConfig.accountIteration,
  accountProcessingTimeStatus: ColumnDefaultConfig.accountProcessingTimeStatus,
  curriculumId: ColumnDefaultConfig.curriculumId,
  curriculumVariationCounter: ColumnDefaultConfig.curriculumVariationCounter,
  curriculumVariationTitle: ColumnDefaultConfig.curriculumVariationTitle,
  accountCreationDate: ColumnDefaultConfig.accountCreationDate,
  userArea: ColumnDefaultConfig.userArea,
  userCompany: ColumnDefaultConfig.userCompany,
  userCostCenter: ColumnDefaultConfig.userCostCenter,
  userCostcenterDesc: ColumnDefaultConfig.userCostcenterDesc,
  userDateOfBirth: ColumnDefaultConfig.userDateOfBirth,
  userEmail: ColumnDefaultConfig.userEmail,
  userEmployeeID: ColumnDefaultConfig.userEmployeeID,
  userExtID: ColumnDefaultConfig.userExtID,
  userFreeTextAttribute1: ColumnDefaultConfig.userFreeTextAttribute1,
  userFreeTextAttribute2: ColumnDefaultConfig.userFreeTextAttribute2,
  userFreeTextAttribute3: ColumnDefaultConfig.userFreeTextAttribute3,
  userFreeTextAttribute4: ColumnDefaultConfig.userFreeTextAttribute4,
  userFreeTextAttribute5: ColumnDefaultConfig.userFreeTextAttribute5,
  userFreeTextAttribute6: ColumnDefaultConfig.userFreeTextAttribute6,
  userFreeTextAttribute7: ColumnDefaultConfig.userFreeTextAttribute7,
  userGeID: ColumnDefaultConfig.userGeID,
  userHireDate: ColumnDefaultConfig.userHireDate,
  userHostID: ColumnDefaultConfig.userHostID,
  userId: ColumnDefaultConfig.userId,
  userJobCode: ColumnDefaultConfig.userJobCode,
  userLocation: ColumnDefaultConfig.userLocation,
  userLocationDesc: ColumnDefaultConfig.userLocationDesc,
  userLogin: ColumnDefaultConfig.userLogin,
  userSex: ColumnDefaultConfig.userSex,
  userSoeID: ColumnDefaultConfig.userSoeID,
  userState: ColumnDefaultConfig.userState,
  userSubstitute2ID: ColumnDefaultConfig.userSubstitute2ID,
  userSubstituteID: ColumnDefaultConfig.userSubstituteID,
  userSuperiorID: ColumnDefaultConfig.userSuperiorID,
};
