import { Translation } from './translation/translation.types';

export namespace ObjectUsageTypes {  

  export enum ObjectType {
    OfflineContent = 'OfflineContent',
    OfflineEvent = 'OfflineEvent'
  }

  export interface Usage {
    active: boolean;
    objectId?: number;
    objectTitle: Translation;
    objectType: ObjectType;
    objectUuid?: string;
  }

}
