import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { Component, ElementRef, HostBinding, Input, OnInit, Optional, Self, ViewChild } from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { MatFormFieldControl } from '@angular/material/form-field';
import { DropdownPosition, NgSelectComponent } from '@ng-select/ng-select';
import { Observable, of, Subject } from 'rxjs';
import { catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, take, tap } from 'rxjs/operators';
import { AnyObject, MacroPrincipals, MessageAccountInfo, Principal, PrincipalType, } from 'src/app/core/core.types';
import { CachedSubject } from '../../core/cached-subject';
import { MailComposerService } from '../../route/user/mail/mail-composer/mail-composer.service';
import { LanguageHelper } from '../../core/language.helper';
import { AdminUserAttributeService } from '../../route/admin/admin-user-attribute/admin-user-attribute.service';
import { UserAttributesForMessaging } from '../../core/admin-user-attribute.types';
import { naturalCompare } from '../../core/natural-sort';


const noop = () => {
};

/**
 * General component to select list of principals (users or groups).
 * It implements ngModel interface.
 */
@Component({
  selector: 'rag-select-user-groups',
  templateUrl: './select-user-groups.component.html',
  styleUrls: [ './select-user-groups.component.scss' ],
  providers: [
    { provide: MatFormFieldControl, useExisting: SelectUserGroupsComponent },  // part of MatFormFieldControl interface
  ],
})
export class SelectUserGroupsComponent
  implements OnInit, ControlValueAccessor, MatFormFieldControl<Principal[]> {

    // part of MatFormFieldControl interface
    static nextId = 0;

    @Input() appendPopupTo = 'body';
    @Input() macrosEnabled = false;

    @ViewChild('receiverElement', { static: true })
    _receiver: ElementRef;
    _types: Array<PrincipalType> = [
      PrincipalType.targetGroup, PrincipalType.user, PrincipalType.group,
    ];
    // part of MatFormFieldControl interface
    controlType = 'rag-select-user-groups';
    @HostBinding('attr.aria-describedby') describedBy = '';
    @Input() displayAll = false;
    focused = false;
    // part of MatFormFieldControl interface
    @HostBinding()
    id = `rag-select-user-groups-${SelectUserGroupsComponent.nextId++}`;
    isLoading = false;
    receiverInput$ = new Subject<string>();
    receivers$: Observable<Principal[]>;
    @Input()
    receiversData$: Observable<Principal[]> = null;
    selectedPrincipals: MessageAccountInfo[] = [];
    // part of MatFormFieldControl interface
    stateChanges = new Subject<void>();
    @Input() dropdownPosition: DropdownPosition = 'bottom';
    @Input() filterMailAccountsByTypes = false;
    private _disabled = false;
    private _ngSelectComponent: NgSelectComponent;
    // part of MatFormFieldControl interface
    private _placeholder: string;
    private _required = false;
    private _receiversData$: CachedSubject<MessageAccountInfo[]> = null;
    /**
     * @deprecated todo implement as EventEmitter with @Output
     */
    private onChangeCallback: (_: any) => void = noop;
    /**
     * @deprecated todo implement as EventEmitter with @Output
     */
    private onTouchedCallback: () => void = noop;

    constructor(
      @Optional() @Self() public ngControl: NgControl,
      private mailComposerService: MailComposerService,
      private adminUserAttributeService: AdminUserAttributeService,
    ) {
      if ( this.ngControl != null ) {
        this.ngControl.valueAccessor = this;
      }
    }

    // part of MatFormFieldControl interface
    @Input()
    get disabled(): boolean {
      return this._disabled;
    }

    set disabled(value: boolean) {
      this._disabled = coerceBooleanProperty(value);
      this._ngSelectComponent?.setDisabledState(this._disabled);
      this.stateChanges.next();
    }

    // part of MatFormFieldControl interface
    get empty() {
      return this.selectedPrincipals == null || this.selectedPrincipals.length === 0;
    }

    get errorState() {
      return this.ngControl.errors !== null && (this.ngControl.dirty || this.focused);
    }

    get ngSelectComponent(): NgSelectComponent {
      return this._ngSelectComponent;
    }

    @ViewChild('ngselect', { static: false })
    set ngSelectComponent(value: NgSelectComponent) {
      this._ngSelectComponent = value;
      if ( (value != null) && (this._disabled != null) ) {
        setTimeout(() => value.setDisabledState(this._disabled));
      }
    }

    // part of MatFormFieldControl interface
    @Input()
    get placeholder() {
      return this._placeholder;
    }

    set placeholder(plh) {
      this._placeholder = plh;
      this.stateChanges.next();
    }

    // part of MatFormFieldControl interface
    @Input()
    get required() {
      return this._required;
    }

    set required(req) {
      this._required = coerceBooleanProperty(req);
      this.stateChanges.next();
    }

    // part of MatFormFieldControl interface
    @HostBinding('class.floating')
    get shouldLabelFloat() {
      return this.focused || !this.empty;
    }

    @Input()
    public set types(value: string | PrincipalType[]) {
      if ( value == null ) {
        return;
      }
      if ( typeof value === 'string' ) {
        const _v = value.trim();
        if ( _v.length === 0 ) {
          return;
        }
        this._types = _v.split(',').map(typeValue => typeValue.toLowerCase() as PrincipalType);
      } else if ( typeof value === 'object' ) {
        this._types = (value).map(typeValue => typeValue.toLowerCase() as PrincipalType);
      }
    }

    get value() {
      return this.selectedPrincipals;
    }

    // part of MatFormFieldControl interface
    set value(principals: MessageAccountInfo[]) {
      this.selectedPrincipals = principals;
    }

    compareFunc(a: Principal, b: Principal) {
      return (a.id === b.id) && (a.type === b.type);
    }

    focusFunction() {
      if ( this._disabled || this.focused ) {
        // ignore input if disabled
        return;
      }

      this.focused = true;
      setTimeout(() => {
        this._ngSelectComponent.open();
      }, 0);
      this.onTouchedCallback();
    }

    focusOutFunction() {
      this.focused = false;
    }

    groupByFunc(principal: Principal) {
      switch (principal.type) {
        case PrincipalType.user:
          return $localize`:@@global_users:Users`;
        case PrincipalType.group:
          return $localize`:@@global_groups:Groups`;
        case PrincipalType.targetGroup:
          return $localize`:@@global_target_groups:Target groups`;
        case PrincipalType.macros:
          return $localize`:@@global_macros:Macros`;
      }
    }

    groupValueFunc = (key: string, children: Principal[]) => {
      let count;
      if (this.selectedPrincipals.length > 0) {
        count = children.filter(
          child => this.selectedPrincipals.find(
            selectedPrincipal => selectedPrincipal.id === child.id && selectedPrincipal.type === child.type) == null).length;
      } else {
        count = children.length;
      }

      return { name: key, total: count };
    };

    ngOnInit() {
      this.receivers$ = this.receiverInput$.pipe(
        startWith(''),
        debounceTime(400),
        distinctUntilChanged(),
        tap(() => this.isLoading = true),
        switchMap(this.findByTerm),
        tap(() => this.isLoading = false),
        catchError(() => {
          this.isLoading = false;
          return of([]);
        }),
      );
    }

    onContainerClick(event: MouseEvent) {
      this.focusFunction();
    }

    onModelChange($event: any) {
      this.onChangeCallback($event);
      this.stateChanges.next();
    }

    registerOnChange(fn: any) {
      this.onChangeCallback = fn;
    }

    registerOnTouched(fn: any) {
      this.registerOnTouched = fn;
    }

    setDescribedByIds(ids: string[]) {
      this.describedBy = ids.join(' ');
    }

    writeValue(selectedReceivers: Array<MessageAccountInfo>) {
      if ( selectedReceivers !== this.selectedPrincipals ) {
        this.selectedPrincipals = selectedReceivers ?? [];
        this.stateChanges.next();
      }
    }

    private findByTerm = (term: string): Observable<Principal[]> => {
      if ( term == null ) {
        return of([]);
      }
      term = term.toLocaleLowerCase().trim();
      if ('' === term) {
        return this.getMessageReceivers();
      }

      return this.getMessageReceivers()
        .pipe(map(receivers =>
          receivers.filter(receiver =>
            (
              receiver.type === undefined ||
              (this.macrosEnabled && receiver.type === PrincipalType.macros) ||
              this._types.includes(receiver.type)
            ) &&
            receiver.name.toLocaleLowerCase().includes(term))));
    };

    private getMessageReceivers(): Observable<Principal[]> {
      if ( this.receiversData$ != null ) {
        // TF-10379 sort input data alphabetically
        return this.receiversData$.pipe(map(data => data.sort((p1, p2) => p1.name.localeCompare(p2.name))));
      }

      if ( this._receiversData$ != null ) {
        return this.receiversData$ = this._receiversData$.withoutEmptyValuesWithInitial();
      }

      this._receiversData$ = new CachedSubject<MessageAccountInfo[]>(null);
      this.receiversData$ = this._receiversData$.withoutEmptyValues();
      this.mailComposerService.getReceiver()
        .pipe(take(1))
        .pipe(switchMap(mailAccounts => {
          let filteredMailAccounts = mailAccounts;
          if ( this.filterMailAccountsByTypes === true ) {
            filteredMailAccounts = mailAccounts
              .filter(principal => this._types.includes(principal.type) === true);
          }

          filteredMailAccounts.forEach(filteredMailAccount => {
            let name = '';
            if (filteredMailAccount?.userId != null) {
              name = `(${filteredMailAccount.userId}) `
            } else if (filteredMailAccount?.id != null) {
              name = `(${filteredMailAccount.id}) `
            }
            name += `${filteredMailAccount.name}`
            filteredMailAccount.name = name;
          })

          if ( this.macrosEnabled ) {
            const macroPrincipals = Array.from(MacroPrincipals)
              .filter(([ placeholder ]) => placeholder !== '{msg:userAccId}')
              .map(([ _, p ]) => ({
                id: p.id,
                name: p.name,
                type: p.type,
                userId: p.id,
                active: true,
              }))
              .concat(filteredMailAccounts);

            return this.adminUserAttributeService.getUserAttributesForMessaging()
              .pipe(map(userAttributesForMessaging =>
                this.combineUserAttributesToReceivers(macroPrincipals, userAttributesForMessaging)))
              .pipe(take(1))
              .pipe(catchError(() => {
                return macroPrincipals;
            }));
          }

          return of(filteredMailAccounts);
        }))
        .pipe(tap((data: unknown) => {
          const split = ((data as MessageAccountInfo[]) ?? [])
            .reduce((pV, item) => {
              (pV[item.type] ??= []).push(item);
              return pV;
            }, <AnyObject<MessageAccountInfo[]>>{});
          this._receiversData$.next([
            ...(split['macros'] ?? []),
            ...(split['group'] ?? []).sort((a, b) =>
              naturalCompare(a.name, b.name, false)),
            ...(split['user'] ?? []).sort((a, b) =>
              naturalCompare(a.name, b.name, false)),
          ]);
        }))
        .pipe(catchError(this._receiversData$.nextError))
        .subscribe();
      return this.receiversData$;
    }

  private combineUserAttributesToReceivers(
    macroPrincipals: MessageAccountInfo[],
    userAttributesForMessaging: UserAttributesForMessaging,
  ): MessageAccountInfo[]  {
    const userFields = userAttributesForMessaging.userAttributes;
    let attributeId = -7;
    const userAttributesMacroPrincipals = Object.values(userFields)
      .map(p => {
        attributeId = attributeId - 1;
        return {
          id: attributeId,
          name: LanguageHelper.objectToText(p.label),
          type: PrincipalType.macros,
          userId: attributeId,
          active: true,
          fieldId: '{usr:' + p.fieldId.toLowerCase() + '}',
        };
      });

    return macroPrincipals.concat(userAttributesMacroPrincipals);
  }
}
