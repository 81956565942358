import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PageHeaderComponent } from '../../../../component/page-header/page-header.component';
import { PipesModule } from '../../../../core/pipes/pipes.module';
import { LanguageHelper } from '../../../../core/language.helper';
import { CONTACT_CLH_DE, CONTACT_CLH_EN } from './contact-static.clh';


@Component({
  selector: 'rag-contact-static',
  standalone: true,
  imports: [
    CommonModule,
    PageHeaderComponent,
    PipesModule,
  ],
  templateUrl: './contact-static.component.html',
  styleUrls: [ './contact-static.component.scss' ]
})
export class ContactStaticComponent {

  readonly content: string;

  constructor() {
    const languageKey = LanguageHelper.getCurrentLanguage()?.key;
    if ( languageKey === 'de' ) {
      this.content = CONTACT_CLH_DE;
    } else {
      this.content = CONTACT_CLH_EN;
    }
  }

}
