<ng-template #tplForm>
  <rag-contact-form></rag-contact-form>
</ng-template>

<ng-template #tplStatic>
  <rag-contact-static></rag-contact-static>
</ng-template>

<ng-template #tplLogin>
  <div class="no-data">
    <span i18n="@@contact_wrapper_please_sign_in">
      Please <a routerLink="/login/redirect" [queryParams]="{url:'/contact'}">sign-in</a> to contact us.
    </span>
  </div>
</ng-template>

<ng-container
  [ngTemplateOutlet]="getTemplate(tplForm, tplStatic, tplLogin)"
></ng-container>
