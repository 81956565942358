import { Component } from '@angular/core';
import { TableControllerTypes } from '../table-controller/table-controller.types';
import { TableControllerComponent } from '../table-controller/table-controller.component';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { TableGroupingTypes } from './table-grouping.types';
import { TableHelper } from '../table-helper';
import { CtrlSingleUserDetailsCurriculumTypes } from '../../../route/ctrl/single-user/ctrl-single-user-details/ctrl-single-user-details-curriculum/ctrl-single-user-details-curriculum.types';
import { TableColumnMenuService } from '../table-column-menu/table-column-menu.service';
import { TableAccessors } from '../table.accessors';
import { TableGroupingHelper } from './table-grouping.helper';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort } from '@angular/material/sort';
import { naturalCompare } from '../../../core/natural-sort';


@Component({
  template: '',
})
export class TableGroupingControllerComponent<R extends TableGroupingTypes.TableRow<TP, TC>,
  RC extends TableGroupingTypes.TableRowChild<TP, TC>,
  RP extends TableGroupingTypes.TableRowParent<TP, TC>,
  M extends TableControllerTypes.ColumnMenuData,
  TC = any, TP = any>
  extends TableControllerComponent<R, M> {

  parentColumns: string[];

  constructor(
    tableColumnMenuService: TableColumnMenuService,
    dataSource: MatTableDataSource<R> = new MatTableDataSource<R>(),
  ) {
    super(tableColumnMenuService, dataSource);

    this.defaultSort = 'orderIndex';
    this.dataSource.filterPredicate = this.filterPredicate;
    this.dataSource.sortData = this.sortData;
    this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
  }

  asChild(row: R): RC {
    if ( this.isChild(row) ) {
      return row as unknown as RC;
    }
    return null;
  }

  asParent(row: R): RP {
    if ( this.isParent(row) ) {
      return row as unknown as RP;
    }
    return null;
  }

  isChild = (row: R): boolean => row?.$rowType === 'child';

  isChildRow = (index: number, row: R): boolean => this.isChild(row);

  filterForSelection(
    rows: R[] | null,
  ): R[] {

    if ( !(rows?.length > 0) ) {
      return [];
    }

    return rows
      .filter(entry => TableGroupingHelper.isFilterVisible(entry) && (entry.$rowType === 'child'));
  }

  /**
   * Check if collapse button is available.
   */
  isChildVisible(row: RP): boolean {
    if ( (row?.$rowType !== 'parent') || !(row.$children?.length > 0) ) {
      // not a parent row or does not have children
      return false;
    } else if ( this.isFilterActive ) {
      // check if any children match filter
      return TableGroupingHelper.isFilterVisible(row);
    } else {
      // without filter collapse is always available
      return true;
    }
  }

  isFilterMatch(row: R): boolean {
    if ( !this.isFilterActive ) {
      return true;
    }
    return row.$filterVisible;
  }

  isParent = (row: R): boolean => row?.$rowType === 'parent';

  isParentRow = (index: number, row: R): boolean => this.isParent(row);

  isRowChecked(row: R): boolean {
    return row?.$selectionStatus === 'checked';
  }

  isRowIndeterminate(row: R): boolean {
    return row?.$selectionStatus === 'indeterminate';
  }

  postOnToggleAll() {
    TableGroupingHelper.updateSelectionStatus(this.dataSource.data, this.selection);
  }

  onToggleSelection($event: MatCheckboxChange, row: R) {
    TableGroupingHelper.onToggleSelection($event.checked, row, this.selection);
    TableGroupingHelper.updateSelectionStatus(this.dataSource.data, this.selection);
    this.checkMultiActionsDisabled();
  }

  setTableData(data: R[]) {
    const selectedRows = this.selection.selected
      .map(row => row.$sortDefaultAsc);
    super.setTableData(data);
    this.restoreSelections(selectedRows);
  }

  showRowChild = (index: number, row: CtrlSingleUserDetailsCurriculumTypes.RowData): boolean => (row.$rowType === 'child');

  showRowParent = (index: number, row: CtrlSingleUserDetailsCurriculumTypes.RowData): boolean => (row.$rowType === 'parent');

  protected filterPredicate = (data: R): boolean => data.$visible && (
      data.$filterVisible ||
      this.asParent(data)?.$childVisible
    );

  /**
   * Method copied from Angular using console.log('fn', this.dataSource.sortData.toString())
   * and modified to apply natural sorting by default (10 > 2)
   */
  protected sortData = (data: R[], sort: MatSort): R[]  => {
    const active = sort.active;
    const direction = sort.direction;
    if (!active || direction == '') {
      return data;
    }
    return data.sort((a, b) => {
      let valueA = this.sortingDataAccessor(a, active);
      let valueB = this.sortingDataAccessor(b, active);
      // If there are data in the column that can be converted to a number,
      // it must be ensured that the rest of the data
      // is of the same type so as not to order incorrectly.
      const valueAType = typeof valueA;
      const valueBType = typeof valueB;
      if (valueAType !== valueBType) {
        if (valueAType === 'number') {
          valueA += '';
        }
        if (valueBType === 'number') {
          valueB += '';
        }
      }
      // If both valueA and valueB exist (truthy), then compare the two. Otherwise, check if
      // one value exists while the other doesn't. In this case, existing value should come last.
      // This avoids inconsistent results when comparing values to undefined/null.
      // If neither value exists, return 0 (equal).
      const comparatorResult = naturalCompare(valueA, valueB);
      /*
      // TF-??? replace original sort logic with natural compare to allow sorting numeric columns
      let comparatorResult = 0;
      if (valueA != null && valueB != null) {
        // Check if one value is greater than the other; if equal, comparatorResult should remain 0.
        if (valueA > valueB) {
          comparatorResult = 1;
        } else if (valueA < valueB) {
          comparatorResult = -1;
        }
      } else if (valueA != null) {
        comparatorResult = 1;
      } else if (valueB != null) {
        comparatorResult = -1;
      }*/
      return comparatorResult * (direction == 'asc' ? 1 : -1);
    });
  };

  protected sortingDataAccessor = (data: R, sortHeaderId: string) => {
    const column = this.columnMenuData?.menuItems?.[sortHeaderId];
    if ( column == null ) {
      // failed to find column details!
      return TableHelper.sortingDataAccessor(data, sortHeaderId);
    }

    // magic :D
    const direction = this.dataSource.sort?.direction;
    return TableGroupingHelper.sortingDataAccessor(data, sortHeaderId, direction,
      row => this.sortingDataForRow(row, column));
  };

  protected sortingDataForRow(data: R, column: TableControllerTypes.ColumnMenuItem): string {
    return TableAccessors.getSortValue(data, column.options, column.id);
  }

  private restoreSelections(selectedRows: string[]) {
    this.selection.clear();
    this.dataSource.data
      .forEach(row => {
        if ( !selectedRows.includes(row.$sortDefaultAsc) ) {
          return;
        }
        TableGroupingHelper.onToggleSelection(true, row, this.selection);
        TableGroupingHelper.updateSelectionStatus(this.dataSource.data, this.selection);
      });
    this.checkMultiActionsDisabled();
  }

}
