import { Component, Input, OnChanges, OnDestroy, SimpleChanges } from '@angular/core';
import { ControllingCurriculumComponent } from '../controlling-curriculum/controlling-curriculum.component';
import { ControllingSingleUserTypes } from '../../../core/ctrl-single-user.types';
import { PrincipalService } from '../../../core/principal/principal.service';
import { PermissionStates } from '../../../core/principal/permission.states';
import { tap } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';


@Component({
  selector: 'rag-controlling-curriculum-multi-actions',
  templateUrl: './controlling-curriculum-multi-actions.component.html',
  styleUrls: [ './controlling-curriculum-multi-actions.component.scss' ],
})
export class ControllingCurriculumMultiActionsComponent
  implements OnChanges, OnDestroy {

  @Input() controllingComponent: ControllingCurriculumComponent;
  editUserUrl: string;
  maySaveUser = false;
  @Input() userDetailsView: ControllingSingleUserTypes.UserDetailsResponse;

  protected permissions: PermissionStates;

  constructor(
    private principalService: PrincipalService,
  ) {

    this.principalService.permissionStates$
      .pipe(tap(permissions => this.permissions = permissions))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  multiActionsDisabled(): boolean {

    if ( this.controllingComponent?.inputDisabled ) {
      return true;
    }

    if ( this.maySaveUser ) {
      return false;
    }

    return this.controllingComponent?.multiActionsDisabled ||
      this.controllingComponent?.canChangeData === false;
  }

  ngOnChanges(_changes: SimpleChanges): void {
    const controllingUser = this.userDetailsView?.controllingUser;
    this.maySaveUser = (controllingUser?.userId > 0) && controllingUser?.rbacActions?.maySave;
    this.editUserUrl = this.maySaveUser ? `/admin/user/edit/${controllingUser.userId}` : '';
  }

}
