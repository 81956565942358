import { Component, OnDestroy, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ApplicationStateService } from '../../../core/application-state.service';
import { NavItem, NavItemSpecial } from '../../../core/navigation/navigation.types';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { HeaderLogoComponent } from '../header-logo/header-logo.component';
import { HeaderNavTopModule } from '../header-nav-top/header-nav-top.module';
import { tap } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { TopNavigationComponent } from '../../top-navigation/top-navigation.component';
import { HeaderService } from '../../../core/navigation/header.service';
import {
  ContentAutocompleteV2Component
} from '../../elearning/content-autocomplete-v2/content-autocomplete-v2.component';
import { RuntimeEnvironmentService } from '../../../core/runtime-environment.service';

@Component({
  selector: 'rag-header-v2',
  standalone: true,
  imports: [CommonModule, MatButtonModule, MatIconModule, HeaderLogoComponent, ContentAutocompleteV2Component, HeaderNavTopModule, TopNavigationComponent],
  templateUrl: './header-v2.component.html',
  styleUrls: ['./header-v2.component.scss']
})
export class HeaderV2Component implements OnDestroy {

  mainItems: NavItem[] | null;
  topItems: NavItem[] | null;
  @Input() showContentSearch = false;

  showSideNav = true;
  isGtMedium = true;
  isSmall = false;
  alphaBetaProd = '';
  isNotProduction = false;


  constructor(
    private appState: ApplicationStateService,
    private headerService: HeaderService,
    private runtimeEnvironmentService: RuntimeEnvironmentService,
  ) {

    this.headerService.getNavigation()
      .pipe(tap(navigationData => {
        this.showContentSearch = navigationData?.showContentSearch ?? false;
        const topItems = navigationData?.top ?? [];
        this.topItems = (topItems.length > 0) ? topItems : null;
        const mainItems = navigationData?.main ?? [];
        this.mainItems = (mainItems.length > 0) ? mainItems : null;
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    this.appState.viewportState$
      .pipe(tap(state => {
        this.isSmall = state.isSmall();
        this.isGtMedium = state.isGtMedium();
        this.showSideNav = this.isSmall ? (this.topItems?.length > 0) : (this.mainItems?.length > 0);
      }))
      .pipe(takeUntilDestroyed(this))
      .subscribe();

    const env = this.runtimeEnvironmentService?.environment;
    this.isNotProduction = env?.production == null || env?.production !== true;
    if (this.isNotProduction) {
      this.alphaBetaProd = env?.alphabetaprod;
    }

  }

  getLanguageItem(): NavItem[] {
    return this.topItems?.filter(item => item.special === NavItemSpecial.language || item.special === NavItemSpecial.logout);
  }
  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  onToggleSideNav(): void {
    this.appState.toggleSidenav();
    this.appState.addToMenuItemStack(null);
    this.appState.clearMenuItemStack();
    this.appState.addToMenuItemStack(this.mainItems);
  }

}
