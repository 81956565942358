import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuestGuard } from './auth-guest.guard';
import { AuthGuard } from './auth.guard';
import { LoadingPageComponent } from './component/loading-page/loading-page.component';
import { DirtyCheckService } from './core/dirty-check.service';
import { ContextItemResolver } from './core/learner-account/context-item.resolver';
import { PrincipalResolver } from './core/principal/principal.resolver';
import { ContentResolverService } from './core/resolvers/content-resolver.service';
import { OfflineContentLearnerResolverService } from './core/resolvers/offline-content-learner.resolver';
import { PurchaseResultsResolverService } from './core/resolvers/purchase-results-resolver.service';
import { UserProfileExportResolver } from './core/resolvers/user-profile-export.resolver';
import { SideNavContainerHeightGuard } from './core/side-nav-container-height.guard';
import { ChangeNotesComponent } from './route/main/change-notes/change-notes.component';
import { ContactFormComponent } from './route/main/contact/contact-form/contact-form.component';
import { LoginOverviewComponent } from './route/main/login-overview/login-overview.component';
import { LoginRedirectComponent } from './route/main/login/login-redirect/login-redirect.component';
import { PublicCatalogRedirectGuard } from './route/main/login/public-catalog-redirect.guard';
import { NotFound404Component } from './route/main/not-found-404/not-found-404.component';
import { PasswordForgottenComponent } from './route/main/password-forgotten/password-forgotten.component';
import { PasswordUpdateComponent } from './route/main/password-update/password-update.component';
import { PostLoginComponent } from './route/main/post-login/post-login.component';
import { PostLoginGuard } from './route/main/post-login/post-login.guard';
import { PostLoginResolver } from './route/main/post-login/post-login.resolver';
import { TechCheckComponent } from './route/main/tech-check/tech-check.component';
import { CatalogRedirectGuard } from './route/user/catalog/catalog-redirect.guard';
import { ContentContextModule } from './route/user/content-context/content-context.module';
import { ContextOverviewComponent } from './route/user/content-context/context-overview/context-overview.component';
import { ContentLearnerCockpitComponent } from './route/user/content-learner-cockpit/content-learner-cockpit.component';
import { ContentOfflineComponent } from './route/user/content/content-offline/content-offline.component';
import { ContentOfflineGuard } from './route/user/content/content-offline/content-offline.guard';
import { ContentComponent } from './route/user/content/content.component';
import { ContentGuard } from './route/user/content/content.guard';
import { LearnerCourseTodoComponent } from './route/user/content/learner-course/learner-course-todo/learner-course-todo.component';
import { LearnerCourseGuard } from './route/user/content/learner-course/learner-course-util/learner-course.guard';
import { CurriculumCockpitComponent } from './route/user/curriculum-cockpit/curriculum-cockpit.component';
import { CurriculumCockpitResolver } from './route/user/curriculum-cockpit/curriculum-cockpit.resolver';
import { GamificationOverviewComponent } from './route/user/gamification-overview/gamification-overview.component';
import { GamificationOverviewGuard } from './route/user/gamification-overview/gamification-overview.guard';
import { GamificationOverviewResolver } from './route/user/gamification-overview/gamification-overview.resolver';
import { HomeComponent } from './route/user/home/home.component';
import { PostLoginRedirectGuard } from './route/user/home/post-login-redirect.guard';
import { LiveAgendaComponent } from './route/user/live-agenda/live-agenda.component';
import { MediaLibraryComponent } from './route/user/media-library/media-library.component';
import { QACreateComponent } from './route/user/qa/qa-create/qa-create.component';
import { QAListResolver } from './route/user/qa/qa-list/qa-list.resolver';
import { QAComponent } from './route/user/qa/qa.component';
import { ScoGuardService } from './route/user/sco/sco-guard.service';
import { ScoResolverService } from './route/user/sco/sco-resolver.service';
import { ScoComponent } from './route/user/sco/sco.component';
import { TransactionsListResolver } from './route/user/transactions/transactions-list/transactions-list.resolver';
import { TransactionsComponent } from './route/user/transactions/transactions.component';
import { UserProfileComponent } from './route/user/user-profile/user-profile.component';
import { UserProfileModule } from './route/user/user-profile/user-profile.module';
import { UserProfileResolver } from './route/user/user-profile/user-profile.resolver';
import { ContactWrapperComponent } from './route/main/contact/contact-wrapper/contact-wrapper.component';
import { MediaLibraryShowFileComponent } from './route/user/media-library-show-file/media-library-show-file.component';
import { MediaLibraryResolverService } from './route/user/media-library/media-library-resolver.service';


const routes: Routes = [
  {
    path:'quest',
    canActivate: [ AuthGuard ],
    loadChildren: () => import('./route/user/quest/quest-render.module').then(m => m.QuestRenderModule),
  },
  // Main learner pages
  {
    path: 'login',
    children: [ {
      path: '',
      pathMatch: 'full',
      component: LoginOverviewComponent,
      canActivate: [ PublicCatalogRedirectGuard ],
    }, {
      path: 'redirect',
      component: LoginRedirectComponent,
    } ],
  },
  {
    path: 'home',
    component: HomeComponent,
    canActivate: [ AuthGuard, PostLoginRedirectGuard ],
    canDeactivate: [ SideNavContainerHeightGuard ]
  },
  {
    path: 'post-login',
    component: PostLoginComponent,
    canActivate: [ AuthGuard, PostLoginGuard ],
    canDeactivate: [ PostLoginGuard ],
    data: { skipPostLoginRedirect: true },
    resolve: {
      postLogin: PostLoginResolver,
    },
  },
  {
    path: 'forgot-password',
    component: PasswordForgottenComponent,
    canActivate: [ AuthGuestGuard ],
    canDeactivate: [ DirtyCheckService ],
  },
  { path: 'update-password', component: PasswordUpdateComponent, canActivate: [ AuthGuestGuard ] },
  {
    path: 'contact',
    component: ContactWrapperComponent,
    data: { skipPostLoginRedirect: true },
    resolve: { principal: PrincipalResolver },
  },
  { path: 'terms', component: NotFound404Component, resolve: { principal: PrincipalResolver } },
  { path: 'policies', component: NotFound404Component, resolve: { principal: PrincipalResolver } },
  {
    path: 'library',
    canActivate: [ AuthGuard ],
    data: {
      checkPermission: 'navDocLib',
    },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: MediaLibraryComponent,
      },
      {
        path: 'view/:uuid',
        component: MediaLibraryShowFileComponent,
        resolve: {
          fileInfo: MediaLibraryResolverService
        }
      }
    ]
  },
  {
    path: 'c',
    children: [
      {
        path: '', pathMatch: 'full',
        redirectTo: '/catalog',
      },
      {
        path: ':shortUrl',
        component: LoadingPageComponent,
        canActivate: [ CatalogRedirectGuard ],
      },
    ],
  },
  {
    path: 'catalog',
    loadChildren: () => import('./route/user/catalog/catalog.module').then(m => m.CatalogModule),
  },
  {
    path: 'process',
    loadChildren: () => import('./route/main/process/process.module').then(m => m.ProcessModule),
  },
  {
    path: 'bookings',
    loadChildren: () => import('./route/user/bookings/bookings.module').then(m => m.BookingsModule)
  },
  {
    path: 'qa',
    canActivate: [ AuthGuard ],
    data: {
      checkPermission: 'navQA'
    },
    children: [
      {
        path: '', pathMatch: 'full',
        component: QAComponent,
        resolve: { content: QAListResolver }
      },
      {
        canActivate: [ AuthGuard ],
        path: 'new',
        component: QACreateComponent,
        data: {
          checkPermission: 'navNewQA'
        }
      }
    ]
  },
  {
    path: 'content/:id',
    canActivate: [ AuthGuard ],
    runGuardsAndResolvers: 'always',
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: ContentComponent,
        canActivate: [ ContentGuard ],
        resolve: {
          content: ContentResolverService
        },
      },
      {
        path: 'cockpit',
        pathMatch: 'full',
        component: ContentLearnerCockpitComponent,
        resolve: { content: ContentResolverService },
      },
      {
        path: 'cockpit/OnlineAgenda',
        pathMatch: 'full',
        component: LiveAgendaComponent,
        resolve: {
          content: ContentResolverService,
        },
      },
      {
        path: 'cockpit/CustomView',
        pathMatch: 'full',
        component: CurriculumCockpitComponent,
        resolve: {
          content: CurriculumCockpitResolver,
        },
      },
      /*{
        path: 'cockpit/:uuid',
        // todo replace with generic component that loads a configuration for the used cockpit type
        component: LearnerCockpitComponent,
        resolve: { content: ContentResolverService },
      },*/
      {
        path: 'offline/:itemId',
        component: ContentOfflineComponent,
        resolve: { learnerResolver: OfflineContentLearnerResolverService },
      },
      {
        path: 'sco/:itemId',
        component: ScoComponent,
        canDeactivate: [ ScoGuardService ],
        resolve: { content: ScoResolverService },
      },
      {
        path: 'context/:itemId',
        component: ContextOverviewComponent,
        resolve: { data: ContextItemResolver },
      },
      {
        path: 'todo/:itemId',
        component: LearnerCourseTodoComponent,
        canDeactivate: [ DirtyCheckService ],
        canActivate: [ LearnerCourseGuard ],
        data: [ 'ToDo' ],
      },
    ],
  },

  {
    path: 'run',
    canActivate: [ AuthGuard ],
    children: [
      { path: '', pathMatch: 'full', redirectTo: '/content-overview' },
      {
        path: 'offline/:contentId',
        component: ContentOfflineComponent,
        canActivate: [ ContentOfflineGuard, PurchaseResultsResolverService ],
        resolve: {
          learnerResolver: OfflineContentLearnerResolverService
        },
        data: { onlyValid: true },
      },
      {
        path: 'sco/:itemId',
        component: ScoComponent,
        canDeactivate: [ ScoGuardService ],
        resolve: { content: ScoResolverService },
      },
      {
        path: 'todo/:itemId',
        pathMatch: 'full',
        canDeactivate: [ DirtyCheckService ],
        component: LearnerCourseTodoComponent,
        canActivate: [ LearnerCourseGuard ],
        data: [ 'ToDo' ],
        //     children: [
        //       {
        //         runGuardsAndResolvers: 'always',
        //         data: ['ToDo'],
        //         path: '',
        //         pathMatch: 'full',
        //         component: LearnerCourseTodoComponent,
        //         canActivate: [ LearnerCourseGuard ],
        //       },
        //       {
        //         runGuardsAndResolvers: 'always',
        //         data: ['SampleSolution'],
        //         canActivate: [ LearnerCourseGuard ],
        //         path: 'sample-solution',
        //         pathMatch: 'full',
        //         component: LearnerCourseToDoSampleSolutionComponent,
        //       },
        //       {
        //         runGuardsAndResolvers: 'always',
        //         path: 'self-estimation',
        //         pathMatch: 'full',
        //         component: LearnerCourseSelfEstimationComponent,
        //         data: ['SelfEstimation'],
        //         canActivate: [ LearnerCourseGuard ],
        //       }
        //     ]
      },
    ],
  },
  {
    path: 'my-profile',
    canActivate: [ AuthGuard ],
    data: { skipPostLoginRedirect: true },
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: UserProfileComponent,
        resolve: { profile: UserProfileResolver },
        data: { skipPostLoginRedirect: true },
      },
      {
        path: 'export',
        resolve: {
          settings: UserProfileExportResolver
        }
      }
    ]
  },
  {
    path: 'awards',
    component: GamificationOverviewComponent,
    canActivate: [ GamificationOverviewGuard ],
    resolve: {
      data: GamificationOverviewResolver,
    }
  },
  {
    path: 'transactions',
    component: TransactionsComponent,
    canActivate: [ AuthGuard ],
    resolve: {
      transactions: TransactionsListResolver,
    },
    data: { skipPostLoginRedirect: true },
  },
  { path: 'tech-check', component: TechCheckComponent, canActivate: [ AuthGuard ] },
  // Profile pages
  { path: 'my-messages', component: NotFound404Component, canActivate: [ AuthGuard ] },
  { path: 'my-uploaded-contents', component: NotFound404Component, canActivate: [ AuthGuard ] },

  { path: 'change-notes', component: ChangeNotesComponent, canActivate: [ AuthGuard ] },

  {
    path: 'medical',
    loadChildren: () => import('./route/user/medical-dashboard/medical-dashboard.routing')
      .then( m => m.MedicalDashboardRoutingModule )
  },


  // Fallback for Home and not registered URLs
  { path: '', redirectTo: '/home', pathMatch: 'full' },
  { path: '**', component: NotFound404Component },
];

@NgModule({
  imports: [
    ContentContextModule,
    RouterModule.forRoot(routes, { useHash: true/*, enableTracing: true*/ }),
    UserProfileModule,
    ContactWrapperComponent,
    MediaLibraryShowFileComponent,
  ],
  exports: [
    RouterModule,
  ],
})
export class AppRoutingModule {
}
