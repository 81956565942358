import { CommonModule } from '@angular/common';
import { Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { ContentHelper } from 'src/app/core/content/content.helper';
import { ImageableContentReference } from 'src/app/core/core.types';
import { DistributionTypeHelper } from 'src/app/core/distribution-type-helper';


@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatIconModule,
    MatTooltipModule,
    RouterModule
  ],
  selector: 'rag-learner-ofl-events-state',
  templateUrl: './learner-ofl-events-state.component.html',
  styleUrls: [ './learner-ofl-events-state.component.scss' ],
})
export class LearnerOflEventsStateComponent
  implements OnInit, OnChanges {

  @Input() content: ImageableContentReference;
  @Input() parent?: ImageableContentReference;
  @Input() tooltipText?: string = $localize`:@@global_details:Details`;
  @HostBinding('style.display') styleDisplay: 'none' | null = null;
  url: string = null;
  locked = false;

  ngOnInit(): void {
    this.locked = ContentHelper.isLocked(this.content);
  }

  get canBook(): boolean {
    // TF-3026 allow null as "canBook"
    return this.content?.canBook !== false;
  }

  get hasBooked(): boolean {
    // todo TF-3026 check if this works with multiple event accounts
    return this.content?.currentEventId > 0;
  }

  get isOfflineContent(): boolean {
    return DistributionTypeHelper.isOfflineContent(this.content.objType);
  }

  getTemplate(tplBookable: TemplateRef<any>, tplBooked: TemplateRef<any>,
    tplNotBookable: TemplateRef<any>): TemplateRef<any> {

    if ( this.hasBooked ) {
      return tplBooked;
    } else if ( this.canBook ) {
      return tplBookable;
    } else {
      return tplNotBookable;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {

    if (changes.content !== undefined && !changes.content.firstChange) {
      this.locked = ContentHelper.isLocked(changes.content.currentValue);
    }

    if ( !this.isOfflineContent ) {
      // hide component and exit
      this.styleDisplay = 'none';
      return;
    } else {
      this.styleDisplay = null;
    }

    this.url = this.getUrl();
  }

  private getUrl(): string {
    if ( this.content == null ) {
      // no content -> no url
      return null;
    }

    if ( this.parent != null ) {
      return '/content/' + this.parent.id + '/offline/' + this.content.id;
    } else {
      return '/run/offline/' + this.content.id;
    }
  }

}
