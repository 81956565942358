<rag-dialog-header>
  <span i18n="@@curriculum_switch_path">Switch path</span>
</rag-dialog-header>

<div mat-dialog-content>
  <ng-template #tplDataLoading>
    <div class="no-data">
      <rag-loading-indicator
      ></rag-loading-indicator>
    </div>
  </ng-template>

  <ng-template #tplColumnCurrentPath let-row="row">
    <ng-container *ngIf="asChild(row) as childRow">
      {{childRow.currentPath || '—'}} (ID: {{childRow.currentVariationCounter}})
    </ng-container>
  </ng-template>

  <ng-template #tplColumnDefault let-column="column" let-row="row">
    <span *ngIf="column.options['dataType'] === 'date'">{{row[column.id] | formatDate}}</span>
    <span *ngIf="column.options['dataType'] === 'number'">{{row[column.id]}}</span>
    <span *ngIf="column.options['dataType'] === 'text'">{{row[column.id] | localeText}}</span>
    <span *ngIf="column.options['dataType'] === 'dropdown'">
      {{column.options['dropDownOptions'][row[column.id]]}}
    </span>
  </ng-template>

  <div class="content">
    <form
      *ngIf="(columns?.length > 0) else tplDataLoading"
      [formGroup]="formGroup">
      <table
        [dataSource]="dataSource"
        mat-table
        matSort>
        <ng-container *ngFor="let columnId of columns" [matColumnDef]="columnId">
          <ng-container *ragVar="getTableColumn(columnId) as column">
            <th *matHeaderCellDef mat-header-cell mat-sort-header>
              {{column.title | localeText}}
            </th>
            <td *matCellDef="let row" mat-cell>
              <ng-container
                [ngTemplateOutlet]="column?.id === 'currentPath' ? tplColumnCurrentPath : tplColumnDefault"
                [ngTemplateOutletContext]="{row: row, column: column}"
              ></ng-container>
            </td>
          </ng-container>
        </ng-container>

        <ng-container matColumnDef="curriculum">
          <td
            *matCellDef="let row"
            class="curriculum"
            mat-cell [colSpan]="columns.length"
            [ngClass]="{'invalid': isControlInvalid(row)}">
            <mat-form-field
              *ngIf="asParent(row)?.$data as curriculum"
              appearance="outline"
            >
              <mat-label>{{curriculum.curriculumTitle}}</mat-label>
              <mat-select required [formControlName]="'' + curriculum.curriculumId">
                <mat-option
                  *ngFor="let path of curriculum.pathInfo"
                  [value]="path.variationCounter"
                >
                  {{path.curriculumPathTitle || '—'}} (ID: {{path.variationCounter}})
                </mat-option>
              </mat-select>
            </mat-form-field>
          </td>
        </ng-container>

        <tr *matHeaderRowDef="columns; sticky: true" class="title" mat-header-row></tr>
        <tr *matRowDef="let row; columns: ['curriculum']; when: isParentRow" mat-row class="parent"></tr>
        <tr
          *matRowDef="let row; columns: columns; when: isChildRow"
          mat-row
          class="child"
          [ngClass]="{changed: isChildChanged(row)}"
        ></tr>
      </table>
    </form>
  </div>
</div>

<div mat-dialog-actions>
  <button class="rag-stroked-button" mat-stroked-button type="button" [mat-dialog-close]="null">
    <span i18n="@@global_cancel">Cancel</span>
  </button>

  <button
    cdkFocusInitial
    color="primary"
    mat-flat-button
    type="button"
    [disabled]="formGroup.pristine || formGroup.invalid"
    [mat-dialog-close]="result">
    <span i18n="@@global_save">Save</span>
  </button>
</div>
