<div class="header-top">
<!--  <div>-->
    <button
      *ngIf="showSideNav"
      (click)="onToggleSideNav()"
      mat-icon-button
      class="menu-icon"
    >
      <mat-icon svgIcon="menu"></mat-icon>
    </button>
  <div
    *ngIf="isNotProduction"
    class="alphabetaprod"
    [attr.data-alphabetaprod]="alphaBetaProd"
    [innerText]="alphaBetaProd"></div>

  <rag-header-logo class="logo"></rag-header-logo>
<!--  </div>-->

  <rag-content-autocomplete-v2
    class="content-autocomplete"
    *ngIf="showContentSearch && isGtMedium"
  ></rag-content-autocomplete-v2>

  <nav
    class="top-navigation"
    *ngIf="isSmall ? getLanguageItem() != null : topItems != null"
    rag-top-navigation
    [navItems]="isSmall ? getLanguageItem() : topItems"
    [isSmall]="isSmall"
  ></nav>
</div>

<rag-content-autocomplete-v2
  *ngIf="showContentSearch && !isSmall && !isGtMedium"
  class="autocomplete-margin"
></rag-content-autocomplete-v2>
