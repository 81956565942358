import { Translation } from './translation/translation.types';
import { AnyObject, FileInfo, UserField } from './core.types';
import { AssignmentDialogTypes } from '../component/assignment-dialog/assignment-dialog.types';
import { ProfileFieldTypes } from './input/profile-field/profile-field.types';
import { ReducedUserAttributes } from '../route/admin/admin-reduced-user-attributes/admin-reduced-user-attributes.types';
import { ColumnName } from './column-settings/column-settings.types';

export interface UserAttributeSettings {
  text: Translation;
  value: UserAttributeSettingsValue;
}

export enum UserAttributeSettingsValue {
  readAdmin = 'readAdmin',
  readLearner = 'readLearner',
  required = 'required',
  requiresConsent = 'requiresConsent',
  updateLearner = 'updateLearner',
  updatePSTD = 'updatePSTD',
  recordField = 'recordField',
  readController = 'readController',
}

export interface UserAttributeTypeSetting {
  language: string;
  label: string;
  dataBaseValue: string;
  disabled: boolean;
}

export interface UserAttribute {
  attributeField: string;
  attributeType: UserAttributeType;
  consentText?: Translation;
  info: Translation;
  label: Translation;
  readAdmin: boolean;
  readLearner: boolean;
  modifiable?: boolean;
  orderIndex?: number;
  required: boolean;
  requiresConsent?: boolean;
  systemField?: boolean;
  updatePSTD: boolean;
  updateLearner: boolean;
  recordField: boolean;
  readController: boolean;
  knownAttribute?: boolean;
  attributeName: string;
  attributeGroupId: number;
  attributeOptions: UserAttributeOption[];
}

export interface UpdateUserAttributeResponse {
  attributes: AnyObject<UserAttribute>;
}

export interface UpdateAttributesResponse {
  userAttribute: UserAttribute;
}

export interface UserAttributesForMessaging {
  userAttributes: AnyObject<UserField>;
}

export interface UserAttributeGroup {
  id: number;
  title: Translation;
  userAttributes: string[];
  orderIndex: number;
  profileFields?: ProfileFieldTypes.ProfileField[];
}

export interface UserAttributeResponse {
  userAttributes: AnyObject<UserAttribute>;
  userAttributeGroups: UserAttributeGroup[];
  reducedUserAttributes: AnyObject<ReducedUserAttributes.Attribute>;
}

export interface UserAttributeResolverResponse {
  userAttributeGroups: UserAttributeGroup[];
  attribute: any;
  reducedUserAttributes: AnyObject<ReducedUserAttributes.Attribute>;
}

export interface UserAttributeTypeWithText {
  type: UserAttributeType;
  label: Translation;
}

/**
 * @see TableColumnDataType
 */
export enum UserAttributeType {
  date = 'date',
  dropdown = 'dropdown',
  email = 'email',
  multiselect = 'multiselect',
  number = 'number',
  password = 'password',
  radio = 'radio',
  text = 'text',
}

export interface UserAttributeOption {
  attributeName: string;
  label?: Translation;
  optionValue: string;
}

export interface UserAttributeOptionPreviewData {
  userAttributeOptions: UserAttributeOption[];
  fieldLabel: Translation;
  type: UserAttributeType;
  attributeName: string;
  info: Translation;
}

export interface UserAttributeOptionDialogResponse {
  label: Translation;
  dataBaseValue: string;
}

export interface UserAttributeContext {
  id: number;
  accountId: number;
  context: string;
  userAttributes: string[];
  privacyUrl: string;
  privacyFile?: FileInfo;
  agreementFile?: FileInfo;
  privacyFileUUID?: string;
  agreementFileUUID?: string;
  agreementUrl: string;
}

export interface UserAttributeContextResponse {
  context?: UserAttributeContext;
  userAttributes: AnyObject<UserAttribute>;
  userAttributeGroups: UserAttributeGroup[];
}

export interface UserAttributeContextSaveData {
  context: string;
  userAttributes: string[];
  privacyFileUUID: string;
  agreementFileUUID: string;
  privacyUrl?: string;
  agreementUrl?: string;
}

export interface UserAttributeGroupEditData {
  allUserAttributeGroups: UserAttributeGroup[];
  userAttributeGroup: UserAttributeGroup;
  userAttributes: AnyObject<UserAttribute>;
  i18n?: AssignmentDialogTypes.AssignmentDialogTranslations;
}

export interface AdminUserFieldsByGroupsData {
  attributeMap: AnyObject<UserAttribute>;
  groups: UserAttributeGroup[];
}

export class UserAttributeHelper {

  public static fromControllingName(field: string | ColumnName | null): string | null {
    if ( !field ) {
      return null;
    }

    switch (field) {
      case ColumnName.accountId:
        return 'accountId';
      case ColumnName.userId:
        return 'userId';
      case 'uuid':
      case ColumnName.userUuid:
        return 'uuid';
      case 'active':
      case ColumnName.userActive:
        return 'active';
      case 'creationDate':
      case ColumnName.userCreationDate:
        return 'creationDate';
      case 'activationDate':
      case ColumnName.userActivationDate:
        return 'activationDate';
      case 'deactivationDate':
      case ColumnName.userDeactivationDate:
        return 'deactivationDate';
      case 'withActivationEvent':
        return null;

      case 'login':
      case ColumnName.userLogin:
        return 'login';
      case 'password':
        return null;
      case 'language':
      case ColumnName.userLanguage:
        return 'language';

      case 'area':
      case ColumnName.userArea:
        return 'area';
      case 'company':
      case ColumnName.userCompany:
        return 'company';
      case 'costCenter':
      case ColumnName.userCostCenter:
        return 'costCenter';
      case 'costcenterDesc':
      case ColumnName.userCostcenterDesc:
        return 'costcenterDesc';
      case 'dateOfBirth':
      case ColumnName.userDateOfBirth:
        return 'dateOfBirth';
      case 'email':
      case ColumnName.userEmail:
        return 'email';
      case 'employeeID':
      case ColumnName.userEmployeeID:
        return 'employeeID';
      case 'extID':
      case ColumnName.userExtID:
        return 'extID';
      case 'firstname':
      case ColumnName.userFirstname:
        return 'firstname';
      case 'freeTextAttribute1':
      case ColumnName.userFreeTextAttribute1:
        return 'freeTextAttribute1';
      case 'freeTextAttribute2':
      case ColumnName.userFreeTextAttribute2:
        return 'freeTextAttribute2';
      case 'freeTextAttribute3':
      case ColumnName.userFreeTextAttribute3:
        return 'freeTextAttribute3';
      case 'freeTextAttribute4':
      case ColumnName.userFreeTextAttribute4:
        return 'freeTextAttribute4';
      case 'freeTextAttribute5':
      case ColumnName.userFreeTextAttribute5:
        return 'freeTextAttribute5';
      case 'freeTextAttribute6':
      case ColumnName.userFreeTextAttribute6:
        return 'freeTextAttribute6';
      case 'freeTextAttribute7':
      case ColumnName.userFreeTextAttribute7:
        return 'freeTextAttribute7';
      case 'geID':
      case ColumnName.userGeID:
        return 'geID';
      case 'hireDate':
      case ColumnName.userHireDate:
        return 'hireDate';
      case 'hostID':
      case ColumnName.userHostID:
        return 'hostID';
      case 'jobCode':
      case ColumnName.userJobCode:
        return 'jobCode';
      case 'lastname':
      case ColumnName.userLastname:
        return 'lastname';
      case 'location':
      case ColumnName.userLocation:
        return 'location';
      case 'locationDesc':
      case ColumnName.userLocationDesc:
        return 'locationDesc';
      case 'sex':
      case 'gender':
      case ColumnName.userSex:
        return 'sex';
      case 'soeID':
      case ColumnName.userSoeID:
        return 'soeID';
      case 'state':
      case ColumnName.userState:
        return 'state';
      case 'substitute2ID':
      case ColumnName.userSubstitute2ID:
        return 'substitute2ID';
      case 'substituteID':
      case ColumnName.userSubstituteID:
        return 'substituteID';
      case 'superiorID':
      case ColumnName.userSuperiorID:
        return 'superiorID';
    }
  }

  public static toControllingName(field: string | null): ColumnName | null {
    if ( !field ) {
      return null;
    }

    switch (field) {
      case 'accountId':
        return ColumnName.accountId;
      case 'userId':
        return ColumnName.userId;
      case 'uuid':
      case 'userUuid':
        return ColumnName.userUuid;
      case 'active':
      case 'userActive':
        return ColumnName.userActive;
      case 'creationDate':
      case 'userCreationDate':
        return ColumnName.userCreationDate;
      case 'activationDate':
      case 'userActivationDate':
        return ColumnName.userActivationDate;
      case 'deactivationDate':
      case 'userDeactivationDate':
        return ColumnName.userDeactivationDate;
      case 'withActivationEvent':
        return null;

      case 'login':
      case 'userLogin':
        return ColumnName.userLogin;
      case 'password':
        return null;
      case 'language':
      case 'userLanguage':
        return ColumnName.userLanguage;

      case 'area':
      case 'userArea':
        return ColumnName.userArea;
      case 'company':
      case 'userCompany':
        return ColumnName.userCompany;
      case 'costCenter':
      case 'userCostCenter':
        return ColumnName.userCostCenter;
      case 'costcenterDesc':
      case 'userCostcenterDesc':
        return ColumnName.userCostcenterDesc;
      case 'dateOfBirth':
      case 'userDateOfBirth':
        return ColumnName.userDateOfBirth;
      case 'email':
      case 'userEmail':
        return ColumnName.userEmail;
      case 'employeeID':
      case 'userEmployeeID':
        return ColumnName.userEmployeeID;
      case 'extID':
      case 'userExtID':
        return ColumnName.userExtID;
      case 'firstname':
      case 'userFirstname':
        return ColumnName.userFirstname;
      case 'freeTextAttribute1':
      case 'userFreeTextAttribute1':
        return ColumnName.userFreeTextAttribute1;
      case 'freeTextAttribute2':
      case 'userFreeTextAttribute2':
        return ColumnName.userFreeTextAttribute2;
      case 'freeTextAttribute3':
      case 'userFreeTextAttribute3':
        return ColumnName.userFreeTextAttribute3;
      case 'freeTextAttribute4':
      case 'userFreeTextAttribute4':
        return ColumnName.userFreeTextAttribute4;
      case 'freeTextAttribute5':
      case 'userFreeTextAttribute5':
        return ColumnName.userFreeTextAttribute5;
      case 'freeTextAttribute6':
      case 'userFreeTextAttribute6':
        return ColumnName.userFreeTextAttribute6;
      case 'freeTextAttribute7':
      case 'userFreeTextAttribute7':
        return ColumnName.userFreeTextAttribute7;
      case 'geID':
      case 'userGeID':
        return ColumnName.userGeID;
      case 'hireDate':
      case 'userHireDate':
        return ColumnName.userHireDate;
      case 'hostID':
      case 'userHostID':
        return ColumnName.userHostID;
      case 'jobCode':
      case 'userJobCode':
        return ColumnName.userJobCode;
      case 'lastname':
      case 'userLastname':
        return ColumnName.userLastname;
      case 'location':
      case 'userLocation':
        return ColumnName.userLocation;
      case 'locationDesc':
      case 'userLocationDesc':
        return ColumnName.userLocationDesc;
      case 'sex':
      case 'gender':
      case 'userSex':
        return ColumnName.userSex;
      case 'soeID':
      case 'userSoeID':
        return ColumnName.userSoeID;
      case 'state':
      case 'userState':
        return ColumnName.userState;
      case 'substitute2ID':
      case 'userSubstitute2ID':
        return ColumnName.userSubstitute2ID;
      case 'substituteID':
      case 'userSubstituteID':
        return ColumnName.userSubstituteID;
      case 'superiorID':
      case 'userSuperiorID':
        return ColumnName.userSuperiorID;
    }
  }

}
