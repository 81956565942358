import { Component, OnDestroy, OnInit } from '@angular/core';
import { map, switchMap, take } from 'rxjs/operators';
import { ActivatedRoute, Router } from '@angular/router';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { PostLoginTypes } from './post-login.types';
import { StepperSelectionEvent } from '@angular/cdk/stepper';
import { LandingPageEnum } from 'src/app/core/global.types';
import { PostLoginService } from '../../../core/principal/post-login.service';
import { RedirectHelper } from '../../../core/redirect.helper';
import { Observable, of } from 'rxjs';
import { PrincipalService } from '../../../core/principal/principal.service';


@Component({
  selector: 'rag-post-login',
  templateUrl: './post-login.component.html',
  styleUrls: [ './post-login.component.scss' ],
})
export class PostLoginComponent
  implements OnInit, OnDestroy {

  postLoginData: PostLoginTypes.PostLoginRouteData;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private principalService: PrincipalService,
  ) {
    this.route.data
      .pipe(takeUntilDestroyed(this))
      .pipe(map(data => {
        this.postLoginData = data?.postLogin ?? {};
    }))
    .subscribe();
  }

  ngOnInit() {
    // not the best solution
    if (!this.postLoginData.hasToResetPassword &&
      this.postLoginData.recordFields?.length === 0 &&
      this.postLoginData.forwardToPages?.length > 0) {
      this.forward();
    }
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

  hasForwardPageActions(): boolean {
    return this.postLoginData.forwardToPages?.length > 0;
  }

  onDoneClicked(): void {
    this.forward();
  }

  onSelectionChange($event: StepperSelectionEvent) {
    if ( $event.selectedStep.ariaLabel === 'forwarding' ) {
      this.forward();
    }
  }

  private forward(): void {
    PostLoginService.clearPostLoginAction()
      .pipe(switchMap(() => this.getRedirectUrl()))
      .pipe(switchMap(url => this.router.navigateByUrl(url, { replaceUrl: true })))
      .pipe(take(1))
      .subscribe();
  }

  private getRedirectUrl(): Observable<string> {

    const redirectUrl = this.route.snapshot.queryParamMap.get('url');
    if ( redirectUrl != null ) {
      console?.log('redirecting to url parameter', redirectUrl);
      return of(redirectUrl);
    }

    const forwardToPage = this.postLoginData.forwardToPages?.[0];
    switch ( forwardToPage ) {

      case LandingPageEnum.myContents:
        console?.log('redirecting to /content-overview');
        return of('/content-overview');
      case LandingPageEnum.catalog:
        console?.log('redirecting to /catalog');
        return of('/catalog');
      case LandingPageEnum.dashboard:
        return RedirectHelper.getRedirect()
          .pipe(switchMap(url => {
            if (!url) {
              console?.log('redirecting to dashboard (/home)');
              return of('/home');
            }
            if (url === '/catalog') {
              console?.warn('redirecting to dashboard (/home) and clearing stored forward to /catalog');
              return RedirectHelper.clearRedirect()
                .pipe(map(() => '/home'));
            }
            console?.log('preserving stored redirect to', url);
            return of(url);
          }));
      case LandingPageEnum.medical:
        return this.principalService.permissionStates$
          .pipe(take(1))
          .pipe(map(permissions => {
            if ( permissions.hideForILearn && !permissions.ctrlTrainerMedicalExamination ) {
              console?.log('redirecting to /medical');
              return '/medical';
            }
            console?.warn(
              'redirecting to /home for invalid landing page - missing permissions (offbaf)', forwardToPage);
            return '/home';
          }));
      default:
        console?.warn('redirecting to /home for invalid landing page', forwardToPage);
        return of('/home');
    }
  }

}
