import { HttpHeaders } from '@angular/common/http';
import { AnyObject, TransientView } from './core.types';

export const HttpRequestOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};

export type HeaderComponentMode = 'save' | 'add';

export type LmsError = string | AnyObject<string>;

export interface TrainResponse<T = any> {
  data?: T;
  error?: LmsError;
  information?: string;
  success: boolean;
}

export interface ApiResponse<T> {
  readonly errors?: any;
  readonly success: any | boolean;

  [key: string]: T;
}

export interface TrainError {
  detailErrorCode?: string;
  errorMessage?: string;
  objectKey?: string;
  bjectType?: string;
}

export interface ApiError {
  errorCode: string;
  messageKey: string;
  type: string;
  details?: TrainError;
}

export interface TrainWidgetResponse {
  data?: Array<any>;
}

export type ExternalSystemType = 'adobe_connect' | 'any';

export interface ExternalLoginView {
  session: string;
}

export type ExtLoginAddDataType = 'session';

export interface ExternalLoginAddData {
  dataType: ExtLoginAddDataType;
  id: number;
  value: string;
}

export interface ExternalLogin
  extends TransientView<ExternalLoginView> {
  // extData?: Array<ExternalLoginAddData>;
  id?: number;
  login?: string;
  password?: string;
  serverName: string;
  serverType: ExternalSystemType;
  url: string;
  uuid: string;
}

export enum LandingPageEnum {
  myContents = 'myContents',
  dashboard = 'dashboard',
  catalog = 'catalog',
  medical = 'medical',
  unknown = 'unknown'
}

export enum OwnershipKind {
  NONE = 'NONE',
  ASSIGNED = 'ASSIGNED',
  BOOKED = 'BOOKED',
}
