import { Component, TemplateRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountInterceptor } from '../../../../core/interceptors/account.interceptor';
import { ContactFormComponent } from '../contact-form/contact-form.component';
import { ContactStaticComponent } from '../contact-static/contact-static.component';
import { PrincipalService } from '../../../../core/principal/principal.service';
import { RouterLink } from '@angular/router';


@Component({
  selector: 'rag-contact-wrapper',
  standalone: true,
  imports: [
    CommonModule,
    ContactFormComponent,
    ContactStaticComponent,
    RouterLink,
  ],
  templateUrl: './contact-wrapper.component.html',
  styleUrls: [ './contact-wrapper.component.scss' ]
})
export class ContactWrapperComponent {

  constructor(
    private principalService: PrincipalService,
  ) {
  }

  getTemplate<T>(
    tplForm: TemplateRef<T>,
    tplStatic: TemplateRef<T>,
    _tplLogin: TemplateRef<T>,
  ): TemplateRef<T> {
    // todo make
    const accountKey = AccountInterceptor.getAccountKey();
    if ( accountKey === 'bwnx4y3du7e3bw2iu58neo721nbctinfqg0' ) {
      /*if ( this.principalService.isLogged ) {*/
        // CLH user is signed in -> show text with contact information
        return tplStatic;
      /*} else {
        // CHL user is _not_ signed in -> require login first
        return tplLogin;
      }*/
    }
    return tplForm;
  }

}
