import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { EMPTY, from, Observable, of } from 'rxjs';
import { catchError, concatMap, last, map, switchMap, takeWhile } from 'rxjs/operators';
import { OfflineContent } from 'src/app/core/admin-offline.types';
import { AdobeConnect } from 'src/app/core/adobe-connect/adobe-connect.types';
import { ApiUrls } from 'src/app/core/api.urls';
import { AccountData } from 'src/app/core/core.types';
import { ApiResponse, ExternalLogin, HttpRequestOptions } from 'src/app/core/global.types';
import { PrincipalService } from 'src/app/core/principal/principal.service';
import { ProfileService } from 'src/app/core/profile.service';
import { InfoService } from './info/info.service';
import { InfoType, MessageKey } from './info/info.types';
import { ModalDialog } from './modal-dialog';
import { ObjectUsageTypes } from './object-usage.types';
import { UsageDialogComponent, UsageDialogData } from '../route/admin/admin-offline/components/usage-dialog/usage-dialog.component';
import { PrincipalData } from './principal/principal.types';

@Injectable({
  providedIn: 'root',
})
export class ExtLoginsService {

  constructor(
    private dialog: ModalDialog,
    private http: HttpClient,
    private infoService: InfoService,
    private principalService: PrincipalService,
    private profileService: ProfileService
  ) {
  }

  deleteExtLogin(extLoginUUID: string): Observable<boolean> {
    const url = ApiUrls.getKey('ExtLogin') + '/' + extLoginUUID;
    return this.http.delete<ApiResponse<boolean>>(url).pipe(map(response => response.success === true));
  }

  fetchLogins(): Observable<ExternalLogin[]> {
    const url = ApiUrls.getKey('ExtLogin');
    return this.http.get<ApiResponse<Array<ExternalLogin>>>(url).pipe(map(response => response.extLogins));
  }

  // getExtLoginData(extLogin: ExternalLogin, extDataType: ExtLoginAddDataType): string | null {
  //   return extLogin.extData?.find(data => data.dataType === extDataType)?.value;
  // }

  handleUpdateError = (response): Observable<never> => {
    const errors = response?.error?.errors || [];
    if ( errors.findIndex(e => e.errorCode === 'ERR_AC_LOGIN_FAILED') > -1 ) {
      this.infoService.showSnackbar(null, InfoType.Error, {
        message: $localize`:@@ext_login_error_login_failed:
          Login failed! Please check your login credentials and try again`,
      });
    } else if ( errors.findIndex(e => e.errorCode === 'EXT_LOGIN_EXISTS') > -1 ) {
      this.infoService.showSnackbar(MessageKey.EXT_LOGIN.SAVE.ERROR.EXISTS, InfoType.Error);
    } else {
      this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error);
    }
    return EMPTY;
  };

  myMeetings(externalLogin: ExternalLogin) {
    const url = `${ApiUrls.getKey('ExtLogin')}/${externalLogin.uuid}/my-meetings`;
    return this.http.get<ApiResponse<any>>(url).pipe(map(response => response.myMeetings.meetings));
  }

  saveExtLogin(extLogin: any): Observable<ExternalLogin> {
    const url = ApiUrls.getKey('ExtLogin');
    return this.http.post<ApiResponse<ExternalLogin>>(
      url, JSON.stringify(extLogin), HttpRequestOptions).pipe(map(response => response.extLogin));
  }

  saveMeeting(extLogin: ExternalLogin, room: AdobeConnect.Meeting): Observable<AdobeConnect.Sco> {
    const apiUrl = `${ApiUrls.getKey('ExtLogin')}/${extLogin.uuid}/my-meetings`;

    const payload = { ...extLogin, meeting: room };

    return this.http
      .post<ApiResponse<AdobeConnect.Sco>>(apiUrl, JSON.stringify(payload), HttpRequestOptions)
      .pipe(map(response => response.sco));
  }

  // setExtLoginData(extLogin: ExternalLogin, extDataType: ExtLoginAddDataType, value: string) {
  //   let extData = extLogin.extData;
  //   if ( extData == null ) {
  //     extData = [ {
  //       id: 0,
  //       dataType: extDataType,
  //       value: value,
  //     } ];
  //     extLogin.extData = extData;
  //     return;
  //   }
  //   let targetExtData = extData.find(data => data.dataType === extDataType);
  //   if ( targetExtData != null ) {
  //     targetExtData.value = value;
  //     return;
  //   }
  //   targetExtData = {
  //     id: 0,
  //     dataType: extDataType,
  //     value: value,
  //   };
  //   extData.push(targetExtData);
  // }

  // unsetExtLoginData(externalLogin: ExternalLogin, extDataType: ExtLoginAddDataType) {
  //   const extData = externalLogin.extData;
  //   if ( extData == null ) {
  //     return;
  //   }
  //   externalLogin.extData = extData.filter(_extData => _extData.dataType !== extDataType);
  // }

  urlForExtLogin(extLogin: ExternalLogin, path?: string, user?: PrincipalData): Observable<string> {
    if ( extLogin ) {
      if ( extLogin.serverType === 'adobe_connect' ) {
        if ( path ) {
          return of(`${extLogin.url}${path}`);
        }
        return of(null);
      }
      let url = extLogin.url;
      let _userData: AccountData;

      const matches = extLogin.url.match(/\{[^\}]+\}/g);
      if ( matches?.length > 0 ) {

        const _user = user ?? this.principalService.currentUser;
        const getValue = (userData: AccountData, macro: string): string => {
          const fieldName = macro.substr(1, macro.length - 2);
          const value = this.profileService.getValue(fieldName, userData);
          url = url.replace(macro, value);
          return url;
        };

        return from(matches)
          .pipe(concatMap(macro => {
            switch ( macro.toLocaleLowerCase() ) {
              case '{firstname}':
                url = url.replace(macro, _user.firstname);
                return of(url);
              case '{lastname}':
                url = url.replace(macro, _user.lastname);
                return of(url);
              default:
                if (_userData !== undefined) {
                  return of(getValue(_userData, macro));
                }
                return this.profileService.getUserData().pipe(map(userData => {
                  _userData = userData;
                  return getValue(_userData, macro);
                }));
          }
        })).pipe(last());
      }
      return of(url);
    }
    return of(null);
  }

  usage(uuid: string): Observable<ObjectUsageTypes.Usage[]> {
    if ( !uuid ) {
      return EMPTY;
    }
    const url = ApiUrls.getKey('ExtLogin') + '/' + uuid + '/v2/usage';
    return this.http.get<ApiResponse<ObjectUsageTypes.Usage[]>>(url)
      .pipe(catchError(() => {
        this.infoService.showSnackbar(MessageKey.GENERAL_ERROR, InfoType.Error);
        return EMPTY;
      }))
      .pipe(map(response => response.usages));
  }

  usageConfirm(uuid: string, action: 'edit' | 'remove', ignoreObjectKey?: string | number): Observable<number> {
    return this.usage(uuid)
      .pipe(map(usages => {
        usages = ((usages || []));
        if ( ignoreObjectKey == null ) {
          return usages;
        }
        // ignore current extLogin
        return usages.filter(usage => !((usage.objectId === ignoreObjectKey) || (usage.objectUuid === ignoreObjectKey)));
      }))
      // warn before editing
      .pipe(switchMap(usages => {
        if ( usages.length > 0 ) {
          return this.dialog.open<any, UsageDialogData, boolean>(UsageDialogComponent, {
            data: { usages, action, canDeleteWithInactive: true },
          }).afterClosed()
            .pipe(takeWhile(confirmed => !!confirmed))
            .pipe(map(() => usages.length));
        } else {
          return of(0);
        }
      }));
  }

  isEnterRoomAvailable(eventSchedule: OfflineContent.EventSchedule) {
    const now = moment();

    let begin = eventSchedule.$view?.startDate || moment(eventSchedule.eventDate);
    let end = eventSchedule.$view?.endDate || moment(eventSchedule.eventDateUntil);

    begin = begin.add(-eventSchedule.extLoginMinsPrefix, 'minutes');
    end = end.add(eventSchedule.extLoginMinsSuffix, 'minutes');

    return now.isBetween(begin, end);
  }

  urlAsGuest(extLogin: ExternalLogin, extLoginPath?: string, user?: PrincipalData): Observable<string> {
    return this.urlForExtLogin(extLogin, extLoginPath).pipe(map(url => {
      if (extLogin.serverType === 'adobe_connect') {
        const _user = user ?? this.principalService.currentUser;
        const name = `${encodeURIComponent(_user.firstname)}%20${encodeURIComponent(_user.lastname)}`;
        return `${url}?guestname=${name}&launcher=false`;
      }
      return url;
    }));
  }

  test(extLoginUUID: string) {
    const url = `${ApiUrls.getKey('ExtLogin')}/${extLoginUUID}/test`;
    return this.http.get<ApiResponse<boolean>>(url).pipe(map(response => response.success === 'success'));
  }

}
