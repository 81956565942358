export enum FileAuthorizationRefType {
  accountimgfile,
  accountstylefile,
  article_attachment,
  base_news_attachment,
  catalogueentry_pic,
  certtemplate,
  contribution,
  course_attachment,
  course_pic,
  curriculum_attachment,
  curriculum_pic,
  dist_category_pic,
  doclib,
  elea_category_pic,
  f2fcontent_attachment,
  f2fcontent_pic,
  f2fevent_attachment,
  f2flocation_attachment,
  language_bpic,
  language_spic,
  message_attachment,
  news_mpic,
  news_spic,
  news_xlpic,
  report,
  user_pic,
  user_import,
  usergroup_pic,
  personalFile,
  notification_attachment,
  selfreg_attachment,
  tbd,
  trainer_pic,
  badge_template_pic,
}

export function FileAuthorizationRefTypeFactory(value: string | number | null): FileAuthorizationRefType {
  if(value == null) return null;
  switch (value){
    case 'accountimgfile':
    case FileAuthorizationRefType.accountimgfile:
      return FileAuthorizationRefType.accountimgfile;
    case 'accountstylefile':
    case FileAuthorizationRefType.accountstylefile:
      return FileAuthorizationRefType.accountstylefile;
    case 'article_attachment':
    case FileAuthorizationRefType.article_attachment:
      return FileAuthorizationRefType.article_attachment;
    case 'base_news_attachment':
    case FileAuthorizationRefType.base_news_attachment:
      return FileAuthorizationRefType.base_news_attachment;
    case 'catalogueentry_pic':
    case FileAuthorizationRefType.catalogueentry_pic:
      return FileAuthorizationRefType.catalogueentry_pic;
    case 'certtemplate':
    case FileAuthorizationRefType.certtemplate:
      return FileAuthorizationRefType.certtemplate;
    case 'contribution':
    case FileAuthorizationRefType.contribution:
      return FileAuthorizationRefType.contribution;
    case 'course_attachment':
    case FileAuthorizationRefType.course_attachment:
      return FileAuthorizationRefType.course_attachment;
    case 'course_pic':
    case FileAuthorizationRefType.course_pic:
      return FileAuthorizationRefType.course_pic;
    case 'curriculum_attachment':
    case FileAuthorizationRefType.curriculum_attachment:
      return FileAuthorizationRefType.curriculum_attachment;
    case 'curriculum_pic':
    case FileAuthorizationRefType.curriculum_pic:
      return FileAuthorizationRefType.curriculum_pic;
    case 'dist_category_pic':
    case FileAuthorizationRefType.dist_category_pic:
      return FileAuthorizationRefType.dist_category_pic;
    case 'doclib':
    case FileAuthorizationRefType.doclib:
      return FileAuthorizationRefType.doclib;
    case 'elea_category_pic':
    case FileAuthorizationRefType.elea_category_pic:
      return FileAuthorizationRefType.elea_category_pic;
    case 'f2fcontent_attachment':
    case FileAuthorizationRefType.f2fcontent_attachment:
      return FileAuthorizationRefType.f2fcontent_attachment;
    case 'f2fcontent_pic':
    case FileAuthorizationRefType.f2fcontent_pic:
      return FileAuthorizationRefType.f2fcontent_pic;
    case 'f2fevent_attachment':
    case FileAuthorizationRefType.f2fevent_attachment:
      return FileAuthorizationRefType.f2fevent_attachment;
    case 'f2flocation_attachment':
    case FileAuthorizationRefType.f2flocation_attachment:
      return FileAuthorizationRefType.f2flocation_attachment;
    case 'language_bpic':
    case FileAuthorizationRefType.language_bpic:
      return FileAuthorizationRefType.language_bpic;
    case 'language_spic':
    case FileAuthorizationRefType.language_spic:
      return FileAuthorizationRefType.language_spic;
    case 'message_attachment':
    case FileAuthorizationRefType.message_attachment:
      return FileAuthorizationRefType.message_attachment;
    case 'news_mpic':
    case FileAuthorizationRefType.news_mpic:
      return FileAuthorizationRefType.news_mpic;
    case 'news_spic':
    case FileAuthorizationRefType.news_spic:
      return FileAuthorizationRefType.news_spic;
    case 'news_xlpic':
    case FileAuthorizationRefType.news_xlpic:
      return FileAuthorizationRefType.news_xlpic;
    case 'report':
    case FileAuthorizationRefType.report:
      return FileAuthorizationRefType.report;
    case 'user_pic':
    case FileAuthorizationRefType.user_pic:
      return FileAuthorizationRefType.user_pic;
    case 'user_import':
    case FileAuthorizationRefType.user_import:
      return FileAuthorizationRefType.user_import;
    case 'usergroup_pic':
    case FileAuthorizationRefType.usergroup_pic:
      return FileAuthorizationRefType.usergroup_pic;
    case 'personalFile':
    case FileAuthorizationRefType.personalFile:
      return FileAuthorizationRefType.personalFile;
    case 'notification_attachment':
    case FileAuthorizationRefType.notification_attachment:
      return FileAuthorizationRefType.notification_attachment;
    case 'selfreg_attachment':
    case FileAuthorizationRefType.selfreg_attachment:
      return FileAuthorizationRefType.selfreg_attachment;
    case 'tbd':
    case FileAuthorizationRefType.tbd:
      return FileAuthorizationRefType.tbd;
    case 'trainer_pic':
    case FileAuthorizationRefType.trainer_pic:
      return FileAuthorizationRefType.trainer_pic;
    case 'badge_template_pic':
    case FileAuthorizationRefType.badge_template_pic:
      return FileAuthorizationRefType.badge_template_pic;
  }


  return null;
}

export enum FileAuthorizationType {
  unchecked,
  login_required,
  permission_required,
  personal,
  forbidden,
}
