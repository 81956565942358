<rag-page-header>
  <div class="page-header-title">
    <span i18n="@@global_doclib_link">Media library file</span>
    <span *ngIf="fileName">{{ ' - ' + fileName }}</span>
  </div>
</rag-page-header>

<ng-container *ngIf="valid && canBeShownInIframe">
  <div class="frame-wrapper">
    <iframe *ngIf="iframeUrl != null" [src]="iframeUrl | safe:'resourceUrl'" allowfullscreen
            frameborder="no"></iframe>

  </div>
</ng-container>

<div class="no-data" *ngIf="valid && !canBeShownInIframe">
  <span i18n="@@doclib-please-download">The file cannot be shown directly - <a
    [href]="downloadUrl">please download</a></span>
</div>

<div class="no-data" *ngIf="!valid">
  <span class="error-message" i18n="@@doclib-invalid-doclib-file">
    The requested file can not be displayed for document library!
  </span>
</div>
