import { Component, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { tap } from 'rxjs/operators';
import { destroySubscriptions, takeUntilDestroyed } from '../../../core/reactive/until-destroyed';
import { PageHeaderComponent } from '../../../component/page-header/page-header.component';
import { CommonModule } from '@angular/common';
import { CoreModule } from '../../../core/core.module';
import { FilesHelper } from '../../../component/file/files-helper';
import { FileInfo } from '../../../core/core.types';
import { FileAuthorizationRefType, FileAuthorizationRefTypeFactory } from '../../../core/files/file-upload.types';

@Component({
  selector: 'rag-media-library-show-file',
  templateUrl: './media-library-show-file.component.html',
  styleUrls: ['./media-library-show-file.component.scss'],
  imports: [
    PageHeaderComponent,
    CommonModule,
    CoreModule,
  ],
  standalone: true
})
export class MediaLibraryShowFileComponent
  implements OnDestroy {

  private fileInfo: FileInfo;
  protected valid: boolean;
  protected canBeShownInIframe: boolean;
  protected iframeUrl: string;
  protected downloadUrl: string;
  protected fileName: string;

  constructor(
    private route: ActivatedRoute,
  ) {
    this.route.data
      .pipe(tap(data => this.updateRouteData(data?.fileInfo)))
      .pipe(takeUntilDestroyed(this))
      .subscribe();
  }

  private updateRouteData(fileInfo: FileInfo) {
    this.valid = FileAuthorizationRefTypeFactory(fileInfo?.authRefType) === FileAuthorizationRefType.doclib;
    this.fileInfo = fileInfo;
    this.canBeShownInIframe = this.valid && (fileInfo?.fileType === 'application/pdf' || fileInfo?.fileType?.startsWith('image/'));
    this.iframeUrl = FilesHelper.getIframeUrl(this.fileInfo?.uuid);
    this.fileName = this.getFileName(this.fileInfo);
    this.downloadUrl = FilesHelper.getDownloadUrl(this.fileInfo?.uuid, this.fileName);
  }

  getFileName(fileInfo: FileInfo): string {
    let fileName = fileInfo?.fileName;
    if (fileName === null || fileName === undefined || fileName === '') {
      console?.warn('File name is empty, using uuid as file name');
      fileName = fileInfo?.uuid;
    }
    return fileName;
  }

  ngOnDestroy(): void {
    destroySubscriptions(this);
  }

}
