import { ActivatedRouteSnapshot, ResolveFn, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { catchError, map, take } from 'rxjs/operators';
import { inject } from '@angular/core';
import { FilesHelper } from '../../../component/file/files-helper';
import { Observable, of } from 'rxjs';
import { FileInfo } from '../../../core/core.types';
import { ApiResponse } from '../../../core/global.types';


export const MediaLibraryResolverService: ResolveFn<Observable<FileInfo>> = (
  route: ActivatedRouteSnapshot,
  _state: RouterStateSnapshot,
  http: HttpClient = inject(HttpClient)
): Observable<FileInfo> => {
  return http.get<ApiResponse<FileInfo>>(FilesHelper.getFileInfoUrl(route.params.uuid))
    .pipe(map(response => response.fileInfo))
    .pipe(catchError(() => of(null)))
    .pipe(take(1))
    ;
};
