<rag-post-to-blank
  #postToBlank
></rag-post-to-blank>

<mat-menu #toolbarMenu="matMenu">
  <ng-template matMenuContent>

    <ng-container *ngIf="!isNothing(toolbarMenuCustomTemplate)">
      <ng-container *ngTemplateOutlet="toolbarMenuCustomTemplate; context: {selection: selection}"></ng-container>
    </ng-container>

    <button
      type="button"
      *ngIf="hasMessagePermission"
      [matMenuTriggerFor]="notificationMenu"
      mat-menu-item
    >
      <mat-icon svgIcon="email-edit-outline"></mat-icon>
      <span i18n="@@admin_offline_event_participants_notifications">Notifications</span>
    </button>

    <button
      type="button"
      *ngIf="hasStatusPermission"
      [matMenuTriggerFor]="invitationStatusMenu"
      [disabled]="blockEvent"
      mat-menu-item
    >
      <mat-icon svgIcon="account-question-outline"></mat-icon>
      <span i18n="@@global_invitation_status">Invitation status</span>
    </button>

    <button
      type="button"
      *ngIf="hasAssignPermission"
      [disabled]="inputDisabled"
      [matMenuTriggerFor]="participationRequestMenu"
      [disabled]="blockEvent"
      mat-menu-item
    >
      <mat-icon svgIcon="account-heart-outline"></mat-icon>
      <span i18n="@@admin_offline_event_participants_handle_request">Participation request</span>
    </button>

    <button
      type="button"
      *ngIf="hasStatusPermission"
      [disabled]="disableStatusActions || !eventInPast"
      [matMenuTriggerFor]="participationStatusMenu"
      mat-menu-item
    >
      <mat-icon svgIcon="account-clock-outline"></mat-icon>
      <span i18n="@@admin_offline_event_participants_set_participation">Participation status</span>
    </button>

    <button
      type="button"
      *ngIf="hasStatusPermission && hasExamination"
      [disabled]="disableStatusActions || !eventInPast || blockEvent"
      [matMenuTriggerFor]="examinationStatusMenu"
      mat-menu-item
    >
      <mat-icon svgIcon="account-star-outline"></mat-icon>
      <span i18n="@@admin_offline_event_participants_examination_status">Examination result</span>
    </button>

    <button
      type="button"
      *ngIf="hasStatusPermission && hasRegistrationConfirmationTemplate"
      [matMenuTriggerFor]="registrationStatusMenu"
      [disabled]="blockEvent"
      mat-menu-item
    >
      <mat-icon svgIcon="send-outline"></mat-icon>
      <span i18n="@@admin_offline_event_participants_registration_status">Registration status</span>
    </button>

    <button
      type="button"
      mat-menu-item
      [matMenuTriggerFor]="matMenuDownload"
    >
      <mat-icon svgIcon="cloud-download-outline"></mat-icon>
      <span i18n="@@admin_offline_event_participants_export">Export participants</span>

      <mat-menu #matMenuDownload>

        <button
          type="button"
          (click)="onDownloadParticipantListPdf(postToBlank)"
          mat-menu-item
        >
          <mat-icon svgIcon="file-pdf-box"></mat-icon>
          <span i18n="@@admin_offline_event_participants_download_pdf">Participant PDF</span>
        </button>

        <button
          type="button"
          (click)="onDownloadParticipantListXls()"
          mat-menu-item
        >
          <mat-icon svgIcon="file-excel-outline"></mat-icon>
          <span i18n="@@admin_offline_event_participants_download_xls">Participant XLS</span>
        </button>

      </mat-menu>
    </button>

    <button
      type="button"
      (click)="onDownloadRegistrationConfirmation(postToBlank)"
      *ngIf="hasRegistrationConfirmationTemplate"
      mat-menu-item
    >
      <mat-icon svgIcon="file-pdf-box"></mat-icon>
      <span i18n="@@admin_offline_event_participants_download_registration_confirmation">
        Registration confirmation PDF
      </span>
    </button>

    <div mat-menu-item class="hidden-menu-item" inert="true"></div>
    <rag-select-target-groups-menu
      [participants]="this.selection.selected"
      [showButtonNew]="true"
      xPosition="before"
      (click)="$event.stopImmediatePropagation()"
    ></rag-select-target-groups-menu>
    <div mat-menu-item class="hidden-menu-item" inert="true"></div>

  </ng-template>
</mat-menu>

<mat-menu #registrationStatusMenu="matMenu">
  <ng-template  let-config="config" let-participant="participant" matMenuContent>

    <ng-container *ngIf="!isNothing(registrationStatusMenuCustomTemplate)">
      <ng-container *ngTemplateOutlet="registrationStatusMenuCustomTemplate; context: {participant: participant}"></ng-container>
    </ng-container>

    <button
      type="button"
      (click)="onSetRegistrationStatus('NOT_GENERATED', participant)"
      mat-menu-item
    >
      <mat-icon
        svgIcon="checkbox-blank-outline"
      ></mat-icon>
      <span i18n="@@admin_offline_event_participants_registration_not_generated">Not generated</span>
    </button>

    <button
      type="button"
      (click)="onSetRegistrationStatus('CONFIRMATION_GENERATED', participant)"
      mat-menu-item
    >
      <mat-icon
        svgIcon="checkbox-marked-outline"
      ></mat-icon>
      <span i18n="@@admin_offline_event_participants_registration_generated">Generated</span>
    </button>

    <button
      type="button"
      (click)="onSetRegistrationStatus('CONFIRMATION_SENT', participant)"
      mat-menu-item
    >
      <mat-icon
        svgIcon="send-outline"
      ></mat-icon>
      <span i18n="@@admin_offline_event_participants_registration_confirmation_sent">Confirmation sent</span>
    </button>

  </ng-template>
</mat-menu>

<mat-menu #participantActionMenu="matMenu" (closed)="onClosed()">

  <ng-template
    matMenuContent
    let-config="config"
    let-participant="participant"
    let-blockEvent="blockEvent"
    >

    <ng-container *ngIf="!isNothing(participantActionMenuCustomTemplate)">
      <ng-container *ngTemplateOutlet="participantActionMenuCustomTemplate; context: {participant: participant}"></ng-container>
    </ng-container>

    <ng-container
      [ngTemplateOutlet]="!isNothing(offlineContent) ? offlineContentIsSet : offlineCOntentIsNotSet"
      [ngTemplateOutletContext]="{participant: participant, config: config}"
      >
    </ng-container>
  </ng-template>

  <ng-template #offlineCOntentIsNotSet>
    <a mat-menu-item>
      <rag-loading-indicator></rag-loading-indicator>
    </a>
  </ng-template>

  <ng-template
    #offlineContentIsSet
    let-config="config"
    let-participant="participant">

    <button
      type="button"
      *ngIf="hasMessagePermission"
      [disabled]="!participant.userActive"
      [matMenuTriggerData]="{participant: participant, config: config}"
      [matMenuTriggerFor]="notificationMenu"
      mat-menu-item
    >
      <mat-icon svgIcon="email-edit-outline"></mat-icon>
      <span i18n="@@admin_offline_event_participants_notifications">Notifications</span>
    </button>

    <button
      type="button"
      *ngIf="hasStatusPermission"
      [matMenuTriggerData]="{participant: participant, config: config}"
      [matMenuTriggerFor]="invitationStatusMenu"
      [disabled]="blockEvent || !participant.userActive"
      mat-menu-item
    >
      <mat-icon svgIcon="account-question-outline"></mat-icon>
      <span i18n="@@global_invitation_status">Invitation status</span>
    </button>

    <button
      type="button"
      *ngIf="hasAssignPermission && isParticipationDesired(participant)"
      [disabled]="inputDisabled || blockEvent || !participant.userActive"
      [matMenuTriggerData]="{participant: participant, config: config}"
      [matMenuTriggerFor]="participationRequestMenu"
      mat-menu-item
    >
      <mat-icon svgIcon="account-heart-outline"></mat-icon>
      <span i18n="@@admin_offline_event_participants_handle_request">Participation request</span>
    </button>

    <button
      type="button"
      *ngIf="hasStatusPermission"
      [disabled]="disableStatusActions || !eventInPast || !participant.userActive"
      [matMenuTriggerData]="{participant: participant, config: config}"
      [matMenuTriggerFor]="participationStatusMenu"
      mat-menu-item
    >
      <mat-icon svgIcon="account-clock-outline"></mat-icon>
      <span i18n="@@admin_offline_event_participants_set_participation">Participation status</span>
    </button>

    <button
      type="button"
      *ngIf="hasStatusPermission && hasExamination"
      [disabled]="disableStatusActions || !eventInPast || !participant.userActive"
      [matMenuTriggerData]="{participant: participant, config: config}"
      [matMenuTriggerFor]="examinationStatusMenu"
      mat-menu-item
    >
      <mat-icon svgIcon="account-star-outline"></mat-icon>
      <span i18n="@@admin_offline_event_participants_examination_status">Examination result</span>
    </button>

    <button
      type="button"
      *ngIf="hasStatusPermission && hasRegistrationConfirmationTemplate"
      [matMenuTriggerData]="{participant: participant, config: config}"
      [matMenuTriggerFor]="registrationStatusMenu"
      mat-menu-item
    >
      <mat-icon svgIcon="send-outline"></mat-icon>
      <span i18n="@@admin_offline_event_participants_registration_status">Registration status</span>
    </button>

    <div mat-menu-item class="hidden-menu-item" inert="true"></div>
      <rag-select-target-groups-menu
        [disabled]="!participant.userActive"
        [participants]="[participant]"
        [showButtonNew]="true"
        xPosition="before"
        (click)="$event.stopImmediatePropagation()"
      ></rag-select-target-groups-menu>
    <div mat-menu-item class="hidden-menu-item" inert="true"></div>

    <button
      type="button"
      (click)="onRemoveParticipant(participant)"
      [disabled]="inputDisabled || config['disableRemoveParticipant'] || blockEvent"
      mat-menu-item
    >
      <mat-icon
        color="warn"
        svgIcon="delete-outline"
      ></mat-icon>
      <span i18n="@@global_remove">Remove</span>
    </button>

  </ng-template>
</mat-menu>

<mat-menu #notificationMenu="matMenu">
  <ng-template  let-config="config" let-participant="participant" matMenuContent>

    <button
      type="button"
      (click)="onSendNotification(null, participant)"
      mat-menu-item
    >
      <rag-icon-text
        svgIcon="email-edit-outline"
      >
        <span i18n="@@global_messaging">Send message</span>
      </rag-icon-text>
    </button>

    <button
      type="button"
      (click)="onSendNotification('inv', participant)"
      *ngIf="hasStatusPermission"
      [disabled]="blockEvent"
      mat-menu-item
    >
      <rag-icon-text
        svgIcon="email-outline"
      >
        <span i18n="@@admin_offline_event_participants_send_invitation">Send Invitation</span>
      </rag-icon-text>
    </button>

    <button
      type="button"
      (click)="onSendNotification('rem', participant)"
      *ngIf="hasStatusPermission"
      [disabled]="blockEvent"
      mat-menu-item
    >
      <rag-icon-text
        svgIcon="email-alert-outline"
      >
        <span i18n="@@admin_offline_event_participants_send_reminder">Send Reminder</span>
      </rag-icon-text>
    </button>

    <button
      type="button"
      (click)="onSendNotification('can', participant)"
      *ngIf="hasStatusPermission"
      [disabled]="blockEvent"
      mat-menu-item
    >
      <rag-icon-text
        svgIcon="calendar-remove-outline"
      >
        <span i18n="@@admin_offline_event_participants_send_cancellation">Send event cancelled</span>
      </rag-icon-text>
    </button>

  </ng-template>
</mat-menu>

<mat-menu #invitationStatusMenu="matMenu">
  <ng-template  let-config="config" let-participant="participant" matMenuContent>

    <button
      type="button"
      (click)="onSetInvitationStatus('inv', participant)"
      mat-menu-item
    >
      <mat-icon svgIcon="email-outline"></mat-icon>
      <span i18n="@@admin_offline_event_invitation_status_inv">Invitation sent</span>
    </button>

    <button
      type="button"
      (click)="onSetInvitationStatus('rem', participant)"
      mat-menu-item
    >
      <mat-icon svgIcon="email-alert-outline"></mat-icon>
      <span i18n="@@admin_offline_event_invitation_status_rem">Reminder sent</span>
    </button>

    <button
      type="button"
      (click)="onSetInvitationStatus('can', participant)"
      mat-menu-item
    >
      <mat-icon svgIcon="calendar-remove-outline"></mat-icon>
      <span i18n="@@admin_offline_event_invitation_status_can">Event cancellation sent</span>
    </button>

    <button
      type="button"
      (click)="onSetInvitationStatus('ack', participant)"
      mat-menu-item
    >
      <mat-icon svgIcon="thumb-up-outline"></mat-icon>
      <span i18n="@@admin_offline_event_invitation_status_ack">Participant confirmed</span>
    </button>

    <button
      type="button"
      (click)="onSetInvitationStatus('dec', participant)"
      mat-menu-item
    >
      <mat-icon svgIcon="thumb-down-outline"></mat-icon>
      <span i18n="@@admin_offline_event_invitation_status_dec">Participant declined</span>
    </button>

  </ng-template>
</mat-menu>

<mat-menu #participationRequestMenu="matMenu">
  <ng-template  let-config="config" let-participant="participant" matMenuContent>

    <button
      type="button"
      (click)="onSetParticipationRequest(true, participant)"
      mat-menu-item
    >
      <mat-icon svgIcon="account-check-outline"></mat-icon>
      <span i18n="@@admin_offline_event_participants_handle_request_accept">Accept request</span>
    </button>

    <button
      type="button"
      (click)="onSetParticipationRequest(false, participant)"
      mat-menu-item
    >
      <mat-icon svgIcon="account-cancel-outline"></mat-icon>
      <span i18n="@@admin_offline_event_participants_handle_request_reject">Decline Request</span>
    </button>

  </ng-template>
</mat-menu>

<mat-menu #participationStatusMenu="matMenu">
  <ng-template  let-config="config" let-participant="participant" matMenuContent>

    <button
      type="button"
      (click)="onSetParticipationStatus(true, participant)"
      mat-menu-item
    >
      <mat-icon svgIcon="account-check-outline"></mat-icon>
      <span i18n="@@global_participation_status_attended">Attended</span>
    </button>

    <button
      type="button"
      (click)="onSetParticipationStatus(false, participant)"
      mat-menu-item
    >
      <mat-icon svgIcon="account-cancel-outline"></mat-icon>
      <span i18n="@@admin_offline_event_participants_not_attended">Not attended</span>
    </button>

  </ng-template>
</mat-menu>

<mat-menu #examinationStatusMenu="matMenu">
  <ng-template  let-config="config" let-participant="participant" matMenuContent>

    <button
      type="button"
      (click)="onSetExaminationStatus(true, participant)"
      mat-menu-item
    >
      <ng-container
        [ngTemplateOutlet]="examinationStatusPassed"
      ></ng-container>
    </button>

    <button
      type="button"
      (click)="onSetExaminationStatus(false, participant)"
      mat-menu-item
    >
      <ng-container
        [ngTemplateOutlet]="examinationStatusNotAttended"
      ></ng-container>
    </button>

  </ng-template>
</mat-menu>

<ng-template
  #examinationStatusPassed
>
  <rag-status-lights
    [displayHint]="true"
    [hideToolTip]="true"
    statusType="DisplayStatus"
    displayStatus="3"
  ></rag-status-lights>
</ng-template>

<ng-template
  #examinationStatusNotAttended
>
  <rag-status-lights
    [displayHint]="true"
    [hideToolTip]="true"
    statusType="DisplayStatus"
    displayStatus="4"
  ></rag-status-lights>
</ng-template>
