import { State } from '../app.state';
import { AnyObject, Translateable } from './core.types';
import { LocaleTranslation, Translation } from './translation/translation.types';


export interface LanguageInfo {
  /**
   * allow static sorting
   */
  index: number;
  /**
   * e.g. <code>'de'</code> for german
   */
  key: string;
  /**
   * JSON object like <code>{'de': 'deutscher text', 'en': 'englischer text', 'default': 'fallback oder admin'}</code>
   */
  title: Translation;
  /**
   * Language tag, but with an underscore instead of a dash. e.g. <code>de_DE</code> or <code>en_GB</code>
   */
  trainLanguage: string;
  /**
   * e.g. <code>'de'</code> for germany
   */
  country: string;
  /**
   * Unicode character, used to show the flag in a dropdown for example.
   */
  flag?: string;
}

export class LanguageHelper {

  static hasLanguage(title: Translateable, value: Array<string>): boolean {
    if (typeof title === 'string') {
      return false;
    }
    return value.reduce((pV, language) => pV = pV && (this.objectToText(title, language)?.length > 0), true);
  }

  static newObject(): Translation {
    const _obj: Translation = {};
    this.LANGUAGES.forEach(l => LanguageHelper.setTranslation(_obj, '', l.key));
    return _obj;
  }

  //formats all languages into an object to be used for a dropdown display
  static forDropdown() : AnyObject<string> {
    const obj = {};
    LanguageHelper.WORLDLANGUAGES.forEach(lang => {
      if (lang.key !== "??") {
        obj[lang.trainLanguage] = LanguageHelper.getFlaggedLabel(lang);
      }
    })
    return obj;
  }

  //returns a pretty translated label for a language with a flag.
  static getFlaggedLabel(lang: LanguageInfo) : string {
      const worldLanguage = LanguageHelper.WORLDLANGUAGES
        .find(o => o.trainLanguage === lang.trainLanguage);
    return LanguageHelper.getLabel(worldLanguage) + ' ' + worldLanguage.flag;
  }

  //returns the translated label for the language.
  static getLabel(lang: LanguageInfo) : string {
    return LanguageHelper.objectToText(lang.title);
  }

  /**
   * The LANGUAGES are updated according to the account settings.
   *
   * @see PreloadService.preload
   * @see PreloadData.languages
   */
  public static LANGUAGES: LanguageInfo[] = [
    { index: 0, key: 'de', trainLanguage: 'de_DE', title: { de: 'Deutsch', en: 'German', fr: 'Allemand', pl: 'Niemiecki' }, country: 'de', flag: '🇩🇪' },
    { index: 1, key: 'en', trainLanguage: 'en_GB', title: { de: 'Englisch', en: 'English', fr: 'Ingles', pl: 'Angielski' }, country: 'gb', flag: '🇬🇧' },
    { index: 2, key: 'fr', trainLanguage: 'fr_FR', title: { de: 'Französisch', en: 'French', fr: 'Français', pl: 'Francuski' }, country: 'fr', flag: '🇫🇷' },
    { index: 3, key: 'pl', trainLanguage: 'pl_PL', title: { de: 'Polnisch', en: 'Polish', fr: 'Polonais', pl: 'Polski' }, country: 'pl', flag: '🇵🇱' },
  ];

  public static WORLDLANGUAGES: LanguageInfo[] = [
    { index: -1, key: '??', trainLanguage: '??_??', title: { de: 'Unbekannt', en: 'Unknown', fr: 'Unknown', pl: 'Unknown' }, country: '??', flag: '🇺🇳' },
    { index: 0, key: 'af', trainLanguage: 'af_ZA', title: { de: 'Afrikaans', en: 'Afrikaans', fr: 'Afrikaans', pl: 'Afrikaans' }, country: 'za', flag: '🇿🇦' },
    { index: 1, key: 'sq', trainLanguage: 'sq_AL', title: { de: 'Albanisch', en: 'Albanian', fr: 'Albanais', pl: 'Albański' }, country: 'al', flag: '🇦🇱' },
    { index: 2, key: 'am', trainLanguage: 'am_ET', title: { de: 'Amharisch', en: 'Amharic', fr: 'Amharique', pl: 'Amharski' }, country: 'et', flag: '🇪🇹' },
    { index: 3, key: 'ar', trainLanguage: 'ar_SA', title: { de: 'Arabisch (Saudi-Arabien)', en: 'Arabic (Saudi Arabia)', fr: 'Arabe (Arabie Saoudite)', pl: 'Arabski (Arabia Saudyjska)' }, country: 'sa', flag: '🇸🇦' },
    { index: 4, key: 'hy', trainLanguage: 'hy_AM', title: { de: 'Armenisch', en: 'Armenian', fr: 'Arménien', pl: 'Armeński' }, country: 'am', flag: '🇦🇲' },
    { index: 5, key: 'az', trainLanguage: 'az_AZ', title: { de: 'Aserbaidschanisch', en: 'Azerbaijani', fr: 'Azerbaïdjanais', pl: 'Azerbejdżański' }, country: 'az', flag: '🇦🇿' },
    { index: 6, key: 'eu', trainLanguage: 'eu_ES', title: { de: 'Baskisch', en: 'Basque', fr: 'Basque', pl: 'Baskijski' }, country: 'es', flag: '🇪🇸' },
    { index: 7, key: 'be', trainLanguage: 'be_BY', title: { de: 'Weißrussisch', en: 'Belarusian', fr: 'Biélorusse', pl: 'Białoruski' }, country: 'by', flag: '🇧🇾' },
    { index: 8, key: 'bn', trainLanguage: 'bn_BD', title: { de: 'Bengalisch', en: 'Bengali', fr: 'Bengali', pl: 'Bengalski' }, country: 'bd', flag: '🇧🇩' },
    { index: 9, key: 'bs', trainLanguage: 'bs_BA', title: { de: 'Bosnisch', en: 'Bosnian', fr: 'Bosniaque', pl: 'Bośniacki' }, country: 'ba', flag: '🇧🇦' },
    { index: 10, key: 'bg', trainLanguage: 'bg_BG', title: { de: 'Bulgarisch', en: 'Bulgarian', fr: 'Bulgare', pl: 'Bułgarski' }, country: 'bg', flag: '🇧🇬' },
    { index: 11, key: 'ca', trainLanguage: 'ca_ES', title: { de: 'Katalanisch', en: 'Catalan', fr: 'Catalan', pl: 'Kataloński' }, country: 'es', flag: '🇪🇸' },
    { index: 12, key: 'ceb', trainLanguage: 'ceb_PH', title: { de: 'Cebuano', en: 'Cebuano', fr: 'Cebuano', pl: 'Cebuano' }, country: 'ph', flag: '🇵🇭' },
    { index: 13, key: 'zh', trainLanguage: 'zh_CN', title: { de: 'Chinesisch', en: 'Chinese', fr: 'Chinois', pl: 'Chiński' }, country: 'cn', flag: '🇨🇳' },
    { index: 14, key: 'co', trainLanguage: 'co_FR', title: { de: 'Korsisch', en: 'Corsican', fr: 'Corse', pl: 'Korsykański' }, country: 'fr', flag: '🇫🇷' },
    { index: 15, key: 'hr', trainLanguage: 'hr_HR', title: { de: 'Kroatisch', en: 'Croatian', fr: 'Croate', pl: 'Chorwacki' }, country: 'hr', flag: '🇭🇷' },
    { index: 16, key: 'cs', trainLanguage: 'cs_CZ', title: { de: 'Tschechisch', en: 'Czech', fr: 'Tchèque', pl: 'Czeski' }, country: 'cz', flag: '🇨🇿' },
    { index: 17, key: 'da', trainLanguage: 'da_DK', title: { de: 'Dänisch', en: 'Danish', fr: 'Danois', pl: 'Duński' }, country: 'dk', flag: '🇩🇰' },
    { index: 18, key: 'nl', trainLanguage: 'nl_NL', title: { de: 'Niederländisch', en: 'Dutch', fr: 'Néerlandais', pl: 'Niderlandzki' }, country: 'nl', flag: '🇳🇱' },
    { index: 19, key: 'en', trainLanguage: 'en_GB', title: { de: 'Englisch (GB)', en: 'English (GB)', fr: 'Anglais (GB)', pl: 'Angielski (GB)' }, country: 'gb', flag: '🇬🇧' },
    { index: 20, key: 'eo', trainLanguage: 'eo_??', title: { de: 'Esperanto', en: 'Esperanto', fr: 'Espéranto', pl: 'Esperanto' }, country: '??', flag: '🌐' },
    { index: 21, key: 'et', trainLanguage: 'et_EE', title: { de: 'Estnisch', en: 'Estonian', fr: 'Estonien', pl: 'Estoński' }, country: 'ee', flag: '🇪🇪' },
    { index: 22, key: 'fi', trainLanguage: 'fi_FI', title: { de: 'Finnisch', en: 'Finnish', fr: 'Finnois', pl: 'Fiński' }, country: 'fi', flag: '🇫🇮' },
    { index: 23, key: 'fr', trainLanguage: 'fr_FR', title: { de: 'Französisch', en: 'French', fr: 'Français', pl: 'Francuski' }, country: 'fr', flag: '🇫🇷' },
    { index: 24, key: 'gl', trainLanguage: 'gl_ES', title: { de: 'Galizisch', en: 'Galician', fr: 'Galicien', pl: 'Galicyjski' }, country: 'es', flag: '🇪🇸' },
    { index: 25, key: 'ka', trainLanguage: 'ka_GE', title: { de: 'Georgisch', en: 'Georgian', fr: 'Géorgien', pl: 'Gruziński' }, country: 'ge', flag: '🇬🇪' },
    { index: 26, key: 'de', trainLanguage: 'de_DE', title: { de: 'Deutsch', en: 'German', fr: 'Allemand', pl: 'Niemiecki' }, country: 'de', flag: '🇩🇪' },
    { index: 27, key: 'el', trainLanguage: 'el_GR', title: { de: 'Griechisch', en: 'Greek', fr: 'Grec', pl: 'Grecki' }, country: 'gr', flag: '🇬🇷' },
    { index: 28, key: 'gu', trainLanguage: 'gu_IN', title: { de: 'Gujarati', en: 'Gujarati', fr: 'Gujarati', pl: 'Gudżarati' }, country: 'in', flag: '🇮🇳' },
    { index: 29, key: 'ht', trainLanguage: 'ht_HT', title: { de: 'Haitianisch', en: 'Haitian Creole', fr: 'Créole haïtien', pl: 'Haitański Kreolski' }, country: 'ht', flag: '🇭🇹' },
    { index: 30, key: 'ha', trainLanguage: 'ha_NG', title: { de: 'Haussa', en: 'Hausa', fr: 'Haoussa', pl: 'Hausa' }, country: 'ng', flag: '🇳🇬' },
    { index: 31, key: 'haw', trainLanguage: 'haw_US', title: { de: 'Hawaiisch', en: 'Hawaiian', fr: 'Hawaïen', pl: 'Hawajski' }, country: 'us', flag: '🇺🇸' },
    { index: 32, key: 'he', trainLanguage: 'he_IL', title: { de: 'Hebräisch', en: 'Hebrew', fr: 'Hébreu', pl: 'Hebrajski' }, country: 'il', flag: '🇮🇱' },
    { index: 33, key: 'hi', trainLanguage: 'hi_IN', title: { de: 'Hindi', en: 'Hindi', fr: 'Hindi', pl: 'Hindi' }, country: 'in', flag: '🇮🇳' },
    { index: 34, key: 'hmn', trainLanguage: 'hmn_LA', title: { de: 'Hmong', en: 'Hmong', fr: 'Hmong', pl: 'Hmong' }, country: 'la', flag: '🇱🇦' },
    { index: 35, key: 'hu', trainLanguage: 'hu_HU', title: { de: 'Ungarisch', en: 'Hungarian', fr: 'Hongrois', pl: 'Węgierski' }, country: 'hu', flag: '🇭🇺' },
    { index: 36, key: 'is', trainLanguage: 'is_IS', title: { de: 'Isländisch', en: 'Icelandic', fr: 'Islandais', pl: 'Islandzki' }, country: 'is', flag: '🇮🇸' },
    { index: 37, key: 'ig', trainLanguage: 'ig_NG', title: { de: 'Igbo', en: 'Igbo', fr: 'Igbo', pl: 'Igbo' }, country: 'ng', flag: '🇳🇬' },
    { index: 38, key: 'id', trainLanguage: 'id_ID', title: { de: 'Indonesisch', en: 'Indonesian', fr: 'Indonésien', pl: 'Indonezyjski' }, country: 'id', flag: '🇮🇩' },
    { index: 39, key: 'ga', trainLanguage: 'ga_IE', title: { de: 'Irisch', en: 'Irish', fr: 'Irlandais', pl: 'Irlandzki' }, country: 'ie', flag: '🇮🇪' },
    { index: 40, key: 'it', trainLanguage: 'it_IT', title: { de: 'Italienisch', en: 'Italian', fr: 'Italien', pl: 'Włoski' }, country: 'it', flag: '🇮🇹' },
    { index: 41, key: 'ja', trainLanguage: 'ja_JP', title: { de: 'Japanisch', en: 'Japanese', fr: 'Japonais', pl: 'Japoński' }, country: 'jp', flag: '🇯🇵' },
    { index: 42, key: 'jv', trainLanguage: 'jv_ID', title: { de: 'Javanisch', en: 'Javanese', fr: 'Javanais', pl: 'Jawajski' }, country: 'id', flag: '🇮🇩' },
    { index: 43, key: 'kn', trainLanguage: 'kn_IN', title: { de: 'Kannada', en: 'Kannada', fr: 'Kannada', pl: 'Kannada' }, country: 'in', flag: '🇮🇳' },
    { index: 44, key: 'kk', trainLanguage: 'kk_KZ', title: { de: 'Kasachisch', en: 'Kazakh', fr: 'Kazakh', pl: 'Kazachski' }, country: 'kz', flag: '🇰🇿' },
    { index: 45, key: 'km', trainLanguage: 'km_KH', title: { de: 'Khmer', en: 'Khmer', fr: 'Khmer', pl: 'Khmer' }, country: 'kh', flag: '🇰🇭' },
    { index: 46, key: 'rw', trainLanguage: 'rw_RW', title: { de: 'Kinyarwanda', en: 'Kinyarwanda', fr: 'Kinyarwanda', pl: 'Kinyarwanda' }, country: 'rw', flag: '🇷🇼' },
    { index: 47, key: 'ko', trainLanguage: 'ko_KR', title: { de: 'Koreanisch', en: 'Korean', fr: 'Coréen', pl: 'Koreański' }, country: 'kr', flag: '🇰🇷' },
    { index: 48, key: 'ku', trainLanguage: 'ku_TR', title: { de: 'Kurdisch', en: 'Kurdish', fr: 'Kurde', pl: 'Kurdyjski' }, country: 'tr', flag: '🇹🇷' },
    { index: 49, key: 'ky', trainLanguage: 'ky_KG', title: { de: 'Kirgisisch', en: 'Kyrgyz', fr: 'Kirghize', pl: 'Kirgijski' }, country: 'kg', flag: '🇰🇬' },
    { index: 50, key: 'lo', trainLanguage: 'lo_LA', title: { de: 'Laotisch', en: 'Lao', fr: 'Lao', pl: 'Laotański' }, country: 'la', flag: '🇱🇦' },
    { index: 51, key: 'la', trainLanguage: 'la_VA', title: { de: 'Lateinisch', en: 'Latin', fr: 'Latin', pl: 'Łaciński' }, country: 'va', flag: '🇻🇦' },
    { index: 52, key: 'lv', trainLanguage: 'lv_LV', title: { de: 'Lettisch', en: 'Latvian', fr: 'Letton', pl: 'Łotewski' }, country: 'lv', flag: '🇱🇻' },
    { index: 53, key: 'lt', trainLanguage: 'lt_LT', title: { de: 'Litauisch', en: 'Lithuanian', fr: 'Lituanien', pl: 'Litewski' }, country: 'lt', flag: '🇱🇹' },
    { index: 54, key: 'lb', trainLanguage: 'lb_LU', title: { de: 'Luxemburgisch', en: 'Luxembourgish', fr: 'Luxembourgeois', pl: 'Luksemburski' }, country: 'lu', flag: '🇱🇺' },
    { index: 55, key: 'mk', trainLanguage: 'mk_MK', title: { de: 'Mazedonisch', en: 'Macedonian', fr: 'Macédonien', pl: 'Macedoński' }, country: 'mk', flag: '🇲🇰' },
    { index: 56, key: 'mg', trainLanguage: 'mg_MG', title: { de: 'Malagasy', en: 'Malagasy', fr: 'Malgache', pl: 'Malagaski' }, country: 'mg', flag: '🇲🇬' },
    { index: 57, key: 'ms', trainLanguage: 'ms_MY', title: { de: 'Malaiisch', en: 'Malay', fr: 'Malais', pl: 'Malajski' }, country: 'my', flag: '🇲🇾' },
    { index: 58, key: 'ml', trainLanguage: 'ml_IN', title: { de: 'Malayalam', en: 'Malayalam', fr: 'Malayalam', pl: 'Malayalam' }, country: 'in', flag: '🇮🇳' },
    { index: 59, key: 'mt', trainLanguage: 'mt_MT', title: { de: 'Maltesisch', en: 'Maltese', fr: 'Maltais', pl: 'Maltański' }, country: 'mt', flag: '🇲🇹' },
    { index: 60, key: 'mi', trainLanguage: 'mi_NZ', title: { de: 'Maori', en: 'Maori', fr: 'Maori', pl: 'Maoryski' }, country: 'nz', flag: '🇳🇿' },
    { index: 61, key: 'mr', trainLanguage: 'mr_IN', title: { de: 'Marathi', en: 'Marathi', fr: 'Marathi', pl: 'Marathi' }, country: 'in', flag: '🇮🇳' },
    { index: 62, key: 'mn', trainLanguage: 'mn_MN', title: { de: 'Mongolisch', en: 'Mongolian', fr: 'Mongol', pl: 'Mongolski' }, country: 'mn', flag: '🇲🇳' },
    { index: 63, key: 'my', trainLanguage: 'my_MM', title: { de: 'Birmanisch', en: 'Myanmar (Burmese)', fr: 'Birman', pl: 'Birmański' }, country: 'mm', flag: '🇲🇲' },
    { index: 64, key: 'ne', trainLanguage: 'ne_NP', title: { de: 'Nepalesisch', en: 'Nepali', fr: 'Népalais', pl: 'Nepalski' }, country: 'np', flag: '🇳🇵' },
    { index: 65, key: 'no', trainLanguage: 'no_NO', title: { de: 'Norwegisch', en: 'Norwegian', fr: 'Norvégien', pl: 'Norweski' }, country: 'no', flag: '🇳🇴' },
    { index: 66, key: 'ny', trainLanguage: 'ny_MW', title: { de: 'Nyanja', en: 'Nyanja (Chichewa)', fr: 'Nyanja', pl: 'Nyanja' }, country: 'mw', flag: '🇲🇼' },
    { index: 67, key: 'or', trainLanguage: 'or_IN', title: { de: 'Oriya', en: 'Odia (Oriya)', fr: 'Odia (Oriya)', pl: 'Orija' }, country: 'in', flag: '🇮🇳' },
    { index: 68, key: 'ps', trainLanguage: 'ps_AF', title: { de: 'Paschtu', en: 'Pashto', fr: 'Pachto', pl: 'Paszto' }, country: 'af', flag: '🇦🇫' },
    { index: 69, key: 'fa', trainLanguage: 'fa_IR', title: { de: 'Persisch', en: 'Persian', fr: 'Persan', pl: 'Perski' }, country: 'ir', flag: '🇮🇷' },
    { index: 70, key: 'pl', trainLanguage: 'pl_PL', title: { de: 'Polnisch', en: 'Polish', fr: 'Polonais', pl: 'Polski' }, country: 'pl', flag: '🇵🇱' },
    { index: 71, key: 'pt', trainLanguage: 'pt_PT', title: { de: 'Portugiesisch', en: 'Portuguese (Portugal)', fr: 'Portugais (Portugal)', pl: 'Portugalski (Portugalia)' }, country: 'pt', flag: '🇵🇹' },
    { index: 72, key: 'pa', trainLanguage: 'pa_IN', title: { de: 'Punjabi', en: 'Punjabi', fr: 'Panjabi', pl: 'Pendżabski' }, country: 'in', flag: '🇮🇳' },
    { index: 73, key: 'ro', trainLanguage: 'ro_RO', title: { de: 'Rumänisch', en: 'Romanian', fr: 'Roumain', pl: 'Rumuński' }, country: 'ro', flag: '🇷🇴' },
    { index: 74, key: 'ru', trainLanguage: 'ru_RU', title: { de: 'Russisch', en: 'Russian', fr: 'Russe', pl: 'Rosyjski' }, country: 'ru', flag: '🇷🇺' },
    { index: 75, key: 'sm', trainLanguage: 'sm_WS', title: { de: 'Samoanisch', en: 'Samoan', fr: 'Samoan', pl: 'Samoański' }, country: 'ws', flag: '🇼🇸' },
    { index: 76, key: 'gd', trainLanguage: 'gd_GB', title: { de: 'Schottisches Gälisch', en: 'Scottish Gaelic', fr: 'Gaélique écossais', pl: 'Szkocki Gaelicki' }, country: 'gb', flag: '🏴' },
    { index: 77, key: 'sr', trainLanguage: 'sr_RS', title: { de: 'Serbisch', en: 'Serbian', fr: 'Serbe', pl: 'Serbski' }, country: 'rs', flag: '🇷🇸' },
    { index: 78, key: 'st', trainLanguage: 'st_LS', title: { de: 'Sesotho', en: 'Sesotho', fr: 'Sotho', pl: 'Sotho' }, country: 'ls', flag: '🇱🇸' },
    { index: 79, key: 'sn', trainLanguage: 'sn_ZW', title: { de: 'Shona', en: 'Shona', fr: 'Shona', pl: 'Shona' }, country: 'zw', flag: '🇿🇼' },
    { index: 80, key: 'sd', trainLanguage: 'sd_PK', title: { de: 'Sindhi', en: 'Sindhi', fr: 'Sindhi', pl: 'Sindhi' }, country: 'pk', flag: '🇵🇰' },
    { index: 81, key: 'si', trainLanguage: 'si_LK', title: { de: 'Singhalesisch', en: 'Sinhala', fr: 'Sinhala', pl: 'Syngaleski' }, country: 'lk', flag: '🇱🇰' },
    { index: 82, key: 'sk', trainLanguage: 'sk_SK', title: { de: 'Slowakisch', en: 'Slovak', fr: 'Slovaque', pl: 'Słowacki' }, country: 'sk', flag: '🇸🇰' },
    { index: 83, key: 'sl', trainLanguage: 'sl_SI', title: { de: 'Slowenisch', en: 'Slovenian', fr: 'Slovène', pl: 'Słoweński' }, country: 'si', flag: '🇸🇮' },
    { index: 84, key: 'so', trainLanguage: 'so_SO', title: { de: 'Somali', en: 'Somali', fr: 'Somali', pl: 'Somalijski' }, country: 'so', flag: '🇸🇴' },
    { index: 85, key: 'es', trainLanguage: 'es_ES', title: { de: 'Spanisch', en: 'Spanish', fr: 'Espagnol', pl: 'Hiszpański' }, country: 'es', flag: '🇪🇸' },
    { index: 86, key: 'su', trainLanguage: 'su_ID', title: { de: 'Sundanesisch', en: 'Sundanese', fr: 'Soundanais', pl: 'Sundajski' }, country: 'id', flag: '🇮🇩' },
    { index: 87, key: 'sw', trainLanguage: 'sw_TZ', title: { de: 'Swahili', en: 'Swahili', fr: 'Swahili', pl: 'Swahili' }, country: 'tz', flag: '🇹🇿' },
    { index: 88, key: 'sv', trainLanguage: 'sv_SE', title: { de: 'Schwedisch', en: 'Swedish', fr: 'Suédois', pl: 'Szwedzki' }, country: 'se', flag: '🇸🇪' },
    { index: 89, key: 'tl', trainLanguage: 'tl_PH', title: { de: 'Tagalog', en: 'Tagalog', fr: 'Tagalog', pl: 'Tagalski' }, country: 'ph', flag: '🇵🇭' },
    { index: 90, key: 'tg', trainLanguage: 'tg_TJ', title: { de: 'Tadschikisch', en: 'Tajik', fr: 'Tadjik', pl: 'Tadżycki' }, country: 'tj', flag: '🇹🇯' },
    { index: 91, key: 'ta', trainLanguage: 'ta_IN', title: { de: 'Tamil', en: 'Tamil', fr: 'Tamoul', pl: 'Tamilski' }, country: 'in', flag: '🇮🇳' },
    { index: 92, key: 'tt', trainLanguage: 'tt_RU', title: { de: 'Tatarisch', en: 'Tatar', fr: 'Tatar', pl: 'Tatarski' }, country: 'ru', flag: '🇷🇺' },
    { index: 93, key: 'te', trainLanguage: 'te_IN', title: { de: 'Telugu', en: 'Telugu', fr: 'Télougou', pl: 'Telugu' }, country: 'in', flag: '🇮🇳' },
    { index: 94, key: 'th', trainLanguage: 'th_TH', title: { de: 'Thailändisch', en: 'Thai', fr: 'Thaï', pl: 'Tajski' }, country: 'th', flag: '🇹🇭' },
    { index: 95, key: 'tr', trainLanguage: 'tr_TR', title: { de: 'Türkisch', en: 'Turkish', fr: 'Turc', pl: 'Turecki' }, country: 'tr', flag: '🇹🇷' },
    { index: 96, key: 'tk', trainLanguage: 'tk_TM', title: { de: 'Turkmenisch', en: 'Turkmen', fr: 'Turkmène', pl: 'Turkmeński' }, country: 'tm', flag: '🇹🇲' },
    { index: 97, key: 'uk', trainLanguage: 'uk_UA', title: { de: 'Ukrainisch', en: 'Ukrainian', fr: 'Ukrainien', pl: 'Ukraiński' }, country: 'ua', flag: '🇺🇦' },
    { index: 98, key: 'ur', trainLanguage: 'ur_PK', title: { de: 'Urdu', en: 'Urdu', fr: 'Ourdou', pl: 'Urdu' }, country: 'pk', flag: '🇵🇰' },
    { index: 99, key: 'ug', trainLanguage: 'ug_CN', title: { de: 'Uigurisch', en: 'Uyghur', fr: 'Ouïghour', pl: 'Ujgurski' }, country: 'cn', flag: '🇨🇳' },
    { index: 100, key: 'uz', trainLanguage: 'uz_UZ', title: { de: 'Usbekisch', en: 'Uzbek', fr: 'Ouzbek', pl: 'Uzbecki' }, country: 'uz', flag: '🇺🇿' },
    { index: 101, key: 'vi', trainLanguage: 'vi_VN', title: { de: 'Vietnamesisch', en: 'Vietnamese', fr: 'Vietnamien', pl: 'Wietnamski' }, country: 'vn', flag: '🇻🇳' },
    { index: 102, key: 'cy', trainLanguage: 'cy_GB', title: { de: 'Walisisch', en: 'Welsh', fr: 'Gallois', pl: 'Walijski' }, country: 'gb', flag: '🏴󠁧󠁢󠁷󠁬󠁳󠁿' },
    { index: 103, key: 'xh', trainLanguage: 'xh_ZA', title: { de: 'Xhosa', en: 'Xhosa', fr: 'Xhosa', pl: 'Khosa' }, country: 'za', flag: '🇿🇦' },
    { index: 104, key: 'yi', trainLanguage: 'yi_??', title: { de: 'Jiddisch', en: 'Yiddish', fr: 'Yiddish', pl: 'Jidysz' }, country: '??', flag: '🇮🇱' },
    { index: 105, key: 'yo', trainLanguage: 'yo_NG', title: { de: 'Yoruba', en: 'Yoruba', fr: 'Yoruba', pl: 'Joruba' }, country: 'ng', flag: '🇳🇬' },
    { index: 106, key: 'zu', trainLanguage: 'zu_ZA', title: { de: 'Zulu', en: 'Zulu', fr: 'Zoulou', pl: 'Zuluski' }, country: 'za', flag: '🇿🇦' },
  ];

  /**
   * Do <b>not</b> modify this to match available languages!
   * It is also used to move users away from a disabled language.
   */
  public static LANGUAGE_PATHS = {
    de: '/de',
    en: '/en',
    fr: '/fr',
    pl: '/pl',
  };

  public static toLowerCase(translation: Translation): Translation {

    const copy: Translation = {};
    Object.keys(translation).forEach(key => {
      copy[key] = translation[key].toLowerCase();
    });
    return copy;

  }

  public static getCurrentLanguage(): LanguageInfo {
    return LanguageHelper.getLanguage(State.language);
  }

  //removes emojis and similar
  public static cleanLanguageLabel(text: string): string {
    return text.replace(/[^\p{L}\p{N}\p{P}\p{Z}^$\n]/gu, '').trim();
  }

  //gets country code from train language code
  public static getCountryNoLang(language: string): string {
    return language.slice(3).toLowerCase();
  }

  static getEmptyTranslation(): LocaleTranslation {
    return LanguageHelper.LANGUAGES
      .reduce((pV, language) => {
        pV[language.key] = '';
        return pV;
      }, {});
  }


  //untouched to ensure compatibility. prefer getLanguageByTrain(), expecting a train language code (de_DE for example)
  public static getLanguage(locale: string): LanguageInfo {
    const language = LanguageHelper.localeToLanguage(locale);
    return LanguageHelper.LANGUAGES
      .find(entry => entry.key === language);
  }

  public static getLanguageByTrain(language: string, all: LanguageInfo[] = this.WORLDLANGUAGES): LanguageInfo {
    const find = all
      .find(entry => entry.trainLanguage === language);
    return find ? find : all[0];
  }

  public static getLanguagesByTrain(languages: string[], all: LanguageInfo[] = this.WORLDLANGUAGES): LanguageInfo[] {
    const result: LanguageInfo[] = [];
    languages.forEach(lang => {
      result.push(this.getLanguageByTrain(lang, all));
    })
    return result;
  }

  public static getLanguageByLabel(label: string, all: LanguageInfo[] = this.WORLDLANGUAGES): LanguageInfo {
    const find = all
      .find(entry => Object.values(entry.title).includes(LanguageHelper.cleanLanguageLabel(label)));
    return find ? find : all[0];
  }

  public static getLanguagesByLabel(labels: string[], all: LanguageInfo[] = this.WORLDLANGUAGES): LanguageInfo[] {
    const result: LanguageInfo[] = [];
    labels.forEach(label => {
      result.push(this.getLanguageByLabel(label, all));
    })
    return result;
  }

  public static getLanguageFlag(language: LanguageInfo): string {
    return 'flag-icon-' + language.country;
  }

  public static getLanguageIndex(languageKey: string): number {
    return Object.values(LanguageHelper.LANGUAGES)
      .findIndex(entry => entry?.key === languageKey);
  }

  public static getLanguageAt(index: number): LanguageInfo {
    if ( !(index > -1 && index < LanguageHelper.LANGUAGES.length) ) {
      index = LanguageHelper.getLanguageIndex(State.language);
    }
    return LanguageHelper.LANGUAGES[index];
  }

  /**
   * Similar to objectToText but returns text for language and do not search for a fallback of a different language
   * */
  public static getAdminText(value: unknown, language?: string): string {
    return LanguageHelper.objectToText(value, language, true);
  }

  /**
   * Always return a {@link LocaleTranslation}, even when value is a string. If value is a string, a new
   * {@link LocaleTranslation} object is created with value set with the given language. If language is not defined,
   * it will be set to 'default'.
   */
  public static getTranslation(value: string | LocaleTranslation, language?: string): Translation {
    if (typeof(value) === 'string') {
      const translation = {};
      translation[language ?? 'default'] = value;
      return translation;
    }
    return value as Translation;
  }

  public static objectToText(value: unknown, language?: string, strict = false): string {
    if ( value == null ) {
      // terminate early if the object is null-ish
      return '';
    }

    const valueType = typeof (value);
    if ( valueType === 'boolean' ) {
      if ( value ) {
        return $localize`:@@global_yes:Yes`;
      } else {
        return $localize`:@@global_no:No`;
      }

    } else if ( !value ) {
      // not a boolean -> check if "falsy"
      return '';

    } else if ( valueType === 'string' ) {
      return value as string;

    } else if ( valueType === 'number' ) {
      return String(value);
    }

    let result: string;
    if ( (valueType === 'object') && !Array.isArray(value) ) {
      // only execute the expensive checks, if value is an object

      const languageInfo = LanguageHelper.getLanguage(language);
      if (Object.prototype.hasOwnProperty.call(value, languageInfo.key)) {
        // de / en
        result = LanguageHelper.trimToNull(value[languageInfo.key]);
      }

      if ((result == null) && Object.prototype.hasOwnProperty.call(value, languageInfo.trainLanguage)) {
        // de_DE / en_GB
        result = LanguageHelper.trimToNull(value[languageInfo.trainLanguage]);
      }

      if (!strict && (result == null) && Object.prototype.hasOwnProperty.call(value, 'default')) {
        // there is a default value available
        result = LanguageHelper.trimToNull(value['default']);
      }
    }

    if ( result == null && !strict ) {
      // try to find the first string-type attribute
      result = Object.values(value)
        // check if value is a string (does not include null / undefined)
        .filter(v => (typeof (v) === 'string'))
        // trim whitespace
        .map(LanguageHelper.trimToNull)
        // find the first non-null value
        .find(v => v != null);
    }

    // make sure we always return something not null
    return result ?? '';
  }

  public static localeToLanguage(locale?: string): string {
    let language: string = locale;
    if ( typeof (locale) === 'string' ) {
      // trim the country from the locale
      let index = locale.indexOf('_');
      if (index != -1) {
        language = locale.substring(0, index);
      }
      index = locale.indexOf('-');
      if (index != -1) {
        language = locale.substring(0, index);
      }
    }

    if ( !LanguageHelper.isValidLanguageKey(language) ) {
      // use current language if the intended language is invalid (e.g. disabled in account)
      language = State.language;
    }

    if ( !LanguageHelper.isValidLanguageKey(language) ) {
      // the HTML export uses a language that is not supported in this account -> apply the first available language
      language = LanguageHelper.LANGUAGES.find(entry => !!entry)?.key;
    }

    return language;
  }

  public static isValidLanguageKey(languageKey: string): boolean {
    return LanguageHelper.getLanguageIndex(languageKey) >= 0;
  }

  static setLanguages(languages: LanguageInfo[]): void {
    if ( !(languages?.length > 0) ) {
      // if classic api call not exists or fails, default const de,en ist taken
      return;
    }

    // replace all previous values with the given languages
    LanguageHelper.LANGUAGES
      // splice(0, len) -> remove all "...languages" -> add all given parameters at position 0
      .splice(0, LanguageHelper.LANGUAGES.length, ...languages);
  }

  //! Warning: Returns a new object only if target is null, else mutates it and might bypass change detection
  //TODO: Has been the cause of several mutation-caused bugs now, including recursive translations, should be rethought to not mutate "target".
  public static setTranslation(target: Translation, value: string, languageKey: string): Translation {
    if ( target == null || target === '' ) {

      // this is an empty translation -> ensure we have a valid object to write our text into
      target = LanguageHelper.getEmptyTranslation();

    } else if ( typeof (target) === 'string' ) {

      // set string value as entry for current language
      target = LanguageHelper.getEmptyTranslation();
      target[LanguageHelper.getCurrentLanguage().key] = String(target);
    }

    // clean-up language keys to force proper update
    Object.keys(target)
      .filter(key => /[_-]/.test(key))
      .forEach(key => delete target[key]);

    // insert text into target object
    target[languageKey] = value;

    // as long as the user only types on the initial language we treat it as default value
    const isThisDefaultCandiate = Object.keys(target).reduce((pV, key) => {
      if (key !== 'default' && key !== languageKey && this.trimToNull(target[key]) != null) {
        pV++;
      }
      return pV;
    }, 0) === 0;
    if (isThisDefaultCandiate) {
      target['default'] = value;
    }

    return target;

  }

  /**
   * This is simply an alternative name for {@link LanguageHelper.objectToText}.
   */
  public static translate(value: unknown, language?: string): string {
    return LanguageHelper.objectToText(value, language);
  }

  public static plural(count: number, options) {
    const directResult = options[count];
    if (directResult !== undefined) {
 return directResult;
}
    // handle zero, one, two, few, many
    // ...
    return options.other;
  }

  private static trimToNull(value: string): string {
    if ( value == null ) {
      return null;
    }

    const result = value.trim();
    if ( result === '' ) {
      return null;
    }

    return result;
  }

  static isNotEmptyTranslation(description: Translation | null): boolean {
    return !LanguageHelper.isEmptyTranslation(description);
  }

  static isEmptyTranslation(description: Translation | null): boolean {
    if (typeof(description) === 'string') {
      return description.trim().length === 0;
    }

    if (Object.keys(description ?? {}).length === 0) {
      return true;
    }

    return Object.values(description)
      .find(value => `${value ?? ''}`.trim().length > 0) == null;
  }
}
